import { guardReactiveProps as Be, useSlots as Ee, normalizeClass as F, reactive as Fe, onActivated as Ft, createElementVNode as H, resolveDynamicComponent as J, withDirectives as Je, provide as Me, createBlock as O, inject as Oe, withCtx as P, toDisplayString as R, normalizeStyle as Ve, mergeProps as W, ref as X, createTextVNode as Y, isRef as Ye, Fragment as ae, openBlock as c, createVNode as ce, vModelRadio as cl, vModelCheckbox as dl, vModelSelect as fl, normalizeProps as ge, renderList as ie, onUnmounted as il, createCommentVNode as j, resolveComponent as la, createElementBlock as m, useAttrs as na, toRef as o, createSlots as oa, h as oe, Comment as ol, renderSlot as p, onMounted as pe, watch as re, Transition as rl, withModifiers as rt, unref as s, getCurrentInstance as sl, onBeforeUnmount as st, computed as u, isReactive as ul, Teleport as vl, defineComponent as x, nextTick as ze } from 'vue'
import { Alert as bl, Carousel as gl, Modal as hl, Popover as kt, Collapse as ml, Dropdown as pl, Offcanvas as yl } from 'bootstrap'
import ht from 'bootstrap/js/dist/tooltip'
const ll = Object.defineProperty
const nl = (e, t, a) => t in e ? ll(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a
const ve = (e, t, a) => (nl(e, typeof t != 'symbol' ? `${t}` : t, a), a)
const Bl = e => u(
  () => e.value === 'center' ? 'justify-content-center' : e.value === 'end' ? 'justify-content-end' : 'justify-content-start',
); const it = (e, t) => Object.keys(e).filter(a => !t.includes(a)).reduce((a, l) => ({ ...a, [l]: e[l] }), {}); const He = () => ({ enumerable: !0, configurable: !1, writable: !1 }); const lt = e => Array.isArray(e)
  ? e.map(t => lt(t))
  : e instanceof Date
    ? new Date(e.getTime())
    : e && typeof e == 'object'
      ? Object.getOwnPropertyNames(e).reduce((t, a) => {
        let l
        return Object.defineProperty(t, a, (l = Object.getOwnPropertyDescriptor(e, a)) != null ? l : {}), t[a] = lt(e[a]), t
      }, Object.create(Object.getPrototypeOf(e)))
      : e; const St = e => new Promise(t => t(lt(e)))
class ut {
  constructor(t, a = {}) {
    ve(this, 'cancelable', !0)
    ve(this, 'componentId', null)
    ve(this, 'defaultPrevented', !1)
    ve(this, 'nativeEvent', null)
    ve(this, 'preventDefault')
    ve(this, 'relatedTarget', null)
    ve(this, 'target', null)
    ve(this, 'eventType', '')
    ve(this, 'vueTarget', null)
    if (!t)
      throw new TypeError(
        `Failed to construct '${this.constructor.name}'. 1 argument required, ${arguments.length} given.`,
      )
    Object.assign(this, ut.Defaults, this.constructor.Defaults, a, { eventType: t }), Object.defineProperties(this, {
      type: He(),
      cancelable: He(),
      nativeEvent: He(),
      target: He(),
      relatedTarget: He(),
      vueTarget: He(),
      componentId: He(),
    })
    let l = !1
    this.preventDefault = function () {
      this.cancelable && (l = !0)
    }, Object.defineProperty(this, 'defaultPrevented', {
      enumerable: !0,
      get() {
        return l
      },
    })
  }

  static get Defaults() {
    return {
      eventType: '',
      cancelable: !0,
      nativeEvent: null,
      target: null,
      relatedTarget: null,
      vueTarget: null,
      componentId: null,
    }
  }
}
const $l = e => e !== null && typeof e == 'object'; const sa = e => /^[0-9]*\.?[0-9]+$/.test(String(e)); const kl = e => Object.prototype.toString.call(e) === '[object Object]'; const ra = /_/g; const ia = /([a-z])([A-Z])/g; const Sl = /(\s|^)(\w)/g; const Cl = /(\s|^)(\w)/; const Qe = /\s+/; const wl = /^#/; const Tl = /^#[A-Za-z]+[\w\-:.]*$/; const Ct = (e, t = 2) => typeof e == 'string' ? e : e == null ? '' : Array.isArray(e) || kl(e) && e.toString === Object.prototype.toString ? JSON.stringify(e, null, t) : String(e); const Dt = e => e.replace(ra, ' ').replace(ia, (t, a, l) => `${a} ${l}`).replace(Cl, (t, a, l) => a + l.toUpperCase()); const Rt = e => e.replace(ra, ' ').replace(ia, (t, a, l) => `${a} ${l}`).replace(Sl, (t, a, l) => a + l.toUpperCase()); const Vl = (e) => {
  const t = e.trim()
  return t.charAt(0).toUpperCase() + t.slice(1)
}; const yt = e => `\\${e}`; const _l = (e) => {
  const t = Ct(e); const { length: a } = t; const l = t.charCodeAt(0)
  return t.split('').reduce((n, i, f) => {
    const d = t.charCodeAt(f)
    return d === 0 ? `${n}\uFFFD` : d === 127 || d >= 1 && d <= 31 || f === 0 && d >= 48 && d <= 57 || f === 1 && d >= 48 && d <= 57 && l === 45 ? n + yt(`${d.toString(16)} `) : f === 0 && d === 45 && a === 1 ? n + yt(i) : d >= 128 || d === 45 || d === 95 || d >= 48 && d <= 57 || d >= 65 && d <= 90 || d >= 97 && d <= 122 ? n + i : n + yt(i)
  }, '')
}; const ua = typeof window < 'u'; const da = typeof document < 'u'; const Al = typeof navigator < 'u'; const xl = ua && da && Al; const ca = da ? document : {}; const Pe = e => !!(e && e.nodeType === Node.ELEMENT_NODE); const Il = e => Pe(e) ? e.getBoundingClientRect() : null; const Fl = (e = []) => {
  const { activeElement: t } = document
  return t && !e.includes(t) ? t : null
}; const Ol = e => Pe(e) && e === Fl(); const zl = (e, t = {}) => {
  try {
    e.focus(t)
  }
  catch (a) {
    console.error(a)
  }
  return Ol(e)
}; const Pl = (e, t) => t && Pe(e) && e.getAttribute(t) || null; const Nl = (e) => {
  if (Pl(e, 'display') === 'none')
    return !1
  const t = Il(e)
  return !!(t && t.height > 0 && t.width > 0)
}; const qt = (e, t) => !e || e(t).filter(a => a.type !== ol).length < 1; const Ll = (e, t) => (Pe(t) ? t : ca).querySelector(e) || null; const Hl = (e, t) => Array.from([(Pe(t) ? t : ca).querySelectorAll(e)]); const fa = (e, t) => t && Pe(e) ? e.getAttribute(t) : null; const El = (e, t, a) => {
  t && Pe(e) && e.setAttribute(t, a)
}; const Dl = (e, t) => {
  t && Pe(e) && e.removeAttribute(t)
}; const Rl = (e, t) => Ct(e).toLowerCase() === Ct(t).toLowerCase(); const Ze = ua ? window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || (e => setTimeout(e, 16)) : e => setTimeout(e, 0); const dt = (e, t, a) => t.concat(['sm', 'md', 'lg', 'xl', 'xxl']).reduce((l, n) => (l[e ? `${e}${n.charAt(0).toUpperCase() + n.slice(1)}` : n] = a, l), /* @__PURE__ */ Object.create(null)); const va = (e, t, a, l = a) => Object.keys(t).reduce((n, i) => (e[i] && n.push(
  [l, i.replace(a, ''), e[i]].filter(f => f && typeof f != 'boolean').join('-').toLowerCase(),
), n), []); const Ie = (e = '') => `__BVID__${Math.random().toString().slice(2, 8)}___BV_${e}__`; const ct = (e, t) => e === !0 || e === 'true' || e === '' ? 'true' : e === 'grammar' || e === 'spelling' ? e : t === !1 ? 'true' : e === !1 || e === 'false' ? 'false' : void 0; const Bt = e => !!e && typeof e == 'object' && e.constructor === Object; const wt = (e, t, a = !0) => {
  const l = e instanceof Date && typeof e.getMonth == 'function' ? new Date(e.getTime()) : Object.assign({}, e)
  return Bt(e) && Bt(t) && Object.keys(t).forEach((n) => {
    Bt(t[n])
      ? n in e ? l[n] = wt(e[n], t[n], a) : Object.assign(l, { [n]: t[n] })
      : Array.isArray(t[n]) && Array.isArray(e[n])
        ? Object.assign(l, {
          [n]: a
            ? e[n].concat(
              t[n].filter(i => !e[n].includes(i)),
            )
            : t[n],
        })
        : Object.assign(l, { [n]: t[n] })
  }), l
}; const Te = (e, t = {}, a = {}) => {
  const l = [e]
  let n
  for (let i = 0; i < l.length && !n; i++) {
    const f = l[i]
    n = a[f]
  }
  return n && typeof n == 'function' ? n(t) : n
}; const je = (e, t = NaN) => Number.isInteger(e) ? e : t; const We = (e, t = NaN) => {
  const a = Number.parseInt(e, 10)
  return Number.isNaN(a) ? t : a
}; const ma = (e, t = NaN) => {
  const a = Number.parseFloat(e.toString())
  return Number.isNaN(a) ? t : a
}; const jt = (e, t) => t + (e ? Vl(e) : ''); const Ot = (e, t) => (Array.isArray(t) ? t.slice() : Object.keys(t)).reduce(
  (a, l) => (a[l] = e[l], a),
  {},
); const ql = e => typeof e == 'boolean' ? e : e === '' ? !0 : e === 'true'; const Xe = e => !!(e.href || e.to)
function r(e) {
  return u(() => e.value === void 0 ? void 0 : ql(e.value))
}
const ba = Symbol(); const ga = {
  items: Fe([]),
  reset() {
    this.items = Fe([])
  },
}; const jl = (e) => {
  e.provide(ba, ga)
}; const Ml = () => {
  const e = Oe(ba)
  return e || ga
}; const me = (e, t, a) => {
  pe(() => {
    let l;
    (l = e == null ? void 0 : e.value) == null || l.addEventListener(t, a)
  }), st(() => {
    let l;
    (l = e == null ? void 0 : e.value) == null || l.removeEventListener(t, a)
  })
}; const pa = e => u(() => ({
  'form-check': !e.plain && !e.button,
  'form-check-inline': e.inline,
  'form-switch': e.switch,
  [`form-control-${e.size}`]: e.size && e.size !== 'md',
})); const ha = e => u(() => ({
  'form-check-input': !e.plain && !e.button,
  'is-valid': e.state === !0,
  'is-invalid': e.state === !1,
  'btn-check': e.button,
})); const ya = e => u(() => ({
  'form-check-label': !e.plain && !e.button,
  'btn': e.button,
  [`btn-${e.buttonVariant}`]: e.button,
  [`btn-${e.size}`]: e.button && e.size && e.size !== 'md',
})); const Ba = e => u(() => {
  let t
  return {
    'aria-invalid': ct(e.ariaInvalid, e.state),
    'aria-required': ((t = e.required) == null ? void 0 : t.toString()) === 'true' ? 'true' : null,
  }
}); const $a = e => u(() => ({
  'was-validated': e.validated,
  'btn-group': e.buttons && !e.stacked,
  'btn-group-vertical': e.stacked,
  [`btn-group-${e.size}`]: e.size,
})); const nt = (e, t, a) => e.reduce(
  (l, n) => n.type.toString() === 'Symbol(Fragment)' ? l.concat(n.children) : l.concat([n]),
  [],
).filter(l => (l.type.__name || l.type.name) === t).map((l) => {
  const n = (l.children.default ? l.children.default() : []).find(
    i => i.type.toString() === 'Symbol(Text)',
  )
  return {
    props: {
      disabled: a,
      ...l.props,
    },
    text: n ? n.children : '',
  }
}); const ka = (e, t) => typeof e == 'string'
  ? {
      props: {
        value: e,
        disabled: t.disabled,
      },
      text: e,
    }
  : {
      props: {
        value: e[t.valueField],
        disabled: t.disabled || e[t.disabledField],
        ...e.props,
      },
      text: e[t.textField],
      html: e[t.htmlField],
    }; const Sa = (e, t, a, l, n) => ({
  ...e,
  props: {
    'button-variant': a.buttonVariant,
    'form': a.form,
    'name': l.value,
    'id': `${n.value}_option_${t}`,
    'button': a.buttons,
    'state': a.state,
    'plain': a.plain,
    'size': a.size,
    'inline': !a.stacked,
    'required': a.required,
    ...e.props,
  },
}); const ye = (e, t) => u(() => (e == null ? void 0 : e.value) || Ie(t)); const Ca = {
  ariaInvalid: {
    type: [Boolean, String],
    default: void 0,
  },
  autocomplete: { type: String, required: !1 },
  autofocus: { type: Boolean, default: !1 },
  disabled: { type: Boolean, default: !1 },
  form: { type: String, required: !1 },
  formatter: { type: Function, required: !1 },
  id: { type: String, required: !1 },
  lazy: { type: Boolean, default: !1 },
  lazyFormatter: { type: Boolean, default: !1 },
  list: { type: String, required: !1 },
  modelValue: { type: [String, Number], default: '' },
  name: { type: String, required: !1 },
  number: { type: Boolean, default: !1 },
  placeholder: { type: String, required: !1 },
  plaintext: { type: Boolean, default: !1 },
  readonly: { type: Boolean, default: !1 },
  required: { type: Boolean, default: !1 },
  size: { type: String, required: !1 },
  state: { type: Boolean, default: null },
  trim: { type: Boolean, default: !1 },
}; const wa = (e, t) => {
  const a = X()
  let l = null; let n = !0
  const i = ye(o(e, 'id'), 'input'); const f = (y, V, $ = !1) => (y = String(y), typeof e.formatter == 'function' && (!e.lazyFormatter || $) ? (n = !1, e.formatter(y, V)) : y); const d = y => e.trim ? y.trim() : e.number ? Number.parseFloat(y) : y; const v = () => {
    ze(() => {
      let y
      e.autofocus && ((y = a.value) == null || y.focus())
    })
  }
  pe(v), pe(() => {
    a.value && (a.value.value = e.modelValue)
  }), Ft(v)
  const b = u(
    () => {
      let y
      return ct(e.ariaInvalid, (y = e.state) != null ? y : void 0)
    },
  ); const g = (y) => {
    const { value: V } = y.target; const $ = f(V, y)
    if ($ === !1 || y.defaultPrevented) {
      y.preventDefault()
      return
    }
    if (e.lazy)
      return
    const z = d($)
    e.modelValue !== z && (l = V, t('update:modelValue', z)), t('input', $)
  }; const B = (y) => {
    const { value: V } = y.target; const $ = f(V, y)
    if ($ === !1 || y.defaultPrevented) {
      y.preventDefault()
      return
    }
    if (!e.lazy)
      return
    l = V, t('update:modelValue', $)
    const z = d($)
    e.modelValue !== z && t('change', $)
  }; const h = (y) => {
    if (t('blur', y), !e.lazy && !e.lazyFormatter)
      return
    const { value: V } = y.target; const $ = f(V, y, !0)
    l = V, t('update:modelValue', $)
  }; const k = () => {
    let y
    e.disabled || (y = a.value) == null || y.focus()
  }; const w = () => {
    let y
    e.disabled || (y = a.value) == null || y.blur()
  }
  return re(
    () => e.modelValue,
    (y) => {
      !a.value || (a.value.value = l && n ? l : y, l = null, n = !0)
    },
  ), {
    input: a,
    computedId: i,
    computedAriaInvalid: b,
    onInput: g,
    onChange: B,
    onBlur: h,
    focus: k,
    blur: w,
  }
}; const qe = (e, t) => {
  if (!e)
    return e
  if (t in e)
    return e[t]
  const a = t.split('.')
  return qe(e[a[0]], a.splice(1).join('.'))
}; const $t = (e, t = null, a, l) => {
  if (Object.prototype.toString.call(e) === '[object Object]') {
    const n = qe(e, l.valueField); const i = qe(e, l.textField); const f = qe(e, l.htmlField); const d = qe(e, l.disabledField); const v = e[l.optionsField] || null
    return v !== null
      ? {
          label: String(qe(e, l.labelField) || i),
          options: zt(v, a, l),
        }
      : {
          value: typeof n > 'u' ? t || i : n,
          text: String(typeof i > 'u' ? t : i),
          html: f,
          disabled: Boolean(d),
        }
  }
  return {
    value: t || e,
    text: String(e),
    disabled: !1,
  }
}; const zt = (e, t, a) => Array.isArray(e)
  ? e.map(l => $t(l, null, t, a))
  : Object.prototype.toString.call(e) === '[object Object]'
    ? (console.warn(
  `[BootstrapVue warn]: ${t} - Setting prop "options" to an object is deprecated. Use the array format instead.`,
      ), Object.keys(e).map((l) => {
        const n = e[l]
        switch (typeof n) {
          case 'object':
            return $t(n.text, String(n.value), t, a)
          default:
            return $t(n, String(l), t, a)
        }
      }))
    : []; const Gl = ['id']; const Ta = Symbol(); const Wl = /* @__PURE__ */ x({
  __name: 'BAccordion',
  props: {
    flush: { default: !1 },
    free: { default: !1 },
    id: null,
  },
  setup(e) {
    const t = e; const a = ye(o(t, 'id'), 'accordion'); const l = r(o(t, 'flush')); const n = r(o(t, 'free')); const i = u(() => ({
      'accordion-flush': l.value,
    }))
    return n.value || Me(Ta, a.value.toString()), (f, d) => (c(), m('div', {
      id: s(a),
      class: F(['accordion', s(i)]),
    }, [
      p(f.$slots, 'default'),
    ], 10, Gl))
  },
}); const Va = /* @__PURE__ */ x({
  __name: 'BCollapse',
  props: {
    accordion: null,
    id: { default: Ie() },
    modelValue: { default: !1 },
    tag: { default: 'div' },
    toggle: { default: !1 },
    visible: { default: !1 },
    isNav: { default: !1 },
  },
  emits: ['update:modelValue', 'show', 'shown', 'hide', 'hidden'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'modelValue')); const n = r(o(a, 'toggle')); const i = r(o(a, 'visible')); const f = r(o(a, 'isNav')); const d = X(); const v = X(); const b = u(() => ({
      'show': l.value,
      'navbar-collapse': f.value,
    })); const g = () => t('update:modelValue', !1)
    return me(d, 'show.bs.collapse', () => {
      t('show'), t('update:modelValue', !0)
    }), me(d, 'hide.bs.collapse', () => {
      t('hide'), t('update:modelValue', !1)
    }), me(d, 'shown.bs.collapse', () => t('shown')), me(d, 'hidden.bs.collapse', () => t('hidden')), pe(() => {
      let B
      v.value = new ml(d.value, {
        parent: a.accordion ? `#${a.accordion}` : void 0,
        toggle: n.value,
      }), (i.value || l.value) && (t('update:modelValue', !0), (B = v.value) == null || B.show())
    }), re(
      () => l.value,
      (B) => {
        let h, k
        B ? (h = v.value) == null || h.show() : (k = v.value) == null || k.hide()
      },
    ), re(
      () => i.value,
      (B) => {
        let h, k
        B ? (t('update:modelValue', !!B), (h = v.value) == null || h.show()) : (t('update:modelValue', !!B), (k = v.value) == null || k.hide())
      },
    ), (B, h) => (c(), O(J(e.tag), {
      'id': e.id,
      'ref_key': 'element',
      'ref': d,
      'class': F(['collapse', s(b)]),
      'data-bs-parent': e.accordion || null,
      'is-nav': s(f),
    }, {
      default: P(() => [
        p(B.$slots, 'default', {
          visible: s(l),
          close: g,
        }),
      ]),
      _: 3,
    }, 8, ['id', 'class', 'data-bs-parent', 'is-nav']))
  },
}); const Ul = {
  mounted(e, t) {
    let a = t.value
    Object.keys(t.modifiers).length > 0 && ([a] = Object.keys(t.modifiers)), e.setAttribute('data-bs-toggle', 'modal'), e.setAttribute('data-bs-target', `#${a}`)
  },
}; const Xl = {
  mounted(e, t) {
    let a = 'right'
    const l = []
    t.modifiers.left ? a = 'left' : t.modifiers.right ? a = 'right' : t.modifiers.bottom ? a = 'bottom' : t.modifiers.top && (a = 'top'), t.modifiers.manual ? l.push('manual') : (t.modifiers.click && l.push('click'), t.modifiers.hover && l.push('hover'), t.modifiers.focus && l.push('focus')), e.setAttribute('data-bs-toggle', 'popover'), new kt(e, {
      trigger: l.length === 0 ? 'click' : l.join(' '),
      placement: a,
      content: t.value,
    })
  },
  unmounted(e) {
    const t = kt.getInstance(e)
    t == null || t.dispose()
  },
}; const Kl = (e) => {
  if (e.classList.contains('offcanvas'))
    return 'offcanvas'
  if (e.classList.contains('collapse'))
    return 'collapse'
  throw new Error('Couldn\'t resolve toggle type')
}; const Jl = (e, t) => {
  const { modifiers: a, arg: l, value: n } = e; const i = Object.keys(a || {}); const f = typeof n == 'string' ? n.split(Qe) : n
  if (Rl(t.tagName, 'a')) {
    const d = fa(t, 'href') || ''
    Tl.test(d) && i.push(d.replace(wl, ''))
  }
  return Array.prototype.concat.apply([], [l, f]).forEach(d => typeof d == 'string' && i.push(d)), i.filter((d, v, b) => d && b.indexOf(d) === v)
}; const Pt = {
  mounted(e, t) {
    const a = Jl(t, e); const l = []
    let n = 'data-bs-target'
    e.tagName === 'a' && (n = 'href')
    for (let i = 0; i < a.length; i++) {
      const f = a[i]; const d = document.getElementById(f)
      d && (e.setAttribute('data-bs-toggle', Kl(d)), l.push(`#${f}`))
    }
    l.length > 0 && e.setAttribute(n, l.join(','))
  },
}
function Yl(e) {
  if (e.manual)
    return 'manual'
  const t = []
  return e.click && t.push('click'), e.hover && t.push('hover'), e.focus && t.push('focus'), t.length > 0 ? t.join(' ') : 'hover focus'
}
function Zl(e) {
  return e.left ? 'left' : e.right ? 'right' : e.bottom ? 'bottom' : 'top'
}
function Ql(e) {
  return e != null && e.delay ? e.delay : 0
}
const en = {
  beforeMount(e, t) {
    e.setAttribute('data-bs-toggle', 'tooltip')
    const a = /<("[^"]*"|'[^']*'|[^'">])*>/.test(e.title); const l = Yl(t.modifiers); const n = Zl(t.modifiers); const i = Ql(t.value); const f = e.getAttribute('title')
    new ht(e, {
      trigger: l,
      placement: n,
      delay: i,
      html: a,
    }), f && e.setAttribute('data-bs-original-title', f)
  },
  updated(e) {
    const t = e.getAttribute('title'); const a = e.getAttribute('data-bs-original-title'); const l = ht.getInstance(e)
    e.removeAttribute('title'), t && t !== a && (l == null || l.setContent({ '.tooltip-inner': t }), e.setAttribute('data-bs-original-title', t))
  },
  unmounted(e) {
    const t = ht.getInstance(e)
    t == null || t.dispose()
  },
}; const et = /* @__PURE__ */ new Map(); const _a = (e) => {
  if (et.has(e)) {
    const t = et.get(e)
    t && t.stop && t.stop(), et.delete(e)
  }
}; const Mt = (e, t) => {
  const a = {
    margin: '0px',
    once: !1,
    callback: t.value,
  }
  Object.keys(t.modifiers).forEach((n) => {
    Number.isInteger(n) ? a.margin = `${n}px` : n.toLowerCase() === 'once' && (a.once = !0)
  }), _a(e)
  const l = new an(
    e,
    a.margin,
    a.once,
    a.callback,
    t.instance,
  )
  et.set(e, l)
}; const tn = {
  beforeMount(e, t) {
    Mt(e, t)
  },
  updated(e, t) {
    Mt(e, t)
  },
  unmounted(e) {
    _a(e)
  },
}
class an {
  constructor(t, a, l, n, i) {
    ve(this, 'element')
    ve(this, 'margin')
    ve(this, 'once')
    ve(this, 'callback')
    ve(this, 'instance')
    ve(this, 'observer')
    ve(this, 'doneOnce')
    ve(this, 'visible')
    this.element = t, this.margin = a, this.once = l, this.callback = n, this.instance = i, this.createObserver()
  }

  createObserver() {
    if (this.observer && this.stop(), !(this.doneOnce || typeof this.callback != 'function')) {
      try {
        this.observer = new IntersectionObserver(this.handler.bind(this), {
          root: null,
          rootMargin: this.margin,
          threshold: 0,
        })
      }
      catch {
        console.error('Intersection Observer not supported'), this.doneOnce = !0, this.observer = void 0, this.callback(null)
        return
      }
      this.instance.$nextTick(() => {
        this.observer && this.observer.observe(this.element)
      })
    }
  }

  handler(t) {
    const [a] = t; const l = Boolean(a.isIntersecting || a.intersectionRatio > 0)
    l !== this.visible && (this.visible = l, this.callback(l), this.once && this.visible && (this.doneOnce = !0, this.stop()))
  }

  stop() {
    this.observer && this.observer.disconnect(), this.observer = null
  }
}
const ln = {
  mounted(e, t) {
    t.value !== !1 && e.focus()
  },
}; const nn = {
  BModal: Ul,
  BPopover: Xl,
  BToggle: Pt,
  BTooltip: en,
  BVisible: tn,
  focus: ln,
}; const on = { class: 'accordion-item' }; const sn = ['id']; const rn = ['aria-expanded', 'aria-controls']; const un = { class: 'accordion-body' }; const dn = /* @__PURE__ */ x({
  __name: 'BAccordionItem',
  props: {
    id: null,
    title: null,
    visible: { default: !1 },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'visible')); const l = ye(o(t, 'id'), 'accordion_item'); const n = Oe(Ta, '')
    return (i, f) => (c(), m('div', on, [
      H('h2', {
        id: `${s(l)}heading`,
        class: 'accordion-header',
      }, [
        Je((c(), m('button', {
          'class': F(['accordion-button', { collapsed: !s(a) }]),
          'type': 'button',
          'aria-expanded': s(a) ? 'true' : 'false',
          'aria-controls': s(l),
        }, [
          p(i.$slots, 'title', {}, () => [
            Y(R(e.title), 1),
          ]),
        ], 10, rn)), [
          [s(Pt), void 0, s(l)],
        ]),
      ], 8, sn),
      ce(Va, {
        'id': s(l),
        'class': 'accordion-collapse',
        'visible': s(a),
        'accordion': s(n),
        'aria-labelledby': `heading${s(l)}`,
      }, {
        default: P(() => [
          H('div', un, [
            p(i.$slots, 'default'),
          ]),
        ]),
        _: 3,
      }, 8, ['id', 'visible', 'accordion', 'aria-labelledby']),
    ]))
  },
}); const cn = ['type', 'disabled', 'aria-label']; const Ge = /* @__PURE__ */ x({
  __name: 'BCloseButton',
  props: {
    ariaLabel: { default: 'Close' },
    disabled: { default: !1 },
    white: { default: !1 },
    type: { default: 'button' },
  },
  emits: ['click'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'disabled')); const n = r(o(a, 'white')); const i = u(() => ({
      'btn-close-white': n.value,
    }))
    return (f, d) => (c(), m('button', {
      'type': e.type,
      'class': F(['btn-close', s(i)]),
      'disabled': s(l),
      'aria-label': e.ariaLabel,
      'onClick': d[0] || (d[0] = v => t('click', v)),
    }, null, 10, cn))
  },
}); const fn = /* @__PURE__ */ x({
  __name: 'BAlert',
  props: {
    dismissLabel: { default: 'Close' },
    dismissible: { default: !1 },
    fade: { default: !1 },
    modelValue: { type: [Boolean, Number], default: !1 },
    show: { default: !1 },
    variant: { default: 'info' },
  },
  emits: ['closed', 'close-count-down', 'update:modelValue'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'dismissible'))
    r(o(a, 'fade'))
    const n = r(o(a, 'show')); const i = X(null); const f = X(); const d = u(() => ({
      [`alert-${a.variant}`]: !!a.variant,
      'show': !!a.modelValue,
      'alert-dismissible': l.value,
      'fade': !!a.modelValue,
    }))
    let v
    const b = (V) => {
      if (typeof V == 'boolean')
        return 0
      const $ = je(V, 0)
      return $ > 0 ? $ : 0
    }; const g = () => {
      v !== void 0 && (clearTimeout(v), v = void 0)
    }; const B = X(b(a.modelValue)); const h = u(() => !!a.modelValue || n.value)
    st(() => {
      let V
      g(), (V = f.value) == null || V.dispose(), f.value = void 0
    })
    const k = u(() => a.modelValue === !0 ? !0 : a.modelValue === !1 || je(a.modelValue, 0) < 1 ? !1 : !!a.modelValue); const w = () => {
      B.value = b(a.modelValue), (k.value || n.value) && !f.value && (f.value = new bl(i.value))
    }; const y = () => {
      typeof a.modelValue == 'boolean' ? t('update:modelValue', !1) : t('update:modelValue', 0), t('closed')
    }
    return re(() => a.modelValue, w), re(() => n.value, w), re(B, (V) => {
      g(), typeof a.modelValue != 'boolean' && (t('close-count-down', V), V === 0 && a.modelValue > 0 && t('closed'), a.modelValue !== V && t('update:modelValue', V), V > 0 && (v = setTimeout(() => {
        B.value--
      }, 1e3)))
    }), (V, $) => s(h)
      ? (c(), m('div', {
          key: 0,
          ref_key: 'element',
          ref: i,
          class: F(['alert', s(d)]),
          role: 'alert',
        }, [
          p(V.$slots, 'default'),
          s(l)
            ? (c(), m(ae, { key: 0 }, [
                V.$slots.close
                  ? (c(), m('button', {
                      'key': 0,
                      'type': 'button',
                      'data-bs-dismiss': 'alert',
                      'onClick': y,
                    }, [
                      p(V.$slots, 'close'),
                    ]))
                  : (c(), O(Ge, {
                      'key': 1,
                      'aria-label': e.dismissLabel,
                      'data-bs-dismiss': 'alert',
                      'onClick': y,
                    }, null, 8, ['aria-label'])),
              ], 64))
            : j('', !0),
        ], 2))
      : j('', !0)
  },
}); const Aa = Symbol(); const vn = /* @__PURE__ */ x({
  __name: 'BAvatarGroup',
  props: {
    overlap: { default: 0.3 },
    rounded: { type: [Boolean, String], default: !1 },
    size: null,
    square: { default: !1 },
    tag: { default: 'div' },
    variant: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'square')); const l = u(() => Tt(t.size)); const n = d => typeof d == 'string' && sa(d) ? ma(d, 0) : d || 0; const i = u(
      () => Math.min(Math.max(n(t.overlap), 0), 1) / 2,
    ); const f = u(() => {
      const d = l.value ? `calc(${l.value} * ${i.value})` : null
      return d ? { paddingLeft: d, paddingRight: d } : {}
    })
    return Me(Aa, {
      overlapScale: i,
      size: t.size,
      square: a.value,
      rounded: t.rounded,
      variant: t.variant,
    }), (d, v) => (c(), O(J(e.tag), {
      class: 'b-avatar-group',
      role: 'group',
    }, {
      default: P(() => [
        H('div', {
          class: 'b-avatar-group-inner',
          style: Ve(s(f)),
        }, [
          p(d.$slots, 'default'),
        ], 4),
      ]),
      _: 3,
    }))
  },
}); const mn = {
  key: 0,
  class: 'b-avatar-custom',
}; const bn = {
  key: 1,
  class: 'b-avatar-img',
}; const gn = ['src', 'alt']; const Tt = (e) => {
  const t = typeof e == 'string' && sa(e) ? ma(e, 0) : e
  return typeof t == 'number' ? `${t}px` : t || null
}; const pn = /* @__PURE__ */ x({
  __name: 'BAvatar',
  props: {
    alt: { default: 'avatar' },
    ariaLabel: null,
    badge: { type: [Boolean, String], default: !1 },
    badgeLeft: { default: !1 },
    badgeOffset: null,
    badgeTop: { default: !1 },
    badgeVariant: { default: 'primary' },
    button: { default: !1 },
    buttonType: { default: 'button' },
    disabled: { default: !1 },
    icon: null,
    rounded: { type: [Boolean, String], default: 'circle' },
    size: null,
    square: { default: !1 },
    src: null,
    text: null,
    textVariant: { default: void 0 },
    variant: { default: 'secondary' },
  },
  emits: ['click', 'img-error'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'badgeLeft')); const n = r(o(a, 'badgeTop')); const i = r(o(a, 'button')); const f = r(o(a, 'disabled')); const d = r(o(a, 'square')); const v = Ee(); const b = ['sm', null, 'lg']; const g = 0.4; const B = g * 0.7; const h = Oe(Aa, null); const k = (Q) => {
      const Z = Q
      return Z === 'light' || Z === 'warning' ? 'dark' : 'light'
    }; const w = u(() => !qt(v.default)); const y = u(() => !qt(v.badge)); const V = u(
      () => a.badge || a.badge === '' || y.value,
    ); const $ = u(
      () => h != null && h.size ? h.size : Tt(a.size),
    ); const z = u(
      () => h != null && h.variant ? h.variant : a.variant,
    ); const L = u(
      () => h != null && h.rounded ? h.rounded : a.rounded,
    ); const S = u(() => ({
      'type': i.value ? a.buttonType : void 0,
      'aria-label': a.ariaLabel || null,
      'disabled': f.value || null,
    })); const A = u(() => ({
      [`bg-${a.badgeVariant}`]: !!a.badgeVariant,
    })); const I = u(() => a.badge === !0 ? '' : a.badge); const M = u(() => `text-${k(a.badgeVariant)}`); const _ = u(() => ({
      [`b-avatar-${a.size}`]: !!a.size && b.includes(Tt(a.size)),
      [`bg-${z.value}`]: !!z.value,
      'badge': !i.value && z.value && w.value,
      'rounded': L.value === '' || L.value === !0,
      'rounded-circle': !d.value && L.value === 'circle',
      'rounded-0': d.value || L.value === '0',
      'rounded-1': !d.value && L.value === 'sm',
      'rounded-3': !d.value && L.value === 'lg',
      'rounded-top': !d.value && L.value === 'top',
      'rounded-bottom': !d.value && L.value === 'bottom',
      'rounded-start': !d.value && L.value === 'left',
      'rounded-end': !d.value && L.value === 'right',
      'btn': i.value,
      [`btn-${z.value}`]: i.value ? !!z.value : !1,
    })); const T = u(() => `text-${a.textVariant || k(z.value)}`); const D = u(() => {
      const Q = a.badgeOffset || '0px'
      return {
        fontSize: (!b.includes($.value || null) ? `calc(${$.value} * ${B})` : '') || '',
        top: n.value ? Q : '',
        bottom: n.value ? '' : Q,
        left: l.value ? Q : '',
        right: l.value ? '' : Q,
      }
    }); const q = u(() => {
      const Q = !b.includes($.value || null) ? `calc(${$.value} * ${g})` : null
      return Q ? { fontSize: Q } : {}
    }); const le = u(() => {
      let Se
      const Q = ((Se = h == null ? void 0 : h.overlapScale) == null ? void 0 : Se.value) || 0; const Z = $.value && Q ? `calc(${$.value} * -${Q})` : null
      return Z ? { marginLeft: Z, marginRight: Z } : {}
    }); const te = u(() => i.value ? 'button' : 'span'); const K = u(() => ({
      ...le.value,
      width: $.value,
      height: $.value,
    })); const de = (Q) => {
      !f.value && i.value && t('click', Q)
    }; const se = Q => t('img-error', Q)
    return (Q, Z) => (c(), O(J(s(te)), W({
      class: ['b-avatar', s(_)],
      style: s(K),
    }, s(S), { onClick: de }), {
      default: P(() => [
        s(w)
          ? (c(), m('span', mn, [
              p(Q.$slots, 'default'),
            ]))
          : e.src
            ? (c(), m('span', bn, [
                H('img', {
                  src: e.src,
                  alt: e.alt,
                  onError: se,
                }, null, 40, gn),
              ]))
            : e.text
              ? (c(), m('span', {
                  key: 2,
                  class: F(['b-avatar-text', s(T)]),
                  style: Ve(s(q)),
                }, R(e.text), 7))
              : j('', !0),
        s(V)
          ? (c(), m('span', {
              key: 3,
              class: F(['b-avatar-badge', s(A)]),
              style: Ve(s(D)),
            }, [
              s(y)
                ? p(Q.$slots, 'badge', { key: 0 })
                : (c(), m('span', {
                    key: 1,
                    class: F(s(M)),
                  }, R(s(I)), 3)),
            ], 6))
          : j('', !0),
      ]),
      _: 3,
    }, 16, ['class', 'style']))
  },
}); const De = {
  active: { type: [Boolean, String], default: !1 },
  activeClass: { type: String, default: 'router-link-active' },
  append: { type: [Boolean, String], default: !1 },
  disabled: { type: [Boolean, String], default: !1 },
  event: { type: [String, Array], default: 'click' },
  exact: { type: [Boolean, String], default: !1 },
  exactActiveClass: { type: String, default: 'router-link-exact-active' },
  href: { type: String },
  rel: { type: String, default: null },
  replace: { type: [Boolean, String], default: !1 },
  routerComponentName: { type: String, default: 'router-link' },
  routerTag: { type: String, default: 'a' },
  target: { type: String, default: '_self' },
  to: { type: [String, Object], default: null },
}; const hn = x({
  props: De,
  emits: ['click'],
  setup(e, { emit: t, attrs: a }) {
    const l = r(o(e, 'active')); const n = r(o(e, 'append')); const i = r(o(e, 'disabled')); const f = r(o(e, 'exact')); const d = r(o(e, 'replace')); const v = sl(); const b = X(null); const g = u(() => {
      const w = e.routerComponentName.split('-').map(V => V.charAt(0).toUpperCase() + V.slice(1)).join('')
      return !((v == null ? void 0 : v.appContext.app.component(w)) !== void 0) || i.value || !e.to ? 'a' : e.routerComponentName
    }); const B = u(() => {
      const w = '#'
      if (e.href)
        return e.href
      if (typeof e.to == 'string')
        return e.to || w
      const y = e.to
      if (Object.prototype.toString.call(y) === '[object Object]' && (y.path || y.query || y.hash)) {
        const V = y.path || ''; const $ = y.query ? `?${Object.keys(y.query).map(L => `${L}=${y.query[L]}`).join('=')}` : ''; const z = !y.hash || y.hash.charAt(0) === '#' ? y.hash || '' : `#${y.hash}`
        return `${V}${$}${z}` || w
      }
      return w
    }); const h = u(() => ({
      'to': e.to,
      'href': B.value,
      'target': e.target,
      'rel': e.target === '_blank' && e.rel === null ? 'noopener' : e.rel || null,
      'tabindex': i.value ? '-1' : typeof a.tabindex > 'u' ? null : a.tabindex,
      'aria-disabled': i.value ? 'true' : null,
    }))
    return {
      tag: g,
      routerAttr: h,
      link: b,
      clicked: (w) => {
        if (i.value) {
          w.preventDefault(), w.stopImmediatePropagation()
          return
        }
        t('click', w)
      },
      activeBoolean: l,
      appendBoolean: n,
      disabledBoolean: i,
      replaceBoolean: d,
      exactBoolean: f,
    }
  },
}); const ke = (e, t) => {
  const a = e.__vccOpts || e
  for (const [l, n] of t)
    a[l] = n
  return a
}
function yn(e, t, a, l, n, i) {
  return e.tag === 'router-link'
    ? (c(), O(J(e.tag), W({ key: 0 }, e.routerAttr, { custom: '' }), {
        default: P(({ href: f, navigate: d, isActive: v, isExactActive: b }) => [
          (c(), O(J(e.routerTag), W({
            ref: 'link',
            href: f,
            class: [
              (v || e.activeBoolean) && e.activeClass,
              (b || e.exactBoolean) && e.exactActiveClass,
            ],
          }, e.$attrs, { onClick: d }), {
            default: P(() => [
              p(e.$slots, 'default'),
            ]),
            _: 2,
          }, 1040, ['href', 'class', 'onClick'])),
        ]),
        _: 3,
      }, 16))
    : (c(), O(J(e.tag), W({
        key: 1,
        ref: 'link',
        class: { active: e.activeBoolean, disabled: e.disabledBoolean },
      }, e.routerAttr, { onClick: e.clicked }), {
        default: P(() => [
          p(e.$slots, 'default'),
        ]),
        _: 3,
      }, 16, ['class', 'onClick']))
}
const Ce = /* @__PURE__ */ ke(hn, [['render', yn]]); const Gt = it(De, ['event', 'routerTag']); const Bn = x({
  components: { BLink: Ce },
  props: {
    pill: { type: [Boolean, String], default: !1 },
    tag: { type: String, default: 'span' },
    variant: { type: String, default: 'secondary' },
    textIndicator: { type: [Boolean, String], default: !1 },
    dotIndicator: { type: [Boolean, String], default: !1 },
    ...Gt,
  },
  setup(e) {
    const t = u(() => Xe(e)); const a = u(() => t.value ? Ce : e.tag); const l = r(o(e, 'pill')); const n = r(o(e, 'textIndicator')); const i = r(o(e, 'dotIndicator'))
    return {
      classes: u(() => ({
        [`bg-${e.variant}`]: e.variant,
        'active': e.active,
        'disabled': e.disabled,
        'text-dark': ['warning', 'info', 'light'].includes(e.variant),
        'rounded-pill': l.value,
        'position-absolute top-0 start-100 translate-middle': n.value || i.value,
        'p-2 border border-light rounded-circle': i.value,
        'text-decoration-none': t.value,
      })),
      props: t.value ? Ot(e, Gt) : {},
      computedTag: a,
    }
  },
})
function $n(e, t, a, l, n, i) {
  return c(), O(J(e.computedTag), W({
    class: ['badge', e.classes],
  }, e.props), {
    default: P(() => [
      p(e.$slots, 'default'),
    ]),
    _: 3,
  }, 16, ['class'])
}
const kn = /* @__PURE__ */ ke(Bn, [['render', $n]]); const Wt = it(De, ['event', 'routerTag']); const Sn = x({
  components: { BLink: Ce },
  props: {
    ...Wt,
    active: { type: [Boolean, String], default: !1 },
    ariaCurrent: { type: String, default: 'location' },
    disabled: { type: [Boolean, String], default: !1 },
    text: { type: String, required: !1 },
  },
  emits: ['click'],
  setup(e, { emit: t }) {
    const a = r(o(e, 'active')); const l = r(o(e, 'disabled')); const n = u(() => ({
      active: a.value,
    })); const i = u(
      () => a.value ? 'span' : Ce,
    ); const f = u(
      () => a.value ? e.ariaCurrent : void 0,
    ); const d = (v) => {
      if (l.value || a.value) {
        v.preventDefault(), v.stopImmediatePropagation()
        return
      }
      l.value || t('click', v)
    }
    return {
      props: i.value !== 'span' ? Ot(e, Wt) : {},
      liClasses: n,
      computedTag: i,
      computedAriaCurrent: f,
      clicked: d,
    }
  },
})
function Cn(e, t, a, l, n, i) {
  return c(), m('li', {
    class: F(['breadcrumb-item', e.liClasses]),
  }, [
    (c(), O(J(e.computedTag), W({ 'aria-current': e.computedAriaCurrent }, e.props, { onClick: e.clicked }), {
      default: P(() => [
        p(e.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }, 16, ['aria-current', 'onClick'])),
  ], 2)
}
const xa = /* @__PURE__ */ ke(Sn, [['render', Cn]]); const wn = { 'aria-label': 'breadcrumb' }; const Tn = { class: 'breadcrumb' }; const Vn = /* @__PURE__ */ x({
  __name: 'BBreadcrumb',
  props: {
    items: null,
  },
  setup(e) {
    const t = e; const a = Ml(); const l = u(() => {
      const n = t.items || (a == null ? void 0 : a.items) || []
      let i = !1
      return n.map((d, v) => (typeof d == 'string' && (d = { text: d }, v < n.length - 1 && (d.href = '#')), d.active && (i = !0), !d.active && !i && (d.active = v + 1 === n.length), d))
    })
    return (n, i) => (c(), m('nav', wn, [
      H('ol', Tn, [
        p(n.$slots, 'prepend'),
        (c(!0), m(ae, null, ie(s(l), (f, d) => (c(), O(xa, W({ key: d }, f), {
          default: P(() => [
            Y(R(f.text), 1),
          ]),
          _: 2,
        }, 1040))), 128)),
        p(n.$slots, 'default'),
        p(n.$slots, 'append'),
      ]),
    ]))
  },
}); const _n = {
  key: 0,
  class: 'visually-hidden',
}; const ft = /* @__PURE__ */ x({
  __name: 'BSpinner',
  props: {
    label: null,
    role: { default: 'status' },
    small: { default: !1 },
    tag: { default: 'span' },
    type: { default: 'border' },
    variant: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'small')); const l = u(() => ({
      'spinner-border': t.type === 'border',
      'spinner-border-sm': t.type === 'border' && a.value,
      'spinner-grow': t.type === 'grow',
      'spinner-grow-sm': t.type === 'grow' && a.value,
      [`text-${t.variant}`]: t.variant !== void 0,
    }))
    return (n, i) => (c(), O(J(e.tag), {
      'class': F(s(l)),
      'role': e.label || n.$slots.label ? e.role : null,
      'aria-hidden': e.label || n.$slots.label ? null : !0,
    }, {
      default: P(() => [
        e.label || n.$slots.label
          ? (c(), m('span', _n, [
              p(n.$slots, 'label', {}, () => [
                Y(R(e.label), 1),
              ]),
            ]))
          : j('', !0),
      ]),
      _: 3,
    }, 8, ['class', 'role', 'aria-hidden']))
  },
}); const An = x({
  components: { BLink: Ce, BSpinner: ft },
  props: {
    ...De,
    active: { type: [Boolean, String], default: !1 },
    disabled: { type: [Boolean, String], default: !1 },
    href: { type: String, required: !1 },
    pill: { type: [Boolean, String], default: !1 },
    pressed: { type: [Boolean, String], default: !1 },
    rel: { type: String, default: void 0 },
    size: { type: String, default: 'md' },
    squared: { type: [Boolean, String], default: !1 },
    tag: { type: String, default: 'button' },
    target: { type: String, default: '_self' },
    type: { type: String, default: 'button' },
    variant: { type: String, default: 'secondary' },
    loading: { type: [Boolean, String], default: !1 },
    loadingMode: { type: String, default: 'inline' },
  },
  emits: ['click', 'update:pressed'],
  setup(e, { emit: t }) {
    const a = r(o(e, 'active')); const l = r(o(e, 'disabled')); const n = r(o(e, 'pill')); const i = r(o(e, 'pressed')); const f = r(o(e, 'squared')); const d = r(o(e, 'loading')); const v = u(() => i.value === !0); const b = u(
      () => e.tag === 'button' && e.href === void 0 && e.to === null,
    ); const g = u(() => Xe(e)); const B = u(() => e.to !== null); const h = u(
      () => e.href !== void 0 ? !1 : !b.value,
    ); const k = u(() => ({
      [`btn-${e.variant}`]: !!e.variant,
      [`btn-${e.size}`]: !!e.size,
      'active': a.value || i.value,
      'rounded-pill': n.value,
      'rounded-0': f.value,
      'disabled': l.value,
    })); const w = u(() => ({
      'aria-disabled': h.value ? l.value : null,
      'aria-pressed': v.value ? i.value : null,
      'autocomplete': v.value ? 'off' : null,
      'disabled': b.value ? l.value : null,
      'href': e.href,
      'rel': g.value ? e.rel : null,
      'role': h.value || g.value ? 'button' : null,
      'target': g.value ? e.target : null,
      'type': b.value ? e.type : null,
      'to': b.value ? null : e.to,
      'append': g.value ? e.append : null,
      'activeClass': B.value ? e.activeClass : null,
      'event': B.value ? e.event : null,
      'exact': B.value ? e.exact : null,
      'exactActiveClass': B.value ? e.exactActiveClass : null,
      'replace': B.value ? e.replace : null,
      'routerComponentName': B.value ? e.routerComponentName : null,
      'routerTag': B.value ? e.routerTag : null,
    })); const y = u(
      () => B.value ? Ce : e.href ? 'a' : e.tag,
    )
    return {
      classes: k,
      attrs: w,
      computedTag: y,
      clicked: ($) => {
        if (l.value) {
          $.preventDefault(), $.stopPropagation()
          return
        }
        t('click', $), v.value && t('update:pressed', !i.value)
      },
      loadingBoolean: d,
    }
  },
})
function xn(e, t, a, l, n, i) {
  const f = la('b-spinner')
  return c(), O(J(e.computedTag), W({
    class: ['btn', e.classes],
  }, e.attrs, { onClick: e.clicked }), {
    default: P(() => [
      e.loadingBoolean
        ? (c(), m('div', {
            key: 0,
            class: F(['btn-loading', { 'mode-fill': e.loadingMode === 'fill', 'mode-inline': e.loadingMode === 'inline' }]),
          }, [
            p(e.$slots, 'loading', {}, () => [
              ce(f, {
                class: 'btn-spinner',
                small: e.size !== 'lg',
              }, null, 8, ['small']),
            ]),
          ], 2))
        : j('', !0),
      H('div', {
        class: F(['btn-content', { 'btn-loading-fill': e.loadingBoolean && e.loadingMode === 'fill' }]),
      }, [
        p(e.$slots, 'default'),
      ], 2),
    ]),
    _: 3,
  }, 16, ['class', 'onClick'])
}
const Ke = /* @__PURE__ */ ke(An, [['render', xn]]); const In = /* @__PURE__ */ x({
  __name: 'BButtonGroup',
  props: {
    ariaLabel: { default: 'Group' },
    size: null,
    tag: { default: 'div' },
    vertical: { default: !1 },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'vertical')); const l = u(() => ({
      'btn-group': !a.value && t.size === void 0,
      [`btn-group-${t.size}`]: t.size !== void 0,
      'btn-group-vertical': a.value,
    }))
    return (n, i) => (c(), O(J(e.tag), {
      'class': F(s(l)),
      'role': 'group',
      'aria-label': e.ariaLabel,
    }, {
      default: P(() => [
        p(n.$slots, 'default'),
      ]),
      _: 3,
    }, 8, ['class', 'aria-label']))
  },
}); const Fn = ['role', 'aria-label']; const On = /* @__PURE__ */ x({
  __name: 'BButtonToolbar',
  props: {
    ariaLabel: { default: 'Group' },
    justify: { default: !1 },
    role: { default: 'toolbar' },
  },
  setup(e) {
    const a = r(o(e, 'justify')); const l = u(() => ({
      'justify-content-between': a.value,
    }))
    return (n, i) => (c(), m('div', {
      'class': F([s(l), 'btn-toolbar']),
      'role': e.role,
      'aria-label': e.ariaLabel,
    }, [
      p(n.$slots, 'default'),
    ], 10, Fn))
  },
}); const Nt = /* @__PURE__ */ x({
  __name: 'BImg',
  props: {
    alt: null,
    blank: { default: !1 },
    blankColor: { default: 'transparent' },
    block: { default: !1 },
    center: { default: !1 },
    fluid: { default: !1 },
    lazy: { default: !1 },
    fluidGrow: { default: !1 },
    height: null,
    left: { default: !1 },
    start: { default: !1 },
    right: { default: !1 },
    end: { default: !1 },
    rounded: { type: [Boolean, String], default: !1 },
    sizes: null,
    src: null,
    srcset: null,
    thumbnail: { default: !1 },
    width: null,
  },
  emits: ['load'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'lazy')); const n = r(o(a, 'blank')); const i = r(o(a, 'block')); const f = r(o(a, 'center')); const d = r(o(a, 'fluid')); const v = r(o(a, 'fluidGrow')); const b = r(o(a, 'left')); const g = r(o(a, 'start')); const B = r(o(a, 'right')); const h = r(o(a, 'end')); const k = r(o(a, 'thumbnail')); const w = '<svg width="%{w}" height="%{h}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 %{w} %{h}" preserveAspectRatio="none"><rect width="100%" height="100%" style="fill:%{f};"></rect></svg>'; const y = (M, _, T) => `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
      w.replace('%{w}', String(M)).replace('%{h}', String(_)).replace('%{f}', T),
    )}`; const V = u(
      () => typeof a.srcset == 'string' ? a.srcset.split(',').filter(M => M).join(',') : Array.isArray(a.srcset) ? a.srcset.filter(M => M).join(',') : void 0,
    ); const $ = u(
      () => typeof a.sizes == 'string' ? a.sizes.split(',').filter(M => M).join(',') : Array.isArray(a.sizes) ? a.sizes.filter(M => M).join(',') : void 0,
    ); const z = u(() => {
      const M = D => D === void 0 ? void 0 : typeof D == 'number' ? D : Number.parseInt(D, 10) || void 0; const _ = M(a.width); const T = M(a.height)
      if (n.value) {
        if (_ !== void 0 && T === void 0)
          return { height: _, width: _ }
        if (_ === void 0 && T !== void 0)
          return { height: T, width: T }
        if (_ === void 0 && T === void 0)
          return { height: 1, width: 1 }
      }
      return {
        width: _,
        height: T,
      }
    }); const L = u(
      () => y(z.value.width, z.value.height, a.blankColor),
    ); const S = u(() => ({
      src: n.value ? L.value : a.src,
      alt: a.alt,
      width: z.value.width || void 0,
      height: z.value.height || void 0,
      srcset: n.value ? void 0 : V.value,
      sizes: n.value ? void 0 : $.value,
      loading: l.value ? 'lazy' : 'eager',
    })); const A = u(
      () => b.value || g.value ? 'float-start' : B.value || h.value ? 'float-end' : f.value ? 'mx-auto' : void 0,
    ); const I = u(() => ({
      'img-thumbnail': k.value,
      'img-fluid': d.value || v.value,
      'w-100': v.value,
      'rounded': a.rounded === '' || a.rounded === !0,
      [`rounded-${a.rounded}`]: typeof a.rounded == 'string' && a.rounded !== '',
      [`${A.value}`]: A.value !== void 0,
      'd-block': i.value || f.value,
    }))
    return (M, _) => (c(), m('img', W({ class: s(I) }, s(S), {
      onLoad: _[0] || (_[0] = T => t('load', T)),
    }), null, 16))
  },
}); const ot = /* @__PURE__ */ x({
  __name: 'BCardImg',
  props: {
    alt: null,
    blank: { default: !1 },
    blankColor: null,
    bottom: { default: !1 },
    lazy: { default: !1 },
    height: null,
    left: { default: !1 },
    start: { default: !1 },
    right: { default: !1 },
    end: { default: !1 },
    sizes: null,
    src: null,
    srcset: null,
    top: { default: !1 },
    width: null,
  },
  emits: ['load'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'bottom')); const n = r(o(a, 'end')); const i = r(o(a, 'left')); const f = r(o(a, 'right')); const d = r(o(a, 'start')); const v = r(o(a, 'top')); const b = u(
      () => v.value ? 'card-img-top' : f.value || n.value ? 'card-img-right' : l.value ? 'card-img-bottom' : i.value || d.value ? 'card-img-left' : 'card-img',
    ); const g = u(() => ({
      alt: a.alt,
      height: a.height,
      src: a.src,
      lazy: a.lazy,
      width: a.width,
      blank: a.blank,
      blankColor: a.blankColor,
      sizes: a.sizes,
      srcset: a.srcset,
    })); const B = u(() => [b.value])
    return (h, k) => (c(), O(Nt, W({ class: s(B) }, s(g), {
      onLoad: k[0] || (k[0] = w => t('load', w)),
    }), null, 16, ['class']))
  },
}); const zn = ['innerHTML']; const Ia = /* @__PURE__ */ x({
  __name: 'BCardHeadFoot',
  props: {
    text: null,
    bgVariant: null,
    borderVariant: null,
    html: null,
    tag: { default: 'div' },
    textVariant: null,
  },
  setup(e) {
    const t = e; const a = u(() => ({
      [`text-${t.textVariant}`]: t.textVariant !== void 0,
      [`bg-${t.bgVariant}`]: t.bgVariant !== void 0,
      [`border-${t.borderVariant}`]: t.borderVariant !== void 0,
    }))
    return (l, n) => (c(), O(J(e.tag), {
      class: F(s(a)),
    }, {
      default: P(() => [
        e.html
          ? (c(), m('div', {
              key: 0,
              innerHTML: e.html,
            }, null, 8, zn))
          : p(l.$slots, 'default', { key: 1 }, () => [
            Y(R(e.text), 1),
          ]),
      ]),
      _: 3,
    }, 8, ['class']))
  },
}); const Fa = /* @__PURE__ */ x({
  __name: 'BCardHeader',
  props: {
    text: null,
    bgVariant: null,
    borderVariant: null,
    html: null,
    tag: { default: 'div' },
    textVariant: null,
  },
  setup(e) {
    return (t, a) => (c(), O(Ia, W({ class: 'card-header' }, t.$props), {
      default: P(() => [
        p(t.$slots, 'default'),
      ]),
      _: 3,
    }, 16))
  },
}); const Oa = /* @__PURE__ */ x({
  __name: 'BCardTitle',
  props: {
    text: null,
    tag: { default: 'h4' },
  },
  setup(e) {
    return (t, a) => (c(), O(J(e.tag), { class: 'card-title' }, {
      default: P(() => [
        p(t.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }))
  },
}); const za = /* @__PURE__ */ x({
  __name: 'BCardSubtitle',
  props: {
    text: null,
    tag: { default: 'h6' },
    textVariant: { default: 'muted' },
  },
  setup(e) {
    const t = e; const a = u(() => ({
      [`text-${t.textVariant}`]: !!t.textVariant,
    }))
    return (l, n) => (c(), O(J(e.tag), {
      class: F(['card-subtitle mb-2', s(a)]),
    }, {
      default: P(() => [
        p(l.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }, 8, ['class']))
  },
}); const Pa = /* @__PURE__ */ x({
  __name: 'BCardBody',
  props: {
    bodyBgVariant: null,
    bodyTag: { default: 'div' },
    bodyTextVariant: null,
    overlay: { default: !1 },
    subtitle: null,
    subtitleTag: { default: 'h4' },
    subtitleTextVariant: null,
    title: null,
    titleTag: { default: 'h4' },
    text: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'overlay')); const l = u(() => ({
      'card-img-overlay': a.value,
      [`text-${t.bodyTextVariant}`]: t.bodyTextVariant !== void 0,
      [`bg-${t.bodyBgVariant}`]: t.bodyBgVariant !== void 0,
    }))
    return (n, i) => (c(), O(J(e.bodyTag), {
      class: F(['card-body', s(l)]),
    }, {
      default: P(() => [
        !!e.title || n.$slots.title
          ? (c(), O(Oa, {
              key: 0,
              tag: e.titleTag,
            }, {
              default: P(() => [
                p(n.$slots, 'title', {}, () => [
                  Y(R(e.title), 1),
                ]),
              ]),
              _: 3,
            }, 8, ['tag']))
          : j('', !0),
        !!e.subtitle || !!n.$slots.subtitle
          ? (c(), O(za, {
              'key': 1,
              'tag': e.subtitleTag,
              'text-variant': e.subtitleTextVariant,
            }, {
              default: P(() => [
                p(n.$slots, 'subtitle', {}, () => [
                  Y(R(e.subtitle), 1),
                ]),
              ]),
              _: 3,
            }, 8, ['tag', 'text-variant']))
          : j('', !0),
        p(n.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }, 8, ['class']))
  },
}); const Na = /* @__PURE__ */ x({
  __name: 'BCardFooter',
  props: {
    text: null,
    bgVariant: null,
    borderVariant: null,
    html: null,
    tag: { default: 'div' },
    textVariant: null,
  },
  setup(e) {
    return (t, a) => (c(), O(Ia, W({ class: 'card-footer' }, t.$props), {
      default: P(() => [
        p(t.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }, 16))
  },
}); const La = /* @__PURE__ */ x({
  __name: 'BCard',
  props: {
    align: null,
    bgVariant: null,
    bodyBgVariant: null,
    bodyClass: null,
    bodyTag: { default: 'div' },
    bodyTextVariant: null,
    borderVariant: null,
    footer: null,
    footerBgVariant: null,
    footerBorderVariant: null,
    footerClass: null,
    footerHtml: { default: '' },
    footerTag: { default: 'div' },
    footerTextVariant: null,
    header: null,
    headerBgVariant: null,
    headerBorderVariant: null,
    headerClass: null,
    headerHtml: { default: '' },
    headerTag: { default: 'div' },
    headerTextVariant: null,
    imgAlt: null,
    imgBottom: { default: !1 },
    imgEnd: { default: !1 },
    imgHeight: null,
    imgLeft: { default: !1 },
    imgRight: { default: !1 },
    imgSrc: null,
    imgStart: { default: !1 },
    imgTop: { default: !1 },
    imgWidth: null,
    noBody: { default: !1 },
    overlay: { default: !1 },
    subtitle: null,
    subtitleTag: { default: 'h6' },
    subtitleTextVariant: { default: 'muted' },
    tag: { default: 'div' },
    textVariant: null,
    title: null,
    titleTag: { default: 'h4' },
    bodyText: { default: '' },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'imgBottom')); const l = r(o(t, 'imgEnd')); const n = r(o(t, 'imgLeft')); const i = r(o(t, 'imgRight')); const f = r(o(t, 'imgStart')); const d = r(o(t, 'noBody')); const v = u(() => ({
      [`text-${t.align}`]: t.align !== void 0,
      [`text-${t.textVariant}`]: t.textVariant !== void 0,
      [`bg-${t.bgVariant}`]: t.bgVariant !== void 0,
      [`border-${t.borderVariant}`]: t.borderVariant !== void 0,
      'flex-row': n.value || f.value,
      'flex-row-reverse': l.value || i.value,
    })); const b = u(() => ({
      bgVariant: t.headerBgVariant,
      borderVariant: t.headerBorderVariant,
      html: t.headerHtml,
      tag: t.headerTag,
      textVariant: t.headerTextVariant,
    })); const g = u(() => ({
      overlay: t.overlay,
      bodyBgVariant: t.bodyBgVariant,
      bodyTag: t.bodyTag,
      bodyTextVariant: t.bodyTextVariant,
      subtitle: t.subtitle,
      subtitleTag: t.subtitleTag,
      subtitleTextVariant: t.subtitleTextVariant,
      title: t.title,
      titleTag: t.titleTag,
    })); const B = u(() => ({
      bgVariant: t.footerBgVariant,
      borderVariant: t.footerBorderVariant,
      html: t.footerHtml,
      tag: t.footerTag,
      textVariant: t.footerTextVariant,
    })); const h = u(() => ({
      src: t.imgSrc,
      alt: t.imgAlt,
      height: t.imgHeight,
      width: t.imgWidth,
      bottom: t.imgBottom,
      end: t.imgEnd,
      left: t.imgLeft,
      right: t.imgRight,
      start: t.imgStart,
      top: t.imgTop,
    }))
    return (k, w) => (c(), O(J(e.tag), {
      class: F(['card', s(v)]),
    }, {
      default: P(() => [
        s(a)
          ? j('', !0)
          : p(k.$slots, 'img', { key: 0 }, () => [
            e.imgSrc ? (c(), O(ot, ge(W({ key: 0 }, s(h))), null, 16)) : j('', !0),
          ]),
        e.header || k.$slots.header || e.headerHtml
          ? (c(), O(Fa, W({ key: 1 }, s(b), { class: e.headerClass }), {
              default: P(() => [
                p(k.$slots, 'header', {}, () => [
                  Y(R(e.header), 1),
                ]),
              ]),
              _: 3,
            }, 16, ['class']))
          : j('', !0),
        s(d)
          ? p(k.$slots, 'default', { key: 3 }, () => [
            Y(R(e.bodyText), 1),
          ])
          : (c(), O(Pa, W({ key: 2 }, s(g), { class: e.bodyClass }), {
              default: P(() => [
                p(k.$slots, 'default', {}, () => [
                  Y(R(e.bodyText), 1),
                ]),
              ]),
              _: 3,
            }, 16, ['class'])),
        e.footer || k.$slots.footer || e.footerHtml
          ? (c(), O(Na, W({ key: 4 }, s(B), { class: e.footerClass }), {
              default: P(() => [
                p(k.$slots, 'footer', {}, () => [
                  Y(R(e.footer), 1),
                ]),
              ]),
              _: 3,
            }, 16, ['class']))
          : j('', !0),
        s(a)
          ? p(k.$slots, 'img', { key: 5 }, () => [
            e.imgSrc ? (c(), O(ot, ge(W({ key: 0 }, s(h))), null, 16)) : j('', !0),
          ])
          : j('', !0),
      ]),
      _: 3,
    }, 8, ['class']))
  },
}); const Pn = /* @__PURE__ */ x({
  __name: 'BCardGroup',
  props: {
    columns: { default: !1 },
    deck: { default: !1 },
    tag: { default: 'div' },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'columns')); const l = r(o(t, 'deck')); const n = u(
      () => l.value ? 'card-deck' : a.value ? 'card-columns' : 'card-group',
    ); const i = u(() => ({
      [n.value]: !!n.value,
    }))
    return (f, d) => (c(), O(J(e.tag), {
      class: F(s(i)),
    }, {
      default: P(() => [
        p(f.$slots, 'default'),
      ]),
      _: 3,
    }, 8, ['class']))
  },
}); const Nn = /* @__PURE__ */ x({
  __name: 'BCardText',
  props: {
    text: null,
    tag: { default: 'p' },
  },
  setup(e) {
    return (t, a) => (c(), O(J(e.tag), { class: 'card-text' }, {
      default: P(() => [
        p(t.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }))
  },
}); const Ln = ['id']; const Hn = {
  key: 0,
  class: 'carousel-indicators',
}; const En = ['data-bs-target', 'data-bs-slide-to', 'aria-label']; const Dn = { class: 'carousel-inner' }; const Rn = ['data-bs-target']; const qn = /* @__PURE__ */ H('span', {
  'class': 'carousel-control-prev-icon',
  'aria-hidden': 'true',
}, null, -1); const jn = { class: 'visually-hidden' }; const Mn = ['data-bs-target']; const Gn = /* @__PURE__ */ H('span', {
  'class': 'carousel-control-next-icon',
  'aria-hidden': 'true',
}, null, -1); const Wn = { class: 'visually-hidden' }; const Ha = Symbol(); const Un = /* @__PURE__ */ x({
  __name: 'BCarousel',
  props: {
    startingSlide: { default: 0 },
    id: null,
    imgHeight: null,
    imgWidth: null,
    background: null,
    modelValue: { default: 0 },
    controls: { default: !1 },
    indicators: { default: !1 },
    interval: { default: 5e3 },
    noTouch: { default: !1 },
    noWrap: { default: !1 },
    controlsPrevText: { default: 'Previous' },
    controlsNextText: { default: 'Next' },
    indicatorsButtonLabel: { default: 'Slide' },
  },
  emits: ['sliding-start', 'sliding-end'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'controls')); const n = r(o(a, 'indicators')); const i = r(o(a, 'noTouch'))
    r(o(a, 'noWrap'))
    const f = Ee(); const d = X(); const v = X(); const b = ye(o(a, 'id'), 'carousel'); const g = X([])
    return me(d, 'slide.bs.carousel', B => t('sliding-start', B)), me(d, 'slid.bs.carousel', B => t('sliding-end', B)), pe(() => {
      v.value = new gl(d.value, {
        wrap: !i.value,
        interval: a.interval,
        touch: !i.value,
      }), f.default && (g.value = f.default().filter((B) => {
        let h
        return ((h = B.type) == null ? void 0 : h.__name) === 'BCarouselSlide'
      }))
    }), Me(Ha, {
      background: a.background,
      width: a.imgWidth,
      height: a.imgHeight,
    }), (B, h) => (c(), m('div', {
      'id': s(b),
      'ref_key': 'element',
      'ref': d,
      'class': 'carousel slide',
      'data-bs-ride': 'carousel',
    }, [
      s(n)
        ? (c(), m('div', Hn, [
            (c(!0), m(ae, null, ie(g.value, (k, w) => (c(), m('button', {
              'key': w,
              'type': 'button',
              'data-bs-target': `#${s(b)}`,
              'data-bs-slide-to': w,
              'class': F(w === e.startingSlide ? 'active' : ''),
              'aria-current': 'true',
              'aria-label': `${e.indicatorsButtonLabel} ${w}`,
            }, null, 10, En))), 128)),
          ]))
        : j('', !0),
      H('div', Dn, [
        p(B.$slots, 'default'),
      ]),
      s(l)
        ? (c(), m(ae, { key: 1 }, [
            H('button', {
              'class': 'carousel-control-prev',
              'type': 'button',
              'data-bs-target': `#${s(b)}`,
              'data-bs-slide': 'prev',
            }, [
              qn,
              H('span', jn, R(e.controlsPrevText), 1),
            ], 8, Rn),
            H('button', {
              'class': 'carousel-control-next',
              'type': 'button',
              'data-bs-target': `#${s(b)}`,
              'data-bs-slide': 'next',
            }, [
              Gn,
              H('span', Wn, R(e.controlsNextText), 1),
            ], 8, Mn),
          ], 64))
        : j('', !0),
    ], 8, Ln))
  },
}); const Xn = ['data-bs-interval']; const Kn = ['innerHTML']; const Jn = { key: 1 }; const Yn = ['innerHTML']; const Zn = { key: 1 }; const Qn = /* @__PURE__ */ x({
  __name: 'BCarouselSlide',
  props: {
    imgSrc: null,
    imgHeight: null,
    imgWidth: null,
    interval: null,
    active: { default: !1 },
    background: null,
    caption: null,
    captionHtml: null,
    captionTag: { default: 'h3' },
    contentTag: { default: 'div' },
    contentVisibleUp: null,
    id: null,
    imgAlt: null,
    imgBlank: { default: !1 },
    imgBlankColor: { default: 'transparent' },
    text: null,
    textHtml: null,
    textTag: { default: 'p' },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'active')); const l = r(o(t, 'imgBlank')); const n = Oe(Ha, {})
    u(
      () => l.value ? l.value : t.imgSrc,
    )
    const i = u(() => ({
      background: `${t.background || n.background || 'rgb(171, 171, 171)'} none repeat scroll 0% 0%`,
    })); const f = u(() => ({
      'd-none': t.contentVisibleUp !== void 0,
      [`d-${t.contentVisibleUp}-block`]: t.contentVisibleUp !== void 0,
    })); const d = u(() => n.width); const v = u(() => n.height)
    return (b, g) => (c(), m('div', {
      'class': F(['carousel-item', { active: s(a) }]),
      'data-bs-interval': e.interval,
      'style': Ve(s(i)),
    }, [
      p(b.$slots, 'img', {}, () => [
        ce(Nt, {
          'class': 'd-block w-100',
          'alt': e.imgAlt,
          'src': e.imgSrc,
          'width': e.imgWidth || s(d),
          'height': e.imgHeight || s(v),
          'blank': s(l),
          'blank-color': e.imgBlankColor,
        }, null, 8, ['alt', 'src', 'width', 'height', 'blank', 'blank-color']),
      ]),
      e.caption || e.captionHtml || e.text || e.textHtml || b.$slots.default
        ? (c(), O(J(e.contentTag), {
            key: 0,
            class: F(['carousel-caption', s(f)]),
          }, {
            default: P(() => [
              e.caption || e.captionHtml
                ? (c(), O(J(e.captionTag), { key: 0 }, {
                    default: P(() => [
                      e.captionHtml
                        ? (c(), m('span', {
                            key: 0,
                            innerHTML: e.captionHtml,
                          }, null, 8, Kn))
                        : (c(), m('span', Jn, R(e.caption), 1)),
                    ]),
                    _: 1,
                  }))
                : j('', !0),
              e.text || e.textHtml
                ? (c(), O(J(e.textTag), { key: 1 }, {
                    default: P(() => [
                      e.textHtml
                        ? (c(), m('span', {
                            key: 0,
                            innerHTML: e.textHtml,
                          }, null, 8, Yn))
                        : (c(), m('span', Zn, R(e.text), 1)),
                    ]),
                    _: 1,
                  }))
                : j('', !0),
              p(b.$slots, 'default'),
            ]),
            _: 3,
          }, 8, ['class']))
        : j('', !0),
    ], 14, Xn))
  },
}); const Ut = dt('', [], { type: [Boolean, String, Number], default: !1 }); const Xt = dt('offset', [''], { type: [String, Number], default: null }); const Kt = dt('order', [''], { type: [String, Number], default: null }); const eo = x({
  name: 'BCol',
  props: {
    col: { type: [Boolean, String], default: !1 },
    cols: { type: [String, Number], default: null },
    ...Ut,
    offset: { type: [String, Number], default: null },
    ...Xt,
    order: { type: [String, Number], default: null },
    ...Kt,
    alignSelf: { type: String, default: null },
    tag: { type: String, default: 'div' },
  },
  setup(e) {
    const t = r(o(e, 'col')); const a = [
      { content: Ut, propPrefix: 'cols', classPrefix: 'col' },
      { content: Xt, propPrefix: 'offset' },
      { content: Kt, propPrefix: 'order' },
    ]; const l = u(
      () => a.flatMap(i => va(e, i.content, i.propPrefix, i.classPrefix)),
    )
    return {
      classes: u(() => ({
        col: t.value || !l.value.some(i => i.startsWith('col-') && !e.cols),
        [`col-${e.cols}`]: !!e.cols,
        [`offset-${e.offset}`]: !!e.offset,
        [`order-${e.order}`]: !!e.order,
        [`align-self-${e.alignSelf}`]: !!e.alignSelf,
      })),
      classList: l,
    }
  },
})
function to(e, t, a, l, n, i) {
  return c(), O(J(e.tag), {
    class: F([e.classes, e.classList]),
  }, {
    default: P(() => [
      p(e.$slots, 'default'),
    ]),
    _: 3,
  }, 8, ['class'])
}
const Ue = /* @__PURE__ */ ke(eo, [['render', to]]); const Lt = /* @__PURE__ */ x({
  __name: 'BTransition',
  props: {
    appear: { default: !1 },
    mode: null,
    noFade: { default: !1 },
    transProps: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'appear')); const l = r(o(t, 'noFade')); const n = u(() => {
      const d = {
        name: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveActiveClass: '',
        leaveToClass: 'showing',
        enterFromClass: 'showing',
        leaveFromClass: '',
      }; const v = {
        ...d,
        enterActiveClass: 'fade showing',
        leaveActiveClass: 'fade showing',
      }
      return l.value ? d : v
    }); const i = u(() => ({ mode: t.mode, css: !0, ...n.value })); const f = u(
      () => t.transProps !== void 0
        ? {
            ...i.value,
            ...t.transProps,
          }
        : a.value
          ? {
              ...i.value,
              appear: !0,
              appearActiveClass: n.value.enterActiveClass,
              appearToClass: n.value.enterToClass,
            }
          : i.value,
    )
    return (d, v) => (c(), O(rl, ge(Be(s(f))), {
      default: P(() => [
        p(d.$slots, 'default'),
      ]),
      _: 3,
    }, 16))
  },
}); const ao = 'toast-title'; const Jt = 1e3; const Ea = x({
  components: { BLink: Ce },
  props: {
    ...De,
    delay: { type: Number, default: 5e3 },
    bodyClass: { type: String },
    body: { type: [Object, String] },
    headerClass: { type: String },
    headerTag: { type: String, default: 'div' },
    animation: { type: [Boolean, String], default: !0 },
    id: { type: String },
    isStatus: { type: [Boolean, String], default: !1 },
    autoHide: { type: [Boolean, String], default: !0 },
    noCloseButton: { type: [Boolean, String], default: !1 },
    noFade: { type: [Boolean, String], default: !1 },
    noHoverPause: { type: [Boolean, String], default: !1 },
    solid: { type: [Boolean, String], default: !1 },
    static: { type: [Boolean, String], default: !1 },
    title: { type: String },
    modelValue: { type: [Boolean, String], default: !1 },
    toastClass: { type: Array },
    variant: { type: String },
  },
  emits: ['destroyed', 'update:modelValue'],
  setup(e, { emit: t, slots: a }) {
    r(o(e, 'animation'))
    const l = r(o(e, 'isStatus')); const n = r(o(e, 'autoHide')); const i = r(o(e, 'noCloseButton')); const f = r(o(e, 'noFade')); const d = r(o(e, 'noHoverPause'))
    r(o(e, 'solid')), r(o(e, 'static'))
    const v = r(o(e, 'modelValue')); const b = X(!1); const g = X(!1); const B = X(!1); const h = u(() => ({
      [`b-toast-${e.variant}`]: e.variant !== void 0,
      show: B.value || b.value,
    }))
    let k, w, y
    const V = () => {
      typeof k > 'u' || (clearTimeout(k), k = void 0)
    }; const $ = u(
      () => Math.max(je(e.delay, 0), Jt),
    ); const z = () => {
      v.value && (w = y = 0, V(), g.value = !0, Ze(() => {
        B.value = !1
      }))
    }; const L = () => {
      V(), t('update:modelValue', !0), w = y = 0, g.value = !1, ze(() => {
        Ze(() => {
          B.value = !0
        })
      })
    }; const S = () => {
      if (!n.value || d.value || !k || y)
        return
      const le = Date.now() - w
      le > 0 && (V(), y = Math.max($.value - le, Jt))
    }; const A = () => {
      (!n.value || d.value || !y) && (y = w = 0), I()
    }
    re(
      () => v.value,
      (le) => {
        le ? L() : z()
      },
    )
    const I = () => {
      V(), n.value && (k = setTimeout(z, y || $.value), w = Date.now(), y = 0)
    }; const M = () => {
      b.value = !0, t('update:modelValue', !0)
    }; const _ = () => {
      b.value = !1, I()
    }; const T = () => {
      b.value = !0
    }; const D = () => {
      b.value = !1, y = w = 0, t('update:modelValue', !1)
    }
    il(() => {
      V(), n.value && t('destroyed', e.id)
    }), pe(() => {
      ze(() => {
        v.value && Ze(() => {
          L()
        })
      })
    })
    const q = () => {
      ze(() => {
        Ze(() => {
          z()
        })
      })
    }
    return () => {
      const le = () => {
        const te = []; const K = Te(ao, { hide: z }, a)
        K ? te.push(oe(K)) : e.title && te.push(oe('strong', { class: 'me-auto' }, e.title)), !i.value && te.length !== 0 && te.push(
          oe(Ge, {
            class: ['btn-close'],
            onClick: () => {
              z()
            },
          }),
        )
        const de = []
        if (te.length > 0 && de.push(
          oe(
            e.headerTag,
            {
              class: 'toast-header',
            },
            { default: () => te },
          ),
        ), Te('default', { hide: z }, a) || e.body) {
          const se = oe(
            Xe(e) ? 'b-link' : 'div',
            {
              class: ['toast-body', e.bodyClass],
              onClick: Xe(e) ? { click: q } : {},
            },
            Te('default', { hide: z }, a) || e.body,
          )
          de.push(se)
        }
        return oe(
          'div',
          {
            class: ['toast', e.toastClass, h.value],
            tabindex: '0',
          },
          de,
        )
      }
      return oe(
        'div',
        {
          'class': ['b-toast'],
          'id': e.id,
          'role': g.value ? null : l.value ? 'status' : 'alert',
          'aria-live': g.value ? null : l.value ? 'polite' : 'assertive',
          'aria-atomic': g.value ? null : 'true',
          'onmouseenter': S,
          'onmouseleave': A,
        },
        [
          oe(
            Lt,
            {
              noFade: f.value,
              onAfterEnter: _,
              onBeforeEnter: M,
              onAfterLeave: D,
              onBeforeLeave: T,
            },
            () => [B.value ? le() : ''],
          ),
        ],
      )
    }
  },
}); const Vt = /* @__PURE__ */ x({
  __name: 'BToaster',
  props: {
    position: { default: 'top-right' },
    instance: null,
  },
  setup(e) {
    const t = e; const a = {
      'top-left': 'top-0 start-0',
      'top-center': 'top-0 start-50 translate-middle-x',
      'top-right': 'top-0 end-0',
      'middle-left': 'top-50 start-0 translate-middle-y',
      'middle-center': 'top-50 start-50 translate-middle',
      'middle-right': 'top-50 end-0 translate-middle-y',
      'bottom-left': 'bottom-0 start-0',
      'bottom-center': 'bottom-0 start-50 translate-middle-x',
      'bottom-right': 'bottom-0 end-0',
    }; const l = u(() => a[t.position]); const n = (i) => {
      let f;
      (f = t.instance) == null || f.remove(i)
    }
    return (i, f) => {
      let d
      return c(), m('div', {
        class: F([[s(l)], 'b-toaster position-fixed p-3']),
        style: { 'z-index': '11' },
      }, [
        (c(!0), m(ae, null, ie((d = e.instance) == null ? void 0 : d.toasts(e.position).value, v => (c(), O(Ea, {
          'id': v.options.id,
          'key': v.options.id,
          'modelValue': v.options.value,
          'onUpdate:modelValue': b => v.options.value = b,
          'delay': v.options.delay,
          'title': v.content.title,
          'body': v.content.body,
          'component': v.content.body,
          'variant': v.options.variant,
          'onDestroyed': n,
        }, null, 8, ['id', 'modelValue', 'onUpdate:modelValue', 'delay', 'title', 'body', 'component', 'variant']))), 128)),
      ], 2)
    }
  },
}); const Re = { delay: 5e3, value: !0, pos: 'top-right' }
class Yt {
  constructor(t) {
    ve(this, 'vm')
    ve(this, 'containerPositions')
    ul(t) ? this.vm = t : this.vm = Fe(t), this.containerPositions = u(() => {
      const a = /* @__PURE__ */ new Set([])
      return this.vm.toasts.map((l) => {
        l.options.pos && a.add(l.options.pos)
      }), a
    })
  }

  toasts(t) {
    return t
      ? u(
        () => this.vm.toasts.filter((a) => {
          if (a.options.pos === t && a.options.value)
            return a
        }),
      )
      : u(() => this.vm.toasts)
  }

  remove(...t) {
    this.vm.toasts = this.vm.toasts.filter((a) => {
      if (a.options.id && !t.includes(a.options.id))
        return a
    })
  }

  isRoot() {
    let t
    return (t = this.vm.root) != null ? t : !1
  }

  show(t, a = Re) {
    const l = { id: Ie(), ...Re, ...a }; const n = {
      options: Fe(l),
      content: t,
    }
    return this.vm.toasts.push(n), n
  }

  info(t, a = Re) {
    return this.show(t, { variant: 'info', ...a })
  }

  danger(t, a = Re) {
    return this.show(t, { variant: 'danger', ...a })
  }

  warning(t, a = Re) {
    return this.show(t, { variant: 'warning', ...a })
  }

  success(t, a = Re) {
    return this.show(t, { variant: 'success', ...a })
  }

  hide() {
  }
}
class lo {
  constructor() {
    ve(this, 'vms')
    ve(this, 'rootInstance')
    ve(this, 'useToast', Ra)
    this.vms = {}
  }

  getOrCreateViewModel(t) {
    if (!t) {
      if (this.rootInstance)
        return this.vms[this.rootInstance]
      const a = { root: !0, toasts: [], container: void 0, id: Symbol('toast') }
      return this.rootInstance = a.id, this.vms[a.id] = a, a
    }
    if (t.root) {
      if (this.rootInstance)
        return this.vms[this.rootInstance]
      this.rootInstance = t.id
    }
    return this.vms[t.id] = t, t
  }

  getVM(t) {
    if (!t && this.rootInstance)
      return this.vms[this.rootInstance]
    if (t)
      return this.vms[t]
  }
}
const _t = Symbol(); const Da = Symbol(); const no = {
  container: void 0,
  toasts: [],
  root: !1,
}
function oo() {
  return Oe(Da)
}
function Ra(e, t = _t) {
  const a = Oe(oo())
  if (!e)
    return new Yt(a.getOrCreateViewModel())
  const l = { id: Symbol('toastInstance') }; const n = { ...no, ...l, ...e }; const i = a.getOrCreateViewModel(n)
  return new Yt(i)
}
const so = {
  install: (e, t = {}) => {
    let a, l, n, i
    e.provide(Da, (l = (a = t == null ? void 0 : t.BToast) == null ? void 0 : a.injectkey) != null ? l : _t), e.provide((i = (n = t == null ? void 0 : t.BToast) == null ? void 0 : n.injectkey) != null ? i : _t, new lo())
  },
}; const ro = x({
  name: 'BContainer',
  props: {
    gutterX: { type: String, default: null },
    gutterY: { type: String, default: null },
    fluid: { type: [Boolean, String], default: !1 },
    toast: { type: Object },
    position: { type: String, required: !1 },
    tag: { type: String, default: 'div' },
  },
  setup(e, { slots: t, expose: a }) {
    const l = X()
    let n
    const i = u(() => ({
      'container': !e.fluid,
      'container-fluid': typeof e.fluid == 'boolean' && e.fluid,
      [`container-${e.fluid}`]: typeof e.fluid == 'string',
      [`gx-${e.gutterX}`]: e.gutterX !== null,
      [`gy-${e.gutterY}`]: e.gutterY !== null,
    }))
    return pe(() => {
      e.toast
    }), e.toast && (n = Ra({ container: l, root: e.toast.root }), a({})), () => {
      let d
      const f = []
      return n == null || n.containerPositions.value.forEach((v) => {
        f.push(oe(Vt, { key: v, instance: n, position: v }))
      }), oe(e.tag, { class: [i.value, e.position], ref: l }, [
        ...f,
        (d = t.default) == null ? void 0 : d.call(t),
      ])
    }
  },
  methods: {},
}); const io = { class: 'visually-hidden' }; const uo = ['aria-labelledby', 'role']; const qa = /* @__PURE__ */ x({
  __name: 'BDropdown',
  props: {
    id: null,
    menuClass: null,
    size: null,
    splitClass: null,
    splitVariant: null,
    text: null,
    toggleClass: null,
    autoClose: { type: [Boolean, String], default: !0 },
    block: { default: !1 },
    boundary: { default: 'clippingParents' },
    dark: { default: !1 },
    disabled: { default: !1 },
    isNav: { default: !1 },
    dropup: { default: !1 },
    dropright: { default: !1 },
    dropleft: { default: !1 },
    noFlip: { default: !1 },
    offset: { default: 0 },
    popperOpts: { default: () => ({}) },
    right: { default: !1 },
    role: { default: 'menu' },
    split: { default: !1 },
    splitButtonType: { default: 'button' },
    splitHref: { default: void 0 },
    noCaret: { default: !1 },
    toggleText: { default: 'Toggle dropdown' },
    variant: { default: 'secondary' },
  },
  emits: ['show', 'shown', 'hide', 'hidden', 'click', 'toggle'],
  setup(e, { expose: t, emit: a }) {
    const l = e; const n = r(o(l, 'block')); const i = r(o(l, 'dark')); const f = r(o(l, 'disabled')); const d = r(o(l, 'dropup')); const v = r(o(l, 'dropright')); const b = r(o(l, 'isNav')); const g = r(o(l, 'dropleft')); const B = r(o(l, 'right')); const h = r(o(l, 'split')); const k = r(o(l, 'noCaret')); const w = X(); const y = X(); const V = X(); const $ = ye(o(l, 'id'), 'dropdown')
    me(w, 'show.bs.dropdown', () => a('show')), me(w, 'shown.bs.dropdown', () => a('shown')), me(w, 'hide.bs.dropdown', () => a('hide')), me(w, 'hidden.bs.dropdown', () => a('hidden'))
    const z = (T) => {
      h.value && a('click', T)
    }; const L = u(() => ({
      'd-grid': n.value,
      'd-flex': n.value && h.value,
    })); const S = u(() => ({
      'nav-link': b.value,
      'dropdown-toggle': !h.value,
      'dropdown-toggle-no-caret': k.value && !h.value,
      'w-100': h.value && n.value,
    })); const A = u(() => ({
      'dropdown-menu-dark': i.value,
      'dropdown-menu-end': B.value,
    })); const I = u(() => ({
      'data-bs-toggle': h.value ? void 0 : 'dropdown',
      'aria-expanded': h.value ? void 0 : !1,
      'ref': h.value ? void 0 : y,
      'href': h.value ? l.splitHref : void 0,
    })); const M = u(() => ({
      ref: h.value ? y : void 0,
    })); const _ = () => {
      let T;
      (T = V.value) == null || T.hide()
    }
    return pe(() => {
      let T
      V.value = new pl((T = y.value) == null ? void 0 : T.$el, {
        autoClose: l.autoClose,
        boundary: l.boundary,
        offset: l.offset ? l.offset.toString() : '',
        reference: l.offset || h.value ? 'parent' : 'toggle',
        popperConfig: (D) => {
          const q = {
            placement: 'bottom-start',
            modifiers: l.noFlip
              ? [
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: [],
                    },
                  },
                ]
              : [],
          }
          return d.value ? q.placement = B.value ? 'top-end' : 'top-start' : v.value ? q.placement = 'right-start' : g.value ? q.placement = 'left-start' : B.value && (q.placement = 'bottom-end'), wt(D, wt(q, l.popperOpts))
        },
      })
    }), t({
      hide: _,
    }), (T, D) => (c(), m('div', {
      ref_key: 'parent',
      ref: w,
      class: F([s(L), 'btn-group']),
    }, [
      ce(Ke, W({
        id: s($),
        variant: e.splitVariant || e.variant,
        size: e.size,
        class: [s(S), s(h) ? e.splitClass : e.toggleClass],
        disabled: s(f),
        type: e.splitButtonType,
      }, s(I), { onClick: z }), {
        default: P(() => [
          p(T.$slots, 'button-content', {}, () => [
            Y(R(e.text), 1),
          ]),
        ]),
        _: 3,
      }, 16, ['id', 'variant', 'size', 'class', 'disabled', 'type']),
      s(h)
        ? (c(), O(Ke, W({
            key: 0,
            variant: e.variant,
            size: e.size,
            disabled: s(f),
          }, s(M), {
            'class': [e.toggleClass, 'dropdown-toggle-split dropdown-toggle'],
            'data-bs-toggle': 'dropdown',
            'aria-expanded': 'false',
            'onClick': D[0] || (D[0] = q => a('toggle')),
          }), {
            default: P(() => [
              H('span', io, [
                p(T.$slots, 'toggle-text', {}, () => [
                  Y(R(e.toggleText), 1),
                ]),
              ]),
            ]),
            _: 3,
          }, 16, ['variant', 'size', 'disabled', 'class']))
        : j('', !0),
      H('ul', {
        'class': F(['dropdown-menu', [e.menuClass, s(A)]]),
        'aria-labelledby': s($),
        'role': e.role,
      }, [
        p(T.$slots, 'default'),
      ], 10, uo),
    ], 2))
  },
}); const co = { role: 'presentation' }; const fo = /* @__PURE__ */ x({
  __name: 'BDropdownDivider',
  props: {
    tag: { default: 'hr' },
  },
  setup(e) {
    return (t, a) => (c(), m('li', co, [
      (c(), O(J(e.tag), {
        'class': 'dropdown-divider',
        'role': 'separator',
        'aria-orientation': 'horizontal',
      })),
    ]))
  },
}); const vo = {}; const mo = { role: 'presentation' }; const bo = { class: 'px-4 py-3' }
function go(e, t) {
  return c(), m('li', mo, [
    H('form', bo, [
      p(e.$slots, 'default'),
    ]),
  ])
}
const po = /* @__PURE__ */ ke(vo, [['render', go]]); const ho = { role: 'presentation' }; const yo = ['id', 'aria-describedby']; const Bo = {
  inheritAttrs: !1,
}; const $o = /* @__PURE__ */ x({
  ...Bo,
  __name: 'BDropdownGroup',
  props: {
    id: null,
    ariaDescribedby: null,
    header: null,
    headerClass: { default: void 0 },
    headerTag: { default: 'header' },
    headerVariant: null,
  },
  setup(e) {
    const t = e; const a = u(
      () => t.id ? `${t.id}_group_dd_header` : void 0,
    ); const l = u(
      () => t.headerTag === 'header' ? void 0 : 'heading',
    ); const n = u(() => ({
      [`text-${t.headerVariant}`]: t.headerVariant !== void 0,
    }))
    return (i, f) => (c(), m('li', ho, [
      (c(), O(J(e.headerTag), {
        id: s(a),
        class: F(['dropdown-header', [s(n), e.headerClass]]),
        role: s(l),
      }, {
        default: P(() => [
          p(i.$slots, 'header', {}, () => [
            Y(R(e.header), 1),
          ]),
        ]),
        _: 3,
      }, 8, ['id', 'class', 'role'])),
      H('ul', W({
        id: e.id,
        role: 'group',
        class: 'list-unstyled',
      }, i.$attrs, {
        'aria-describedby': e.ariaDescribedby || s(a),
      }), [
        p(i.$slots, 'default'),
      ], 16, yo),
    ]))
  },
}); const ko = {}; const So = { class: 'dropdown-header' }
function Co(e, t) {
  return c(), m('li', null, [
    H('h6', So, [
      p(e.$slots, 'default'),
    ]),
  ])
}
const wo = /* @__PURE__ */ ke(ko, [['render', Co]]); const To = {
  inheritAttrs: !1,
}; const Vo = /* @__PURE__ */ x({
  ...To,
  __name: 'BDropdownItem',
  props: {
    href: null,
    linkClass: null,
    active: { default: !1 },
    disabled: { default: !1 },
    rel: { default: void 0 },
    target: { default: '_self' },
    variant: null,
  },
  emits: ['click'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'active')); const n = r(o(a, 'disabled')); const i = na(); const f = u(() => ({
      active: l.value,
      disabled: n.value,
      [`text-${a.variant}`]: a.variant !== void 0,
    })); const d = u(
      () => a.href ? 'a' : i.to ? Ce : 'button',
    ); const v = u(() => ({
      'disabled': n.value,
      'aria-current': l.value ? 'true' : null,
      'href': d.value === 'a' ? a.href : null,
      'rel': a.rel,
      'type': d.value === 'button' ? 'button' : null,
      'target': a.target,
      ...i.to ? { activeClass: 'active', ...i } : {},
    })); const b = g => t('click', g)
    return (g, B) => (c(), m('li', {
      role: 'presentation',
      class: F(g.$attrs.class),
    }, [
      (c(), O(J(s(d)), W({
        class: ['dropdown-item', [s(f), e.linkClass]],
      }, s(v), { onClick: b }), {
        default: P(() => [
          p(g.$slots, 'default'),
        ]),
        _: 3,
      }, 16, ['class'])),
    ], 2))
  },
}); const _o = {
  inheritAttrs: !1,
}; const Ao = /* @__PURE__ */ x({
  ..._o,
  __name: 'BDropdownItemButton',
  props: {
    buttonClass: null,
    active: { default: !1 },
    activeClass: { default: 'active' },
    disabled: { default: !1 },
    variant: null,
  },
  emits: ['click'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'active')); const n = r(o(a, 'disabled')); const i = u(() => ({
      [a.activeClass]: l.value,
      disabled: n.value,
      [`text-${a.variant}`]: a.variant !== void 0,
    })); const f = u(() => ({
      disabled: n.value,
    })); const d = v => t('click', v)
    return (v, b) => (c(), m('li', {
      role: 'presentation',
      class: F(v.$attrs.class),
    }, [
      H('button', W({
        role: 'menu',
        type: 'button',
        class: ['dropdown-item', [s(i), e.buttonClass]],
      }, s(f), { onClick: d }), [
        p(v.$slots, 'default'),
      ], 16),
    ], 2))
  },
}); const xo = { role: 'presentation' }; const Io = { class: 'px-4 py-1 mb-0 text-muted' }; const Fo = /* @__PURE__ */ x({
  __name: 'BDropdownText',
  props: {
    text: { default: '' },
  },
  setup(e) {
    return (t, a) => (c(), m('li', xo, [
      H('p', Io, [
        p(t.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
    ]))
  },
}); const Oo = ['id', 'novalidate', 'onSubmit']; const ja = /* @__PURE__ */ x({
  __name: 'BForm',
  props: {
    id: null,
    floating: { default: !1 },
    novalidate: { default: !1 },
    validated: { default: !1 },
  },
  emits: ['submit'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'floating')); const n = r(o(a, 'novalidate')); const i = r(o(a, 'validated')); const f = u(() => ({
      'form-floating': l.value,
      'was-validated': i.value,
    })); const d = v => t('submit', v)
    return (v, b) => (c(), m('form', {
      id: e.id,
      novalidate: s(n),
      class: F(s(f)),
      onSubmit: rt(d, ['prevent']),
    }, [
      p(v.$slots, 'default'),
    ], 42, Oo))
  },
}); const zo = { class: 'form-floating' }; const Po = ['for']; const No = /* @__PURE__ */ x({
  __name: 'BFormFloatingLabel',
  props: {
    labelFor: null,
    label: null,
    text: null,
  },
  setup(e) {
    return (t, a) => (c(), m('div', zo, [
      p(t.$slots, 'default', {}, () => [
        Y(R(e.text), 1),
      ]),
      H('label', { for: e.labelFor }, [
        p(t.$slots, 'label', {}, () => [
          Y(R(e.label), 1),
        ]),
      ], 8, Po),
    ]))
  },
}); const At = /* @__PURE__ */ x({
  __name: 'BFormInvalidFeedback',
  props: {
    ariaLive: null,
    forceShow: { default: !1 },
    id: null,
    text: null,
    role: null,
    state: { default: void 0 },
    tag: { default: 'div' },
    tooltip: { default: !1 },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'forceShow')); const l = r(o(t, 'state')); const n = r(o(t, 'tooltip')); const i = u(
      () => a.value === !0 || l.value === !1,
    ); const f = u(() => ({
      'd-block': i.value,
      'invalid-feedback': !n.value,
      'invalid-tooltip': n.value,
    })); const d = u(() => ({
      'id': t.id,
      'role': t.role,
      'aria-live': t.ariaLive,
      'aria-atomic': t.ariaLive ? 'true' : void 0,
    }))
    return (v, b) => (c(), O(J(e.tag), W({ class: s(f) }, s(d)), {
      default: P(() => [
        p(v.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }, 16, ['class']))
  },
}); const tt = /* @__PURE__ */ x({
  __name: 'BFormRow',
  props: {
    tag: { default: 'div' },
  },
  setup(e) {
    return (t, a) => (c(), O(J(e.tag), { class: 'row d-flex flex-wrap' }, {
      default: P(() => [
        p(t.$slots, 'default'),
      ]),
      _: 3,
    }))
  },
}); const xt = /* @__PURE__ */ x({
  __name: 'BFormText',
  props: {
    id: null,
    inline: { default: !1 },
    tag: { default: 'small' },
    text: null,
    textVariant: { default: 'muted' },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'inline')); const l = u(() => ({
      'form-text': !a.value,
      [`text-${t.textVariant}`]: !!t.textVariant,
    })); const n = u(() => ({
      id: t.id,
    }))
    return (i, f) => (c(), O(J(e.tag), W({ class: s(l) }, s(n)), {
      default: P(() => [
        p(i.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }, 16, ['class']))
  },
}); const It = /* @__PURE__ */ x({
  __name: 'BFormValidFeedback',
  props: {
    ariaLive: null,
    forceShow: { default: !1 },
    id: null,
    role: null,
    text: null,
    state: { default: void 0 },
    tag: { default: 'div' },
    tooltip: { default: !1 },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'forceShow')); const l = r(o(t, 'state')); const n = r(o(t, 'tooltip')); const i = u(
      () => a.value === !0 || l.value === !0,
    ); const f = u(() => ({
      'd-block': i.value,
      'valid-feedback': !n.value,
      'valid-tooltip': n.value,
    })); const d = u(() => ({
      'id': t.id || null,
      'role': t.role || null,
      'aria-live': t.ariaLive || null,
      'aria-atomic': t.ariaLive ? 'true' : null,
    }))
    return (v, b) => (c(), O(J(e.tag), W({ class: s(f) }, s(d)), {
      default: P(() => [
        p(v.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }, 16, ['class']))
  },
}); const Lo = ['id', 'disabled', 'required', 'name', 'form', 'aria-label', 'aria-labelledby', 'aria-required', 'value', 'indeterminate']; const Ho = ['for']; const Eo = {
  inheritAttrs: !1,
}; const Ma = /* @__PURE__ */ x({
  ...Eo,
  __name: 'BFormCheckbox',
  props: {
    ariaLabel: null,
    ariaLabelledBy: null,
    form: null,
    indeterminate: null,
    name: null,
    id: { default: void 0 },
    autofocus: { default: !1 },
    plain: { default: !1 },
    button: { default: !1 },
    switch: { default: !1 },
    disabled: { default: !1 },
    buttonVariant: { default: 'secondary' },
    inline: { default: !1 },
    required: { default: void 0 },
    size: { default: 'md' },
    state: { default: void 0 },
    uncheckedValue: { type: [Array, Set, Boolean, String, Object, Number], default: !1 },
    value: { type: [Array, Set, Boolean, String, Object, Number], default: !0 },
    modelValue: { type: [Array, Set, Boolean, String, Object, Number], default: void 0 },
  },
  emits: ['update:modelValue', 'input', 'change'],
  setup(e, { emit: t }) {
    const a = e; const l = r(
      o(a, 'indeterminate'),
    ); const n = r(o(a, 'autofocus')); const i = r(o(a, 'plain')); const f = r(o(a, 'button')); const d = r(o(a, 'switch')); const v = r(o(a, 'disabled')); const b = r(o(a, 'inline')); const g = r(o(a, 'required')); const B = r(o(a, 'state')); const h = ye(o(a, 'id'), 'form-check'); const k = X(null); const w = X(!1); const y = u({
      get: () => a.uncheckedValue ? Array.isArray(a.modelValue) ? a.modelValue.includes(a.value) : a.modelValue === a.value : a.modelValue,
      set: (A) => {
        let I = A
        Array.isArray(a.modelValue) ? a.uncheckedValue && (I = a.modelValue, A ? (I.includes(a.uncheckedValue) && I.splice(I.indexOf(a.uncheckedValue), 1), I.push(a.value)) : (I.includes(a.value) && I.splice(I.indexOf(a.value), 1), I.push(a.uncheckedValue))) : I = A ? a.value : a.uncheckedValue, t('input', I), t('update:modelValue', I), t('change', I)
      },
    }); const V = u(() => Array.isArray(a.modelValue) ? a.modelValue.includes(a.value) : JSON.stringify(a.modelValue) === JSON.stringify(a.value)); const $ = Fe({
      plain: o(i, 'value'),
      button: o(f, 'value'),
      inline: o(b, 'value'),
      switch: o(d, 'value'),
      size: o(a, 'size'),
      state: o(B, 'value'),
      buttonVariant: o(a, 'buttonVariant'),
    }); const z = pa($); const L = ha($); const S = ya($)
    return pe(() => {
      n.value && k.value.focus()
    }), (A, I) => (c(), m('div', {
      class: F(s(z)),
    }, [
      Je(H('input', W({ id: s(h) }, A.$attrs, {
        'ref_key': 'input',
        'ref': k,
        'onUpdate:modelValue': I[0] || (I[0] = M => Ye(y) ? y.value = M : null),
        'class': s(L),
        'type': 'checkbox',
        'disabled': s(v),
        'required': !!e.name && !!s(g),
        'name': e.name,
        'form': e.form,
        'aria-label': e.ariaLabel,
        'aria-labelledby': e.ariaLabelledBy,
        'aria-required': e.name && s(g) ? 'true' : void 0,
        'value': e.value,
        'indeterminate': s(l),
        'onFocus': I[1] || (I[1] = M => w.value = !0),
        'onBlur': I[2] || (I[2] = M => w.value = !1),
      }), null, 16, Lo), [
        [dl, s(y)],
      ]),
      A.$slots.default || !s(i)
        ? (c(), m('label', {
            key: 0,
            for: s(h),
            class: F([s(S), { active: s(V), focus: w.value }]),
          }, [
            p(A.$slots, 'default'),
          ], 10, Ho))
        : j('', !0),
    ], 2))
  },
}); const Do = ['id']; const Ro = ['innerHTML']; const qo = ['textContent']; const jo = /* @__PURE__ */ x({
  __name: 'BFormCheckboxGroup',
  props: {
    id: null,
    form: null,
    modelValue: { default: () => [] },
    ariaInvalid: { default: void 0 },
    autofocus: { default: !1 },
    buttonVariant: { default: 'secondary' },
    buttons: { default: !1 },
    disabled: { default: !1 },
    disabledField: { default: 'disabled' },
    htmlField: { default: 'html' },
    name: null,
    options: { default: () => [] },
    plain: { default: !1 },
    required: { default: !1 },
    size: null,
    stacked: { default: !1 },
    state: { default: void 0 },
    switches: { default: !1 },
    textField: { default: 'text' },
    validated: { default: !1 },
    valueField: { default: 'value' },
  },
  emits: ['input', 'update:modelValue', 'change'],
  setup(e, { emit: t }) {
    const a = e
    r(o(a, 'autofocus'))
    const l = r(o(a, 'buttons')); const n = r(o(a, 'disabled'))
    r(o(a, 'plain'))
    const i = r(o(a, 'required')); const f = r(o(a, 'stacked')); const d = r(o(a, 'state')); const v = r(o(a, 'switches')); const b = r(o(a, 'validated')); const g = Ee(); const B = 'BFormCheckbox'; const h = ye(o(a, 'id'), 'checkbox'); const k = ye(o(a, 'name'), 'checkbox'); const w = u({
      get: () => a.modelValue,
      set: (L) => {
        if (JSON.stringify(L) === JSON.stringify(a.modelValue))
          return
        const S = a.options.filter(
          A => L.map(I => JSON.stringify(I)).includes(JSON.stringify(typeof A == 'string' ? A : A[a.valueField])),
        ).map(A => typeof A == 'string' ? A : A[a.valueField])
        t('input', S), t('update:modelValue', S), t('change', S)
      },
    }); const y = u(
      () => (g.first ? nt(g.first(), B, n.value) : []).concat(a.options.map(L => ka(L, a))).concat(g.default ? nt(g.default(), B, n.value) : []).map((L, S) => Sa(L, S, a, k, h)).map(L => ({
        ...L,
        props: {
          switch: v.value,
          ...L.props,
        },
      })),
    ); const V = Fe({
      required: o(i, 'value'),
      ariaInvalid: o(a, 'ariaInvalid'),
      state: o(d, 'value'),
      validated: o(b, 'value'),
      buttons: o(l, 'value'),
      stacked: o(f, 'value'),
      size: o(a, 'size'),
    }); const $ = Ba(V); const z = $a(V)
    return (L, S) => (c(), m('div', W(s($), {
      id: s(h),
      role: 'group',
      class: [s(z), 'bv-no-focus-ring'],
      tabindex: '-1',
    }), [
      (c(!0), m(ae, null, ie(s(y), (A, I) => (c(), O(Ma, W({
        'key': I,
        'modelValue': s(w),
        'onUpdate:modelValue': S[0] || (S[0] = M => Ye(w) ? w.value = M : null),
      }, A.props), {
        default: P(() => [
          A.html
            ? (c(), m('span', {
                key: 0,
                innerHTML: A.html,
              }, null, 8, Ro))
            : (c(), m('span', {
                key: 1,
                textContent: R(A.text),
              }, null, 8, qo)),
        ]),
        _: 2,
      }, 1040, ['modelValue']))), 128)),
    ], 16, Do))
  },
}); const Ga = ['input', 'select', 'textarea']; const Mo = Ga.map(e => `${e}:not([disabled])`).join(); const Go = [...Ga, 'a', 'button', 'label']; const Wo = 'label'; const Uo = 'invalid-feedback'; const Xo = 'valid-feedback'; const Ko = 'description'; const Jo = 'default'; const Yo = x({
  components: { BCol: Ue, BFormInvalidFeedback: At, BFormRow: tt, BFormText: xt, BFormValidFeedback: It },
  props: {
    contentCols: { type: [Boolean, String, Number], required: !1 },
    contentColsLg: { type: [Boolean, String, Number], required: !1 },
    contentColsMd: { type: [Boolean, String, Number], required: !1 },
    contentColsSm: { type: [Boolean, String, Number], required: !1 },
    contentColsXl: { type: [Boolean, String, Number], required: !1 },
    description: { type: [String], required: !1 },
    disabled: { type: [Boolean, String], default: !1 },
    feedbackAriaLive: { type: String, default: 'assertive' },
    id: { type: String, required: !1 },
    invalidFeedback: { type: String, required: !1 },
    label: { type: String, required: !1 },
    labelAlign: { type: [Boolean, String, Number], required: !1 },
    labelAlignLg: { type: [Boolean, String, Number], required: !1 },
    labelAlignMd: { type: [Boolean, String, Number], required: !1 },
    labelAlignSm: { type: [Boolean, String, Number], required: !1 },
    labelAlignXl: { type: [Boolean, String, Number], required: !1 },
    labelClass: { type: [Array, Object, String], required: !1 },
    labelCols: { type: [Boolean, String, Number], required: !1 },
    labelColsLg: { type: [Boolean, String, Number], required: !1 },
    labelColsMd: { type: [Boolean, String, Number], required: !1 },
    labelColsSm: { type: [Boolean, String, Number], required: !1 },
    labelColsXl: { type: [Boolean, String, Number], required: !1 },
    labelFor: { type: String, required: !1 },
    labelSize: { type: String, required: !1 },
    labelSrOnly: { type: [Boolean, String], default: !1 },
    state: { type: [Boolean, String], default: null },
    tooltip: { type: [Boolean, String], default: !1 },
    validFeedback: { type: String, required: !1 },
    validated: { type: [Boolean, String], default: !1 },
    floating: { type: [Boolean, String], default: !1 },
  },
  setup(e, { attrs: t }) {
    const a = r(o(e, 'disabled')); const l = r(o(e, 'labelSrOnly')); const n = r(o(e, 'state')); const i = r(o(e, 'tooltip')); const f = r(o(e, 'validated')); const d = r(o(e, 'floating')); const v = null; const b = ['xs', 'sm', 'md', 'lg', 'xl']; const g = (I, M) => b.reduce((_, T) => {
      const D = jt(T === 'xs' ? '' : T, `${M}Align`); const q = I[D] || null
      return q && (T === 'xs' ? _.push(`text-${q}`) : _.push(`text-${T}-${q}`)), _
    }, []); const B = (I, M) => b.reduce((_, T) => {
      const D = jt(T === 'xs' ? '' : T, `${M}Cols`)
      let q = I[D]
      return q = q === '' ? !0 : q || !1, typeof q != 'boolean' && q !== 'auto' && (q = We(q, 0), q = q > 0 ? q : !1), q && (T === 'xs' ? _.cols = q : _[T || (typeof q == 'boolean' ? 'col' : 'cols')] = q), _
    }, {}); const h = X(); const k = (I, M = null) => {
      if (xl && e.labelFor) {
        const _ = Ll(`#${_l(e.labelFor)}`, h)
        if (_) {
          const T = 'aria-describedby'; const D = (I || '').split(Qe); const q = (M || '').split(Qe); const le = (fa(_, T) || '').split(Qe).filter(te => !q.includes(te)).concat(D).filter((te, K, de) => de.indexOf(te) === K).filter(te => te).join(' ').trim()
          le ? El(_, T, le) : Dl(_, T)
        }
      }
    }; const w = u(() => B(e, 'content')); const y = u(() => g(e, 'label')); const V = u(() => B(e, 'label')); const $ = u(
      () => Object.keys(w.value).length > 0 || Object.keys(V.value).length > 0,
    ); const z = u(
      () => typeof n.value == 'boolean' ? n.value : null,
    ); const L = u(() => {
      const I = z.value
      return I === !0 ? 'is-valid' : I === !1 ? 'is-invalid' : null
    }); const S = u(
      () => ct(t.ariaInvalid, n.value),
    )
    return re(
      () => v,
      (I, M) => {
        I !== M && k(I, M)
      },
    ), pe(() => {
      ze(() => {
        k(v)
      })
    }), {
      disabledBoolean: a,
      labelSrOnlyBoolean: l,
      stateBoolean: n,
      tooltipBoolean: i,
      validatedBoolean: f,
      floatingBoolean: d,
      ariaDescribedby: v,
      computedAriaInvalid: S,
      contentColProps: w,
      isHorizontal: $,
      labelAlignClasses: y,
      labelColProps: V,
      onLegendClick: (I) => {
        if (e.labelFor)
          return
        const { target: M } = I; const _ = M ? M.tagName : ''
        if (Go.includes(_))
          return
        const T = Hl(Mo, h).filter(Nl)
        T.length === 1 && zl(T[0])
      },
      stateClass: L,
    }
  },
  render() {
    const e = this.$props; const t = this.$slots; const a = ye(); const l = !e.labelFor
    let n = null
    const i = Te(Wo, {}, t) || e.label; const f = i ? Ie('_BV_label_') : null
    if (i || this.isHorizontal) {
      const S = l ? 'legend' : 'label'
      if (this.labelSrOnlyBoolean)
        i && (n = oe(
          S,
          {
            class: 'visually-hidden',
            id: f,
            for: e.labelFor || null,
          },
          i,
        )), this.isHorizontal ? n = oe(Ue, this.labelColProps, { default: () => n }) : n = oe('div', {}, [n])
      else {
        const A = {
          onClick: l ? this.onLegendClick : null,
          ...this.isHorizontal ? this.labelColProps : {},
          tag: this.isHorizontal ? S : null,
          id: f,
          for: e.labelFor || null,
          tabIndex: l ? '-1' : null,
          class: [
            this.isHorizontal ? 'col-form-label' : 'form-label',
            {
              'bv-no-focus-ring': l,
              'col-form-label': this.isHorizontal || l,
              'pt-0': !this.isHorizontal && l,
              'd-block': !this.isHorizontal && !l,
              [`col-form-label-${e.labelSize}`]: !!e.labelSize,
            },
            this.labelAlignClasses,
            e.labelClass,
          ],
        }
        this.isHorizontal ? n = oe(Ue, A, { default: () => i }) : n = oe(S, A, i)
      }
    }
    let d = null
    const v = Te(Uo, {}, t) || this.invalidFeedback; const b = v ? Ie('_BV_feedback_invalid_') : void 0
    v && (d = oe(
      At,
      {
        ariaLive: e.feedbackAriaLive,
        id: b,
        state: this.stateBoolean,
        tooltip: this.tooltipBoolean,
      },
      { default: () => v },
    ))
    let g = null
    const B = Te(Xo, {}, t) || this.validFeedback; const h = B ? Ie('_BV_feedback_valid_') : void 0
    B && (g = oe(
      It,
      {
        ariaLive: e.feedbackAriaLive,
        id: h,
        state: this.stateBoolean,
        tooltip: this.tooltipBoolean,
      },
      { default: () => B },
    ))
    let k = null
    const w = Te(Ko, {}, t) || this.description; const y = w ? Ie('_BV_description_') : void 0
    w && (k = oe(
      xt,
      {
        id: y,
      },
      { default: () => w },
    ))
    const V = this.ariaDescribedby = [
      y,
      this.stateBoolean === !1 ? b : null,
      this.stateBoolean === !0 ? h : null,
    ].filter(S => S).join(' ') || null; const $ = [
      Te(Jo, { ariaDescribedby: V, descriptionId: y, id: a, labelId: f }, t) || '',
      d,
      g,
      k,
    ]
    !this.isHorizontal && this.floatingBoolean && $.push(n)
    let z = oe(
      'div',
      {
        ref: 'content',
        class: [
          {
            'form-floating': !this.isHorizontal && this.floatingBoolean,
          },
        ],
      },
      $,
    )
    this.isHorizontal && (z = oe(Ue, { ref: 'content', ...this.contentColProps }, { default: () => $ }))
    const L = {
      'class': [
        'mb-3',
        this.stateClass,
        {
          'was-validated': this.validatedBoolean,
        },
      ],
      'id': ye(o(e, 'id')).value,
      'disabled': l ? this.disabledBoolean : null,
      'role': l ? null : 'group',
      'aria-invalid': this.computedAriaInvalid,
      'aria-labelledby': l && this.isHorizontal ? f : null,
    }
    return this.isHorizontal && !l
      ? oe(tt, L, { default: () => [n, z] })
      : oe(
        l ? 'fieldset' : 'div',
        L,
        this.isHorizontal && l ? [oe(tt, {}, { default: () => [n, z] })] : this.isHorizontal || !this.floatingBoolean ? [n, z] : [z],
      )
  },
}); const Zt = [
  'text',
  'number',
  'email',
  'password',
  'search',
  'url',
  'tel',
  'date',
  'time',
  'range',
  'color',
]; const Zo = x({
  props: {
    ...Ca,
    max: { type: [String, Number], required: !1 },
    min: { type: [String, Number], required: !1 },
    step: { type: [String, Number], required: !1 },
    type: {
      type: String,
      default: 'text',
      validator: e => Zt.includes(e),
    },
  },
  emits: ['update:modelValue', 'change', 'blur', 'input'],
  setup(e, { emit: t }) {
    const a = u(() => {
      const h = e.type === 'range'; const k = e.type === 'color'
      return {
        'form-range': h,
        'form-control': k || !e.plaintext && !h,
        'form-control-color': k,
        'form-control-plaintext': e.plaintext && !h && !k,
        [`form-control-${e.size}`]: !!e.size,
        'is-valid': e.state === !0,
        'is-invalid': e.state === !1,
      }
    }); const l = u(
      () => Zt.includes(e.type) ? e.type : 'text',
    ); const { input: n, computedId: i, computedAriaInvalid: f, onInput: d, onChange: v, onBlur: b, focus: g, blur: B } = wa(e, t)
    return {
      classes: a,
      localType: l,
      input: n,
      computedId: i,
      computedAriaInvalid: f,
      onInput: d,
      onChange: v,
      onBlur: b,
      focus: g,
      blur: B,
    }
  },
}); const Qo = ['id', 'name', 'form', 'type', 'disabled', 'placeholder', 'required', 'autocomplete', 'readonly', 'min', 'max', 'step', 'list', 'aria-required', 'aria-invalid']
function es(e, t, a, l, n, i) {
  return c(), m('input', W({
    'id': e.computedId,
    'ref': 'input',
    'class': e.classes,
    'name': e.name || void 0,
    'form': e.form || void 0,
    'type': e.localType,
    'disabled': e.disabled,
    'placeholder': e.placeholder,
    'required': e.required,
    'autocomplete': e.autocomplete || void 0,
    'readonly': e.readonly || e.plaintext,
    'min': e.min,
    'max': e.max,
    'step': e.step,
    'list': e.type !== 'password' ? e.list : void 0,
    'aria-required': e.required ? 'true' : void 0,
    'aria-invalid': e.computedAriaInvalid,
  }, e.$attrs, {
    onInput: t[0] || (t[0] = f => e.onInput(f)),
    onChange: t[1] || (t[1] = f => e.onChange(f)),
    onBlur: t[2] || (t[2] = f => e.onBlur(f)),
  }), null, 16, Qo)
}
const ts = /* @__PURE__ */ ke(Zo, [['render', es]]); const as = ['id', 'disabled', 'required', 'name', 'form', 'aria-label', 'aria-labelledby', 'value', 'aria-required']; const ls = ['for']; const Wa = /* @__PURE__ */ x({
  __name: 'BFormRadio',
  props: {
    ariaLabel: null,
    ariaLabelledBy: null,
    form: null,
    id: null,
    name: null,
    size: null,
    autofocus: { default: !1 },
    modelValue: { type: [Boolean, String, Array, Object, Number], default: void 0 },
    plain: { default: !1 },
    button: { default: !1 },
    switch: { default: !1 },
    disabled: { default: !1 },
    buttonVariant: { default: 'secondary' },
    inline: { default: !1 },
    required: { default: !1 },
    state: { default: void 0 },
    value: { type: [String, Boolean, Object, Number], default: !0 },
  },
  emits: ['input', 'change', 'update:modelValue'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'autofocus')); const n = r(o(a, 'plain')); const i = r(o(a, 'button')); const f = r(o(a, 'switch')); const d = r(o(a, 'disabled')); const v = r(o(a, 'inline')); const b = r(o(a, 'required')); const g = r(o(a, 'state')); const B = ye(o(a, 'id'), 'form-check'); const h = X(null); const k = X(!1); const w = u({
      get: () => Array.isArray(a.modelValue) ? a.modelValue[0] : a.modelValue,
      set: (S) => {
        const A = S ? a.value : !1; const I = Array.isArray(a.modelValue) ? [A] : A
        t('input', I), t('change', I), t('update:modelValue', I)
      },
    }); const y = u(() => Array.isArray(a.modelValue) ? (a.modelValue || []).find(S => S === a.value) : JSON.stringify(a.modelValue) === JSON.stringify(a.value)); const V = Fe({
      plain: o(n, 'value'),
      button: o(i, 'value'),
      inline: o(v, 'value'),
      switch: o(f, 'value'),
      size: o(a, 'size'),
      state: o(g, 'value'),
      buttonVariant: o(a, 'buttonVariant'),
    }); const $ = pa(V); const z = ha(V); const L = ya(V)
    return pe(() => {
      l.value && h.value.focus()
    }), (S, A) => (c(), m('div', {
      class: F(s($)),
    }, [
      Je(H('input', W({ id: s(B) }, S.$attrs, {
        'ref_key': 'input',
        'ref': h,
        'onUpdate:modelValue': A[0] || (A[0] = I => Ye(w) ? w.value = I : null),
        'class': s(z),
        'type': 'radio',
        'disabled': s(d),
        'required': !!e.name && !!s(b),
        'name': e.name,
        'form': e.form,
        'aria-label': e.ariaLabel,
        'aria-labelledby': e.ariaLabelledBy,
        'value': e.value,
        'aria-required': e.name && s(b) ? !0 : void 0,
        'onFocus': A[1] || (A[1] = I => k.value = !0),
        'onBlur': A[2] || (A[2] = I => k.value = !1),
      }), null, 16, as), [
        [cl, s(w)],
      ]),
      S.$slots.default || !s(n)
        ? (c(), m('label', {
            key: 0,
            for: s(B),
            class: F([s(L), { active: s(y), focus: k.value }]),
          }, [
            p(S.$slots, 'default'),
          ], 10, ls))
        : j('', !0),
    ], 2))
  },
}); const ns = ['id']; const os = ['innerHTML']; const ss = ['textContent']; const rs = /* @__PURE__ */ x({
  __name: 'BFormRadioGroup',
  props: {
    size: null,
    form: null,
    id: null,
    name: null,
    modelValue: { type: [String, Boolean, Array, Object, Number], default: '' },
    ariaInvalid: { default: void 0 },
    autofocus: { default: !1 },
    buttonVariant: { default: 'secondary' },
    buttons: { default: !1 },
    disabled: { default: !1 },
    disabledField: { default: 'disabled' },
    htmlField: { default: 'html' },
    options: { default: () => [] },
    plain: { default: !1 },
    required: { default: !1 },
    stacked: { default: !1 },
    state: { default: void 0 },
    textField: { default: 'text' },
    validated: { default: !1 },
    valueField: { default: 'value' },
  },
  emits: ['input', 'update:modelValue', 'change'],
  setup(e, { emit: t }) {
    const a = e
    r(o(a, 'autofocus'))
    const l = r(o(a, 'buttons')); const n = r(o(a, 'disabled'))
    r(o(a, 'plain'))
    const i = r(o(a, 'required')); const f = r(o(a, 'stacked')); const d = r(o(a, 'state')); const v = r(o(a, 'validated')); const b = Ee(); const g = 'BFormRadio'; const B = ye(o(a, 'id'), 'radio'); const h = ye(o(a, 'name'), 'checkbox'); const k = u({
      get: () => a.modelValue,
      set: (z) => {
        t('input', z), t('update:modelValue', z), t('change', z)
      },
    }); const w = u(
      () => (b.first ? nt(b.first(), g, n.value) : []).concat(a.options.map(z => ka(z, a))).concat(b.default ? nt(b.default(), g, n.value) : []).map((z, L) => Sa(z, L, a, h, B)).map(z => ({
        ...z,
      })),
    ); const y = Fe({
      required: o(i, 'value'),
      ariaInvalid: o(a, 'ariaInvalid'),
      state: o(d, 'value'),
      validated: o(v, 'value'),
      buttons: o(l, 'value'),
      stacked: o(f, 'value'),
      size: o(a, 'size'),
    }); const V = Ba(y); const $ = $a(y)
    return (z, L) => (c(), m('div', W(s(V), {
      id: s(B),
      role: 'radiogroup',
      class: [s($), 'bv-no-focus-ring'],
      tabindex: '-1',
    }), [
      (c(!0), m(ae, null, ie(s(w), (S, A) => (c(), O(Wa, W({
        'key': A,
        'modelValue': s(k),
        'onUpdate:modelValue': L[0] || (L[0] = I => Ye(k) ? k.value = I : null),
      }, S.props), {
        default: P(() => [
          S.html
            ? (c(), m('span', {
                key: 0,
                innerHTML: S.html,
              }, null, 8, os))
            : (c(), m('span', {
                key: 1,
                textContent: R(S.text),
              }, null, 8, ss)),
        ]),
        _: 2,
      }, 1040, ['modelValue']))), 128)),
    ], 16, ns))
  },
}); const is = ['value', 'disabled']; const Ht = /* @__PURE__ */ x({
  __name: 'BFormSelectOption',
  props: {
    value: null,
    disabled: { default: !1 },
  },
  setup(e) {
    const a = r(o(e, 'disabled'))
    return (l, n) => (c(), m('option', {
      value: e.value,
      disabled: s(a),
    }, [
      p(l.$slots, 'default'),
    ], 8, is))
  },
}); const us = ['label']; const Ua = /* @__PURE__ */ x({
  __name: 'BFormSelectOptionGroup',
  props: {
    label: null,
    disabledField: { default: 'disabled' },
    htmlField: { default: 'html' },
    options: { default: () => [] },
    textField: { default: 'text' },
    valueField: { default: 'value' },
  },
  setup(e) {
    const t = e; const a = u(
      () => zt(t.options, 'BFormSelectOptionGroup', t),
    )
    return (l, n) => (c(), m('optgroup', { label: e.label }, [
      p(l.$slots, 'first'),
      (c(!0), m(ae, null, ie(s(a), (i, f) => (c(), O(Ht, W({
        key: `option_${f}`,
        value: i.value,
        disabled: i.disabled,
      }, l.$attrs, {
        innerHTML: i.html || i.text,
      }), null, 16, ['value', 'disabled', 'innerHTML']))), 128)),
      p(l.$slots, 'default'),
    ], 8, us))
  },
}); const ds = ['id', 'name', 'form', 'multiple', 'size', 'disabled', 'required', 'aria-required', 'aria-invalid']; const cs = /* @__PURE__ */ x({
  __name: 'BFormSelect',
  props: {
    ariaInvalid: { default: void 0 },
    autofocus: { default: !1 },
    disabled: { default: !1 },
    disabledField: { default: 'disabled' },
    form: null,
    htmlField: { default: 'html' },
    id: null,
    labelField: { default: 'label' },
    multiple: { default: !1 },
    name: null,
    options: { default: () => [] },
    optionsField: { default: 'options' },
    plain: { default: !1 },
    required: { default: !1 },
    selectSize: { default: 0 },
    size: null,
    state: { default: void 0 },
    textField: { default: 'text' },
    valueField: { default: 'value' },
    modelValue: { default: '' },
  },
  emits: ['input', 'update:modelValue', 'change'],
  setup(e, { expose: t, emit: a }) {
    const l = e; const n = r(o(l, 'autofocus')); const i = r(o(l, 'disabled')); const f = r(o(l, 'multiple')); const d = r(o(l, 'plain')); const v = r(o(l, 'required')); const b = r(o(l, 'state')); const g = X(); const B = ye(o(l, 'id'), 'input'); const h = () => {
      ze(() => {
        let S
        n.value && ((S = g.value) == null || S.focus())
      })
    }
    pe(h), Ft(h)
    const k = u(() => ({
      'form-control': d.value,
      [`form-control-${l.size}`]: l.size && d.value,
      'form-select': !d.value,
      [`form-select-${l.size}`]: l.size && !d.value,
      'is-valid': b.value === !0,
      'is-invalid': b.value === !1,
    })); const w = u(() => {
      if (l.selectSize || d.value)
        return l.selectSize
    }); const y = u(
      () => ct(l.ariaInvalid, b.value),
    ); const V = u(
      () => zt(l.options, 'BFormSelect', l),
    ); const $ = u({
      get() {
        return l.modelValue
      },
      set(S) {
        a('change', S), a('update:modelValue', S), a('input', S)
      },
    })
    return t({
      blur: () => {
        let S
        i.value || (S = g.value) == null || S.blur()
      },
      focus: () => {
        let S
        i.value || (S = g.value) == null || S.focus()
      },
    }), (S, A) => Je((c(), m('select', W({
      id: s(B),
      ref_key: 'input',
      ref: g,
    }, S.$attrs, {
      'onUpdate:modelValue': A[0] || (A[0] = I => Ye($) ? $.value = I : null),
      'class': s(k),
      'name': e.name,
      'form': e.form || void 0,
      'multiple': s(f) || void 0,
      'size': s(w),
      'disabled': s(i),
      'required': s(v),
      'aria-required': s(v) ? !0 : void 0,
      'aria-invalid': s(y),
    }), [
      p(S.$slots, 'first'),
      (c(!0), m(ae, null, ie(s(V), (I, M) => (c(), m(ae, null, [
        Array.isArray(I.options)
          ? (c(), O(Ua, {
              key: `option_${M}`,
              label: I.label,
              options: I.options,
            }, null, 8, ['label', 'options']))
          : (c(), O(Ht, {
              key: `option2_${M}`,
              value: I.value,
              disabled: I.disabled,
              innerHTML: I.html || I.text,
            }, null, 8, ['value', 'disabled', 'innerHTML'])),
      ], 64))), 256)),
      p(S.$slots, 'default'),
    ], 16, ds)), [
      [fl, s($)],
    ])
  },
}); const fs = ['id']; const Xa = /* @__PURE__ */ x({
  __name: 'BFormTag',
  props: {
    id: null,
    title: null,
    disabled: { default: !1 },
    noRemove: { default: !1 },
    pill: { default: !1 },
    removeLabel: { default: 'Remove tag' },
    tag: { default: 'span' },
    variant: { default: 'secondary' },
  },
  emits: ['remove'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'disabled')); const n = r(o(a, 'noRemove')); const i = r(o(a, 'pill')); const f = Ee(); const d = u(
      () => {
        let B, h, k
        return (k = ((h = (B = f.default) == null ? void 0 : B.call(f)[0].children) == null ? void 0 : h.toString()) || a.title) != null ? k : ''
      },
    ); const v = ye(o(a, 'id')); const b = u(() => `${v.value}taglabel__`); const g = u(() => [
      `bg-${a.variant}`,
      {
        'text-dark': ['warning', 'info', 'light'].includes(a.variant),
        'rounded-pill': i.value,
        'disabled': l.value,
      },
    ])
    return (B, h) => (c(), O(J(e.tag), {
      'id': s(v),
      'title': s(d),
      'class': F(['badge b-form-tag d-inline-flex align-items-center mw-100', s(g)]),
      'aria-labelledby': s(b),
    }, {
      default: P(() => [
        H('span', {
          id: s(b),
          class: 'b-form-tag-content flex-grow-1 text-truncate',
        }, [
          p(B.$slots, 'default', {}, () => [
            Y(R(s(d)), 1),
          ]),
        ], 8, fs),
        !s(l) && !s(n)
          ? (c(), O(Ge, {
              'key': 0,
              'aria-keyshortcuts': 'Delete',
              'type': 'button',
              'aria-label': e.removeLabel,
              'class': 'b-form-tag-remove',
              'white': !['warning', 'info', 'light'].includes(e.variant),
              'aria-describedby': s(b),
              'aria-controls': e.id,
              'onClick': h[0] || (h[0] = k => t('remove', s(d))),
            }, null, 8, ['aria-label', 'white', 'aria-describedby', 'aria-controls']))
          : j('', !0),
      ]),
      _: 3,
    }, 8, ['id', 'title', 'class', 'aria-labelledby']))
  },
}); const vs = ['id']; const ms = ['id', 'for', 'aria-live']; const bs = ['id', 'aria-live']; const gs = ['id']; const ps = ['aria-controls']; const hs = {
  role: 'group',
  class: 'd-flex',
}; const ys = ['id', 'disabled', 'value', 'type', 'placeholder', 'form', 'required']; const Bs = ['disabled']; const $s = {
  'aria-live': 'polite',
  'aria-atomic': 'true',
}; const ks = {
  key: 0,
  class: 'd-block invalid-feedback',
}; const Ss = {
  key: 1,
  class: 'form-text text-muted',
}; const Cs = {
  key: 2,
  class: 'form-text text-muted',
}; const ws = ['name', 'value']; const Ts = /* @__PURE__ */ x({
  __name: 'BFormTags',
  props: {
    addButtonText: { default: 'Add' },
    addButtonVariant: { default: 'outline-secondary' },
    addOnChange: { default: !1 },
    autofocus: { default: !1 },
    disabled: { default: !1 },
    duplicateTagText: { default: 'Duplicate tag(s)' },
    inputAttrs: null,
    inputClass: null,
    inputId: null,
    inputType: { default: 'text' },
    invalidTagText: { default: 'Invalid tag(s)' },
    form: null,
    limit: null,
    limitTagsText: { default: 'Tag limit reached' },
    modelValue: { default: () => [] },
    name: null,
    noAddOnEnter: { default: !1 },
    noOuterFocus: { default: !1 },
    noTagRemove: { default: !1 },
    placeholder: { default: 'Add tag...' },
    removeOnDelete: { default: !1 },
    required: { default: !1 },
    separator: null,
    state: { default: void 0 },
    size: null,
    tagClass: null,
    tagPills: { default: !1 },
    tagRemoveLabel: null,
    tagRemovedLabel: { default: 'Tag removed' },
    tagValidator: { type: Function, default: () => !0 },
    tagVariant: { default: 'secondary' },
  },
  emits: ['update:modelValue', 'input', 'tag-state', 'focus', 'focusin', 'focusout', 'blur'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'addOnChange')); const n = r(o(a, 'autofocus')); const i = r(o(a, 'disabled')); const f = r(o(a, 'noAddOnEnter')); const d = r(o(a, 'noOuterFocus')); const v = r(o(a, 'noTagRemove')); const b = r(o(a, 'removeOnDelete')); const g = r(o(a, 'required')); const B = r(o(a, 'state')); const h = r(o(a, 'tagPills')); const k = X(null); const w = ye(); const y = u(() => a.inputId || `${w.value}input__`)
    pe(() => {
      te(), a.modelValue.length > 0 && (z.value = !0)
    }), Ft(() => te()), re(
      () => a.modelValue,
      (U) => {
        V.value = U
      },
    )
    const V = X(a.modelValue); const $ = X(''); const z = X(!1); const L = X(!1); const S = X(''); const A = X([]); const I = X([]); const M = X([]); const _ = u(() => ({
      [`form-control-${a.size}`]: a.size !== void 0,
      'disabled': i.value,
      'focus': L.value,
      'is-invalid': B.value === !1,
      'is-valid': B.value === !0,
    })); const T = u(() => V.value.includes($.value)); const D = u(
      () => $.value === '' ? !1 : !a.tagValidator($.value),
    ); const q = u(() => V.value.length === a.limit); const le = u(() => !D.value && !T.value); const te = () => {
      let U
      n.value && ((U = k.value) == null || U.focus())
    }; const K = (U) => {
      if (i.value) {
        U.target.blur()
        return
      }
      t('focusin', U)
    }; const de = (U) => {
      i.value || d.value || (L.value = !0, t('focus', U))
    }; const se = (U) => {
      L.value = !1, t('blur', U)
    }; const Q = (U) => {
      let G, be
      const ee = typeof U == 'string' ? U : U.target.value
      if (z.value = !1, ((G = a.separator) == null ? void 0 : G.includes(ee.charAt(0))) && ee.length > 0) {
        k.value && (k.value.value = '')
        return
      }
      if ($.value = ee, (be = a.separator) != null && be.includes(ee.charAt(ee.length - 1))) {
        ue(ee.slice(0, ee.length - 1))
        return
      }
      A.value = a.tagValidator(ee) && !T.value ? [ee] : [], I.value = a.tagValidator(ee) ? [] : [ee], M.value = T.value ? [ee] : [], t('tag-state', A.value, I.value, M.value)
    }; const Z = (U) => {
      l.value && (Q(U), T.value || ue($.value))
    }; const Se = (U) => {
      if (U.key === 'Enter' && !f.value) {
        ue($.value)
        return
      }
      (U.key === 'Backspace' || U.key === 'Delete') && b.value && $.value === '' && z.value && V.value.length > 0 ? $e(V.value[V.value.length - 1]) : z.value = !0
    }; const ue = (U) => {
      let G
      if (U = (U || $.value).trim(), U === '' || T.value || !a.tagValidator(U) || a.limit && q.value)
        return
      const ee = [...a.modelValue, U]
      $.value = '', z.value = !0, t('update:modelValue', ee), t('input', ee), (G = k.value) == null || G.focus()
    }; const $e = (U) => {
      let G
      const ee = V.value.indexOf((G = U == null ? void 0 : U.toString()) != null ? G : '')
      S.value = V.value.splice(ee, 1).toString(), t('update:modelValue', V.value)
    }; const Ae = u(() => {
      const {
        addButtonText: U,
        addButtonVariant: ee,
        duplicateTagText: G,
        inputAttrs: be,
        form: xe,
        inputType: mt,
        invalidTagText: bt,
        limitTagsText: gt,
        limit: Ne,
        placeholder: C,
        separator: E,
        size: ne,
        tagClass: N,
        tagRemoveLabel: he,
        tagVariant: fe,
      } = a
      return {
        addButtonText: U,
        addButtonVariant: ee,
        addTag: ue,
        disableAddButton: le,
        disabled: i.value,
        duplicateTagText: G,
        duplicateTags: M,
        form: xe,
        inputAttrs: {
          ...be,
          disabled: i.value,
          form: xe,
          id: y,
          value: $,
        },
        inputHandlers: {
          input: Q,
          keydown: Se,
          change: Z,
        },
        inputId: y,
        inputType: mt,
        invalidTagText: bt,
        invalidTags: I,
        isDuplicate: T,
        isInvalid: D,
        isLimitReached: q,
        limitTagsText: gt,
        limit: Ne,
        noTagRemove: v.value,
        placeholder: C,
        removeTag: $e,
        required: g.value,
        separator: E,
        size: ne,
        state: B.value,
        tagClass: N,
        tagPills: h.value,
        tagRemoveLabel: he,
        tagVariant: fe,
        tags: V,
      }
    })
    return (U, ee) => (c(), m('div', {
      id: s(w),
      class: F(['b-form-tags form-control h-auto', s(_)]),
      role: 'group',
      tabindex: '-1',
      onFocusin: K,
      onFocusout: ee[1] || (ee[1] = G => t('focusout', G)),
    }, [
      H('output', {
        'id': `${s(w)}selected_tags__`,
        'class': 'visually-hidden',
        'role': 'status',
        'for': s(y),
        'aria-live': L.value ? 'polite' : 'off',
        'aria-atomic': 'true',
        'aria-relevant': 'additions text',
      }, R(V.value.join(', ')), 9, ms),
      H('div', {
        'id': `${s(w)}removed_tags__`,
        'role': 'status',
        'aria-live': L.value ? 'assertive' : 'off',
        'aria-atomic': 'true',
        'class': 'visually-hidden',
      }, ` (${R(e.tagRemovedLabel)}) ${R(S.value)}`, 9, bs),
      p(U.$slots, 'default', ge(Be(s(Ae))), () => [
        H('ul', {
          id: `${s(w)}tag_list__`,
          class: 'b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center',
        }, [
          (c(!0), m(ae, null, ie(V.value, G => p(U.$slots, 'tag', ge(Be({ tag: G, tagClass: e.tagClass, tagVariant: e.tagVariant, tagPillsBoolean: s(h), removeTag: $e })), () => [
            (c(), O(Xa, {
              key: G,
              class: F(e.tagClass),
              tag: 'li',
              variant: e.tagVariant,
              pill: s(h),
              onRemove: $e,
            }, {
              default: P(() => [
                Y(R(G), 1),
              ]),
              _: 2,
            }, 1032, ['class', 'variant', 'pill'])),
          ])), 256)),
          H('li', {
            'role': 'none',
            'aria-live': 'off',
            'class': 'b-from-tags-field flex-grow-1',
            'aria-controls': `${s(w)}tag_list__`,
          }, [
            H('div', hs, [
              H('input', W({
                id: s(y),
                ref_key: 'input',
                ref: k,
                disabled: s(i),
                value: $.value,
                type: e.inputType,
                placeholder: e.placeholder,
                class: 'b-form-tags-input w-100 flex-grow-1 p-0 m-0 bg-transparent border-0',
                style: { 'outline': 'currentcolor none 0px', 'min-width': '5rem' },
              }, e.inputAttrs, {
                form: e.form,
                required: s(g),
                onInput: Q,
                onChange: Z,
                onKeydown: Se,
                onFocus: de,
                onBlur: se,
              }), null, 16, ys),
              s(le)
                ? (c(), m('button', {
                    key: 0,
                    type: 'button',
                    class: F(['btn b-form-tags-button py-0', [
                  `btn-${e.addButtonVariant}`,
                  {
                    'disabled invisible': $.value.length === 0,
                  },
                  e.inputClass,
                    ]]),
                    style: { 'font-size': '90%' },
                    disabled: s(i) || $.value.length === 0 || s(q),
                    onClick: ee[0] || (ee[0] = G => ue($.value)),
                  }, [
                    p(U.$slots, 'add-button-text', {}, () => [
                      Y(R(e.addButtonText), 1),
                    ]),
                  ], 10, Bs))
                : j('', !0),
            ]),
          ], 8, ps),
        ], 8, gs),
        H('div', $s, [
          s(D) ? (c(), m('div', ks, `${R(e.invalidTagText)}: ${R($.value)}`, 1)) : j('', !0),
          s(T) ? (c(), m('small', Ss, `${R(e.duplicateTagText)}: ${R($.value)}`, 1)) : j('', !0),
          V.value.length === e.limit ? (c(), m('small', Cs, 'Tag limit reached')) : j('', !0),
        ]),
      ]),
      e.name
        ? (c(!0), m(ae, { key: 0 }, ie(V.value, G => (c(), m('input', {
            key: G,
            type: 'hidden',
            name: e.name,
            value: G,
          }, null, 8, ws))), 128))
        : j('', !0),
    ], 42, vs))
  },
}); const Vs = x({
  props: {
    ...Ca,
    noResize: { type: [Boolean, String], default: !1 },
    rows: { type: [String, Number], required: !1, default: 2 },
    wrap: { type: String, default: 'soft' },
  },
  emits: ['update:modelValue', 'change', 'blur', 'input'],
  setup(e, { emit: t }) {
    const a = r(o(e, 'noResize')); const l = u(() => ({
      'form-control': !e.plaintext,
      'form-control-plaintext': e.plaintext,
      [`form-control-${e.size}`]: !!e.size,
      'is-valid': e.state === !0,
      'is-invalid': e.state === !1,
    })); const n = u(
      () => a.value ? { resize: 'none' } : void 0,
    ); const { input: i, computedId: f, computedAriaInvalid: d, onInput: v, onChange: b, onBlur: g, focus: B, blur: h } = wa(e, t)
    return {
      input: i,
      computedId: f,
      computedAriaInvalid: d,
      onInput: v,
      onChange: b,
      onBlur: g,
      focus: B,
      blur: h,
      classes: l,
      computedStyles: n,
    }
  },
}); const _s = ['id', 'name', 'form', 'disabled', 'placeholder', 'required', 'autocomplete', 'readonly', 'aria-required', 'aria-invalid', 'rows', 'wrap']
function As(e, t, a, l, n, i) {
  return c(), m('textarea', W({
    'id': e.computedId,
    'ref': 'input',
    'class': e.classes,
    'name': e.name || void 0,
    'form': e.form || void 0,
    'disabled': e.disabled,
    'placeholder': e.placeholder,
    'required': e.required,
    'autocomplete': e.autocomplete || void 0,
    'readonly': e.readonly || e.plaintext,
    'aria-required': e.required ? 'true' : void 0,
    'aria-invalid': e.computedAriaInvalid,
    'rows': e.rows,
    'style': e.computedStyles,
    'wrap': e.wrap || void 0,
  }, e.$attrs, {
    onInput: t[0] || (t[0] = f => e.onInput(f)),
    onChange: t[1] || (t[1] = f => e.onChange(f)),
    onBlur: t[2] || (t[2] = f => e.onBlur(f)),
  }), null, 16, _s)
}
const xs = /* @__PURE__ */ ke(Vs, [['render', As]]); const Is = {
  key: 0,
  class: 'input-group-text',
}; const Fs = ['innerHTML']; const Os = { key: 1 }; const zs = {
  key: 0,
  class: 'input-group-text',
}; const Ps = ['innerHTML']; const Ns = { key: 1 }; const Ls = /* @__PURE__ */ x({
  __name: 'BInputGroup',
  props: {
    append: null,
    appendHtml: null,
    id: null,
    prepend: null,
    prependHtml: null,
    size: null,
    tag: { default: 'div' },
  },
  setup(e) {
    const t = e; const a = u(() => ({
      'input-group-sm': t.size === 'sm',
      'input-group-lg': t.size === 'lg',
    })); const l = u(() => !!t.append || !!t.appendHtml); const n = u(() => !!t.prepend || !!t.prependHtml)
    return (i, f) => (c(), O(J(e.tag), {
      id: e.id,
      class: F(['input-group', s(a)]),
      role: 'group',
    }, {
      default: P(() => [
        p(i.$slots, 'prepend', {}, () => [
          s(n)
            ? (c(), m('span', Is, [
                e.prependHtml
                  ? (c(), m('span', {
                      key: 0,
                      innerHTML: e.prependHtml,
                    }, null, 8, Fs))
                  : (c(), m('span', Os, R(e.prepend), 1)),
              ]))
            : j('', !0),
        ]),
        p(i.$slots, 'default'),
        p(i.$slots, 'append', {}, () => [
          s(l)
            ? (c(), m('span', zs, [
                e.appendHtml
                  ? (c(), m('span', {
                      key: 0,
                      innerHTML: e.appendHtml,
                    }, null, 8, Ps))
                  : (c(), m('span', Ns, R(e.append), 1)),
              ]))
            : j('', !0),
        ]),
      ]),
      _: 3,
    }, 8, ['id', 'class']))
  },
}); const Ka = /* @__PURE__ */ x({
  __name: 'BInputGroupText',
  props: {
    tag: { default: 'div' },
    text: null,
  },
  setup(e) {
    return (t, a) => (c(), O(J(e.tag), { class: 'input-group-text' }, {
      default: P(() => [
        p(t.$slots, 'default', {}, () => [
          Y(R(e.text), 1),
        ]),
      ]),
      _: 3,
    }))
  },
}); const Et = /* @__PURE__ */ x({
  __name: 'BInputGroupAddon',
  props: {
    isText: { default: !1 },
  },
  setup(e) {
    const a = r(o(e, 'isText'))
    return (l, n) => s(a)
      ? (c(), O(Ka, { key: 0 }, {
          default: P(() => [
            p(l.$slots, 'default'),
          ]),
          _: 3,
        }))
      : p(l.$slots, 'default', { key: 1 })
  },
}); const Hs = /* @__PURE__ */ x({
  __name: 'BInputGroupAppend',
  props: {
    isText: { default: !1 },
  },
  setup(e) {
    return (t, a) => (c(), O(Et, { 'is-text': e.isText }, {
      default: P(() => [
        p(t.$slots, 'default'),
      ]),
      _: 3,
    }, 8, ['is-text']))
  },
}); const Es = /* @__PURE__ */ x({
  __name: 'BInputGroupPrepend',
  props: {
    isText: { default: !1 },
  },
  setup(e) {
    return (t, a) => (c(), O(Et, { 'is-text': e.isText }, {
      default: P(() => [
        p(t.$slots, 'default'),
      ]),
      _: 3,
    }, 8, ['is-text']))
  },
}); const Ja = Symbol(); const Ds = /* @__PURE__ */ x({
  __name: 'BListGroup',
  props: {
    flush: { default: !1 },
    horizontal: { type: [Boolean, String], default: !1 },
    numbered: { default: !1 },
    tag: { default: 'div' },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'flush')); const l = r(o(t, 'numbered')); const n = u(() => {
      const f = a.value ? !1 : t.horizontal
      return {
        'list-group-flush': a.value,
        'list-group-horizontal': f === !0,
        [`list-group-horizontal-${f}`]: typeof f == 'string',
        'list-group-numbered': l.value,
      }
    }); const i = u(() => l.value === !0 ? 'ol' : t.tag)
    return Me(Ja, {
      numbered: l.value,
    }), (f, d) => (c(), O(J(s(i)), {
      class: F(['list-group', s(n)]),
    }, {
      default: P(() => [
        p(f.$slots, 'default'),
      ]),
      _: 3,
    }, 8, ['class']))
  },
}); const Rs = /* @__PURE__ */ x({
  __name: 'BListGroupItem',
  props: {
    action: { default: !1 },
    active: { default: !1 },
    button: { default: !1 },
    disabled: { default: !1 },
    href: null,
    tag: { default: 'div' },
    target: { default: '_self' },
    to: null,
    variant: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'action')); const l = r(o(t, 'active')); const n = r(o(t, 'button')); const i = r(o(t, 'disabled')); const f = na(); const d = Oe(Ja, null); const v = u(() => !n.value && (!!t.href || !!t.to)); const b = u(
      () => d != null && d.numbered ? 'li' : n.value ? 'button' : v.value ? Ce : t.tag,
    ); const g = u(() => {
      const h = a.value || v.value || n.value || ['a', 'router-link', 'button', 'b-link'].includes(t.tag)
      return {
        [`list-group-item-${t.variant}`]: t.variant !== void 0,
        'list-group-item-action': h,
        'active': l.value,
        'disabled': i.value,
      }
    }); const B = u(() => {
      const h = {}
      return n.value && ((!f || !f.type) && (h.type = 'button'), i.value && (h.disabled = !0)), h
    })
    return (h, k) => (c(), O(J(s(b)), W({
      'class': ['list-group-item', s(g)],
      'aria-current': s(l) ? !0 : null,
      'aria-disabled': s(i) ? !0 : null,
      'target': s(v) ? e.target : null,
      'href': s(n) ? null : e.href,
      'to': s(n) ? null : e.to,
    }, s(B)), {
      default: P(() => [
        p(h.$slots, 'default'),
      ]),
      _: 3,
    }, 16, ['class', 'aria-current', 'aria-disabled', 'target', 'href', 'to']))
  },
}); const qs = ['id']; const js = {
  inheritAttrs: !1,
}; const Ms = /* @__PURE__ */ x({
  ...js,
  __name: 'BModal',
  props: {
    bodyBgVariant: null,
    bodyClass: null,
    bodyTextVariant: null,
    busy: { default: !1 },
    lazy: { default: !1 },
    buttonSize: { default: 'md' },
    cancelDisabled: { default: !1 },
    cancelTitle: { default: 'Cancel' },
    cancelVariant: { default: 'secondary' },
    centered: { default: !1 },
    contentClass: null,
    dialogClass: null,
    footerBgVariant: null,
    footerBorderVariant: null,
    footerClass: null,
    footerTextVariant: null,
    fullscreen: { type: [Boolean, String], default: !1 },
    headerBgVariant: null,
    headerBorderVariant: null,
    headerClass: null,
    headerCloseLabel: { default: 'Close' },
    headerCloseWhite: { default: !1 },
    headerTextVariant: null,
    hideBackdrop: { default: !1 },
    hideFooter: { default: !1 },
    hideHeader: { default: !1 },
    hideHeaderClose: { default: !1 },
    id: null,
    modalClass: null,
    modelValue: { default: !1 },
    noCloseOnBackdrop: { default: !1 },
    noCloseOnEsc: { default: !1 },
    noFade: { default: !1 },
    noFocus: { default: !1 },
    okDisabled: { default: !1 },
    okOnly: { default: !1 },
    okTitle: { default: 'Ok' },
    okVariant: { default: 'primary' },
    scrollable: { default: !1 },
    show: { default: !1 },
    size: null,
    title: null,
    titleClass: null,
    titleSrOnly: { default: !1 },
    titleTag: { default: 'h5' },
  },
  emits: ['update:modelValue', 'show', 'shown', 'hide', 'hidden', 'hide-prevented', 'ok', 'cancel'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'busy')); const n = r(o(a, 'lazy')); const i = r(o(a, 'cancelDisabled')); const f = r(o(a, 'centered')); const d = r(o(a, 'headerCloseWhite')); const v = r(o(a, 'hideBackdrop')); const b = r(o(a, 'hideFooter')); const g = r(o(a, 'hideHeader')); const B = r(o(a, 'hideHeaderClose')); const h = r(o(a, 'modelValue')); const k = r(o(a, 'noCloseOnBackdrop')); const w = r(o(a, 'noCloseOnEsc')); const y = r(o(a, 'noFade')); const V = r(o(a, 'noFocus')); const $ = r(o(a, 'okDisabled')); const z = r(o(a, 'okOnly')); const L = r(o(a, 'scrollable')); const S = r(o(a, 'show')); const A = r(o(a, 'titleSrOnly')); const I = X(!1); const M = ye(o(a, 'id'), 'modal'); const _ = X(); const T = X(); const D = u(() => [
      {
        fade: !y.value,
        show: S.value,
      },
      a.modalClass,
    ]); const q = u(() => [
      {
        'modal-fullscreen': a.fullscreen === !0,
        [`modal-fullscreen-${a.fullscreen}-down`]: typeof a.fullscreen == 'string',
        [`modal-${a.size}`]: a.size !== void 0,
        'modal-dialog-centered': f.value,
        'modal-dialog-scrollable': L.value,
      },
      a.dialogClass,
    ]); const le = u(() => [
      {
        [`bg-${a.bodyBgVariant}`]: a.bodyBgVariant,
        [`text-${a.bodyTextVariant}`]: a.bodyTextVariant,
      },
      a.bodyClass,
    ]); const te = u(() => [
      {
        [`bg-${a.headerBgVariant}`]: a.headerBgVariant,
        [`border-${a.headerBorderVariant}`]: a.headerBorderVariant,
        [`text-${a.headerTextVariant}`]: a.headerTextVariant,
      },
      a.headerClass,
    ]); const K = u(() => [
      {
        [`bg-${a.footerBgVariant}`]: a.footerBgVariant,
        [`border-${a.footerBorderVariant}`]: a.footerBorderVariant,
        [`text-${a.footerTextVariant}`]: a.footerTextVariant,
      },
      a.footerClass,
    ]); const de = u(() => [
      {
        'visually-hidden': A.value,
      },
      a.titleClass,
    ]); const se = u(() => i.value || l.value); const Q = u(() => $.value || l.value)
    me(_, 'shown.bs.modal', G => Z(G)), me(_, 'hidden.bs.modal', G => Se(G)), me(_, 'show.bs.modal', G => ue(G)), me(_, 'hide.bs.modal', G => $e(G))
    const Z = (G) => {
      t('shown', G), n.value === !0 && (I.value = !0), h.value === !1 && t('update:modelValue', !0), G.target.focus()
    }; const Se = (G) => {
      t('hidden', G), n.value === !0 && (I.value = !1), h.value === !0 && t('update:modelValue', !1)
      const be = document.querySelector('.modal')
      be && be.focus()
    }; const ue = (G) => {
      t('show', G)
    }; const $e = (G) => {
      t('hide', G)
    }; const Ae = () => {
      h.value && t('update:modelValue', !0), ee().show()
    }; const U = () => {
      h.value && t('update:modelValue', !1), ee().hide()
    }; const ee = () => (T.value !== void 0 || (T.value = new hl(_.value, {
      backdrop: !1,
      keyboard: !w.value,
      focus: !V.value,
    })), T.value)
    return pe(() => {
      h.value && ee().show()
    }), st(() => {
      let G;
      (G = T.value) == null || G.dispose(), T.value = void 0
    }), re(
      () => a.noCloseOnBackdrop,
      (G) => {
        ee()._config.backdrop = a.hideBackdrop ? !1 : G ? 'static' : !a.hideBackdrop
      },
    ), re(
      () => a.noCloseOnEsc,
      (G) => {
        ee()._config.keyboard = !G
      },
    ), re(
      () => h.value,
      (G) => {
        ze(() => {
          G ? Ae() : U()
        })
      },
    ), (G, be) => (c(), O(vl, { to: 'body' }, [
      H('div', W({
        id: s(M),
        ref_key: 'element',
        ref: _,
        class: ['modal', s(D)],
        tabindex: '-1',
      }, G.$attrs), [
        H('div', {
          class: F(['modal-dialog', s(q)]),
        }, [
          !s(n) || s(n) && I.value || s(n) && s(h) === !0
            ? (c(), m('div', {
                key: 0,
                class: F(['modal-content', e.contentClass]),
              }, [
                s(g)
                  ? j('', !0)
                  : (c(), m('div', {
                      key: 0,
                      class: F(['modal-header', s(te)]),
                    }, [
                      p(G.$slots, 'header', {}, () => [
                        (c(), O(J(e.titleTag), {
                          class: F(['modal-title', s(de)]),
                        }, {
                          default: P(() => [
                            p(G.$slots, 'title', {}, () => [
                              Y(R(e.title), 1),
                            ], !0),
                          ]),
                          _: 3,
                        }, 8, ['class'])),
                        s(B)
                          ? j('', !0)
                          : (c(), m(ae, { key: 0 }, [
                              G.$slots['header-close']
                                ? (c(), m('button', {
                                    'key': 0,
                                    'type': 'button',
                                    'data-bs-dismiss': 'modal',
                                    'onClick': be[0] || (be[0] = xe => U()),
                                  }, [
                                    p(G.$slots, 'header-close', {}, void 0, !0),
                                  ]))
                                : (c(), O(Ge, {
                                    'key': 1,
                                    'type': 'button',
                                    'aria-label': e.headerCloseLabel,
                                    'data-bs-dismiss': 'modal',
                                    'white': s(d),
                                    'onClick': be[1] || (be[1] = xe => U()),
                                  }, null, 8, ['aria-label', 'white'])),
                            ], 64)),
                      ], !0),
                    ], 2)),
                H('div', {
                  class: F(['modal-body', s(le)]),
                }, [
                  p(G.$slots, 'default', {}, void 0, !0),
                ], 2),
                s(b)
                  ? j('', !0)
                  : (c(), m('div', {
                      key: 1,
                      class: F(['modal-footer', s(K)]),
                    }, [
                      p(G.$slots, 'footer', {}, () => [
                        s(z)
                          ? j('', !0)
                          : (c(), O(Ke, {
                              key: 0,
                              type: 'button',
                              class: 'btn',
                              disabled: s(se),
                              size: e.buttonSize,
                              variant: e.cancelVariant,
                              onClick: be[2] || (be[2] = xe => (U(), t('cancel'))),
                            }, {
                              default: P(() => [
                                Y(R(e.cancelTitle), 1),
                              ]),
                              _: 1,
                            }, 8, ['disabled', 'size', 'variant'])),
                        ce(Ke, {
                          type: 'button',
                          class: 'btn',
                          disabled: s(Q),
                          size: e.buttonSize,
                          variant: e.okVariant,
                          onClick: be[3] || (be[3] = xe => (U(), t('ok'))),
                        }, {
                          default: P(() => [
                            Y(R(e.okTitle), 1),
                          ]),
                          _: 1,
                        }, 8, ['disabled', 'size', 'variant']),
                      ], !0),
                    ], 2)),
              ], 2))
            : j('', !0),
        ], 2),
        s(v) === !1
          ? (c(), m('div', {
              key: 0,
              class: 'modal-backdrop fade show',
              onClick: be[4] || (be[4] = rt(xe => s(k) === !1 && U(), ['prevent'])),
            }))
          : j('', !0),
      ], 16, qs),
    ]))
  },
})
const Gs = /* @__PURE__ */ ke(Ms, [['__scopeId', 'data-v-38b66978']]); const Ws = /* @__PURE__ */ x({
  __name: 'BNav',
  props: {
    align: null,
    cardHeader: { default: !1 },
    fill: { default: !1 },
    justified: { default: !1 },
    pills: { default: !1 },
    small: { default: !1 },
    tabs: { default: !1 },
    tag: { default: 'ul' },
    vertical: { default: !1 },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'cardHeader')); const l = r(o(t, 'fill')); const n = r(o(t, 'justified')); const i = r(o(t, 'pills')); const f = r(o(t, 'small')); const d = r(o(t, 'tabs')); const v = r(o(t, 'vertical')); const b = u(() => ({
      'nav-tabs': d.value,
      'nav-pills': i.value && !d.value,
      'card-header-tabs': !v.value && a.value && d.value,
      'card-header-pills': !v.value && a.value && i.value && !d.value,
      'flex-column': v.value,
      'nav-fill': !v.value && l.value,
      'nav-justified': !v.value && n.value,
      [`justify-content-${t.align}`]: !v.value && t.align !== void 0,
      'small': f.value,
    }))
    return (g, B) => (c(), O(J(e.tag), {
      class: F(['nav', s(b)]),
    }, {
      default: P(() => [
        p(g.$slots, 'default'),
      ]),
      _: 3,
    }, 8, ['class']))
  },
}); const Us = /* @__PURE__ */ x({
  __name: 'BNavForm',
  props: {
    role: null,
    id: null,
    floating: { default: !1 },
    novalidate: { default: !1 },
    validated: { default: !1 },
  },
  emits: ['submit'],
  setup(e, { emit: t }) {
    const a = e; const l = i => t('submit', i); const n = u(() => ({
      floating: a.floating,
      role: a.role,
      id: a.id,
      novalidate: a.novalidate,
      validated: a.validated,
    }))
    return (i, f) => (c(), O(ja, W(s(n), {
      class: 'd-flex',
      onSubmit: rt(l, ['prevent']),
    }), {
      default: P(() => [
        p(i.$slots, 'default'),
      ]),
      _: 3,
    }, 16, ['onSubmit']))
  },
}); const Xs = x({
  components: { BLink: Ce },
  props: {
    ...it(De, ['event', 'routerTag']),
  },
  setup(e) {
    return { disabledBoolean: r(o(e, 'disabled')) }
  },
}); const Ks = { class: 'nav-item' }
function Js(e, t, a, l, n, i) {
  const f = la('b-link')
  return c(), m('li', Ks, [
    ce(f, W({ class: 'nav-link' }, e.$props, {
      'active-class': 'active',
      'tabindex': e.disabledBoolean ? -1 : void 0,
      'aria-disabled': e.disabledBoolean ? !0 : void 0,
    }), {
      default: P(() => [
        p(e.$slots, 'default'),
      ]),
      _: 3,
    }, 16, ['tabindex', 'aria-disabled']),
  ])
}
const Ys = /* @__PURE__ */ ke(Xs, [['render', Js]]); const Zs = { class: 'nav-item dropdown' }; const Qs = /* @__PURE__ */ x({
  __name: 'BNavItemDropdown',
  props: {
    id: null,
    text: null,
    toggleClass: null,
    size: null,
    offset: null,
    autoClose: { type: [Boolean, String], default: !0 },
    dark: { type: Boolean, default: !1 },
    dropleft: { type: Boolean, default: !1 },
    dropright: { type: Boolean, default: !1 },
    dropup: { type: Boolean, default: !1 },
    right: { type: Boolean, default: !1 },
    left: { type: [Boolean, String], default: !1 },
    split: { type: Boolean, default: !1 },
    splitVariant: null,
    noCaret: { type: Boolean, default: !1 },
    variant: { default: 'link' },
  },
  setup(e) {
    return (t, a) => (c(), m('li', Zs, [
      ce(qa, W(t.$props, { 'is-nav': '' }), oa({ _: 2 }, [
        ie(t.$slots, (l, n) => ({
          name: n,
          fn: P(i => [
            p(t.$slots, n, ge(Be(i || {}))),
          ]),
        })),
      ]), 1040),
    ]))
  },
}); const er = { class: 'navbar-text' }; const tr = /* @__PURE__ */ x({
  __name: 'BNavText',
  props: {
    text: null,
  },
  setup(e) {
    return (t, a) => (c(), m('li', er, [
      p(t.$slots, 'default', {}, () => [
        Y(R(e.text), 1),
      ]),
    ]))
  },
}); const ar = /* @__PURE__ */ x({
  __name: 'BNavbar',
  props: {
    fixed: null,
    print: { default: !1 },
    sticky: null,
    tag: { default: 'nav' },
    toggleable: { type: [Boolean, String], default: !1 },
    dark: { default: !1 },
    variant: null,
    container: { type: [String, Boolean], default: 'fluid' },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'print')); const l = r(o(t, 'dark')); const n = u(
      () => t.tag === 'nav' ? void 0 : 'navigation',
    ); const i = u(
      () => typeof t.toggleable == 'string' ? `navbar-expand-${t.toggleable}` : t.toggleable === !1 ? 'navbar-expand' : void 0,
    ); const f = u(
      () => t.container === !1 ? void 0 : t.container === !0 ? 'container' : `container-${t.container}`,
    ); const d = u(() => ({
      'd-print': a.value,
      [`sticky-${t.sticky}`]: t.sticky !== void 0,
      'navbar-dark': l.value,
      [`bg-${t.variant}`]: t.variant !== void 0,
      [`fixed-${t.fixed}`]: t.fixed !== void 0,
      [`${i.value}`]: i.value !== void 0,
    }))
    return (v, b) => (c(), O(J(e.tag), {
      class: F(['navbar', s(d)]),
      role: s(n),
    }, {
      default: P(() => [
        e.container !== !1
          ? (c(), m('div', {
              key: 0,
              class: F(s(f)),
            }, [
              p(v.$slots, 'default'),
            ], 2))
          : p(v.$slots, 'default', { key: 1 }),
      ]),
      _: 3,
    }, 8, ['class', 'role']))
  },
}); const Qt = it(De, ['event', 'routerTag']); const lr = x({
  components: {
    BLink: Ce,
  },
  props: {
    tag: { type: String, default: 'div' },
    ...Qt,
  },
  setup(e) {
    const t = u(() => Xe(e)); const a = u(() => t.value ? Ce : e.tag)
    return {
      props: t.value ? Ot(e, Qt) : {},
      computedTag: a,
    }
  },
})
function nr(e, t, a, l, n, i) {
  return c(), O(J(e.computedTag), W({ class: 'navbar-brand' }, e.props), {
    default: P(() => [
      p(e.$slots, 'default'),
    ]),
    _: 3,
  }, 16)
}
const or = /* @__PURE__ */ ke(lr, [['render', nr]]); const sr = /* @__PURE__ */ x({
  __name: 'BNavbarNav',
  props: {
    align: null,
    fill: { default: !1 },
    justified: { default: !1 },
    small: { default: !1 },
    tag: { default: 'ul' },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'fill')); const l = r(o(t, 'justified')); const n = r(o(t, 'small')); const i = u(() => ({
      'nav-fill': a.value,
      'nav-justified': l.value,
      [`justify-content-${t.align}`]: t.align !== void 0,
      'small': n.value,
    }))
    return (f, d) => (c(), m('ul', {
      class: F(['navbar-nav', s(i)]),
    }, [
      p(f.$slots, 'default'),
    ], 2))
  },
}); const rr = /* @__PURE__ */ H('span', { class: 'navbar-toggler-icon' }, null, -1); const ir = /* @__PURE__ */ x({
  __name: 'BNavbarToggle',
  props: {
    disabled: { default: !1 },
    label: { default: 'Toggle navigation' },
    target: null,
  },
  emits: ['click'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'disabled')); const n = u(() => ({
      'disabled': l.value,
      'aria-label': a.label,
    })); const i = u(() => ({
      disabled: l.value,
    })); const f = (d) => {
      l.value || t('click', d)
    }
    return (d, v) => Je((c(), m('button', W({
      class: ['navbar-toggler', s(i)],
      type: 'button',
    }, s(n), { onClick: f }), [
      p(d.$slots, 'default', {}, () => [
        rr,
      ]),
    ], 16)), [
      [s(Pt), s(l) ? void 0 : e.target],
    ])
  },
}); const ur = ['data-bs-backdrop', 'data-bs-scroll']; const dr = {
  key: 0,
  class: 'offcanvas-header',
}; const cr = {
  id: 'offcanvasLabel',
  class: 'offcanvas-title',
}; const fr = { class: 'offcanvas-body' }; const vr = { key: 1 }; const mr = /* @__PURE__ */ x({
  __name: 'BOffcanvas',
  props: {
    dismissLabel: { default: 'Close' },
    modelValue: { default: !1 },
    bodyScrolling: { default: !1 },
    backdrop: { default: !0 },
    placement: { default: 'start' },
    title: null,
    noHeaderClose: { default: !1 },
    noHeader: { default: !1 },
  },
  emits: ['update:modelValue', 'show', 'shown', 'hide', 'hidden'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'modelValue')); const n = r(o(a, 'bodyScrolling')); const i = r(o(a, 'backdrop')); const f = r(o(a, 'noHeaderClose')); const d = r(o(a, 'noHeader')); const v = X(); const b = X()
    me(v, 'shown.bs.offcanvas', () => t('shown')), me(v, 'hidden.bs.offcanvas', () => t('hidden'))
    const g = () => {
      t('show'), t('update:modelValue', !0)
    }; const B = () => {
      t('hide'), t('update:modelValue', !1)
    }
    me(v, 'show.bs.offcanvas', () => {
      g()
    }), me(v, 'hide.bs.offcanvas', () => {
      B()
    }), pe(() => {
      let k
      b.value = new yl(v.value), l.value && ((k = b.value) == null || k.show(v.value))
    })
    const h = u(() => ({
      [`offcanvas-${a.placement}`]: !!a.placement,
    }))
    return re(
      () => l.value,
      (k) => {
        let w, y
        k ? (w = b.value) == null || w.show(v.value) : (y = b.value) == null || y.hide()
      },
    ), (k, w) => (c(), m('div', {
      'ref_key': 'element',
      'ref': v,
      'class': F(['offcanvas', s(h)]),
      'tabindex': '-1',
      'aria-labelledby': 'offcanvasLabel',
      'data-bs-backdrop': s(i),
      'data-bs-scroll': s(n),
    }, [
      s(d)
        ? j('', !0)
        : (c(), m('div', dr, [
            p(k.$slots, 'header', ge(Be({ visible: e.modelValue, placement: e.placement, hide: B })), () => [
              H('h5', cr, [
                p(k.$slots, 'title', {}, () => [
                  Y(R(e.title), 1),
                ]),
              ]),
              s(f)
                ? j('', !0)
                : (c(), O(Ge, {
                    'key': 0,
                    'type': 'button',
                    'class': 'text-reset',
                    'data-bs-dismiss': 'offcanvas',
                    'aria-label': e.dismissLabel,
                  }, null, 8, ['aria-label'])),
            ]),
          ])),
      H('div', fr, [
        p(k.$slots, 'default'),
      ]),
      k.$slots.footer
        ? (c(), m('div', vr, [
            p(k.$slots, 'footer', ge(Be({ visible: e.modelValue, placement: e.placement, hide: B }))),
          ]))
        : j('', !0),
    ], 10, ur))
  },
}); const br = /* @__PURE__ */ x({
  __name: 'BOverlay',
  props: {
    bgColor: null,
    blur: { default: '2px' },
    fixed: { default: !1 },
    noCenter: { default: !1 },
    noFade: { default: !1 },
    noWrap: { default: !1 },
    opacity: { default: 0.85 },
    overlayTag: { default: 'div' },
    rounded: { type: [Boolean, String], default: !1 },
    show: { default: !1 },
    spinnerSmall: { default: !1 },
    spinnerType: { default: 'border' },
    spinnerVariant: null,
    variant: { default: 'light' },
    wrapTag: { default: 'div' },
    zIndex: { default: 10 },
  },
  emits: ['click', 'hidden', 'shown'],
  setup(e, { emit: t }) {
    const a = e; const l = { top: 0, left: 0, bottom: 0, right: 0 }; const n = r(o(a, 'fixed')); const i = r(o(a, 'noCenter')); const f = r(o(a, 'noFade')); const d = r(o(a, 'noWrap')); const v = r(o(a, 'show')); const b = r(o(a, 'spinnerSmall')); const g = u(
      () => a.rounded === !0 || a.rounded === '' ? 'rounded' : a.rounded === !1 ? '' : `rounded-${a.rounded}`,
    ); const B = u(
      () => a.variant && !a.bgColor ? `bg-${a.variant}` : '',
    ); const h = u(() => v.value ? 'true' : null); const k = u(() => ({
      type: a.spinnerType || void 0,
      variant: a.spinnerVariant || void 0,
      small: b.value,
    })); const w = u(() => ({
      ...l,
      zIndex: a.zIndex || 10,
    })); const y = u(() => [
      'b-overlay',
      {
        'position-absolute': !d.value || !n.value,
        'position-fixed': d.value && n.value,
      },
    ]); const V = u(() => [B.value, g.value]); const $ = u(() => ({
      ...l,
      opacity: a.opacity,
      backgroundColor: a.bgColor || void 0,
      backdropFilter: blur ? `blur(${blur})` : void 0,
    })); const z = u(
      () => i.value
        ? l
        : {
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          },
    )
    return (L, S) => (c(), O(J(e.wrapTag), {
      'class': 'b-overlay-wrap position-relative',
      'aria-busy': s(h),
    }, {
      default: P(() => [
        p(L.$slots, 'default'),
        ce(Lt, {
          'no-fade': s(f),
          'trans-props': { enterToClass: 'show' },
          'name': 'fade',
          'onOnAfterEnter': S[1] || (S[1] = A => t('shown')),
          'onOnAfterLeave': S[2] || (S[2] = A => t('hidden')),
        }, {
          default: P(() => [
            s(v)
              ? (c(), O(J(e.overlayTag), {
                  key: 0,
                  class: F(s(y)),
                  style: Ve(s(w)),
                  onClick: S[0] || (S[0] = A => t('click', A)),
                }, {
                  default: P(() => [
                    H('div', {
                      class: F(['position-absolute', s(V)]),
                      style: Ve(s($)),
                    }, null, 6),
                    H('div', {
                      class: 'position-absolute',
                      style: Ve(s(z)),
                    }, [
                      p(L.$slots, 'overlay', ge(Be(s(k))), () => [
                        ce(ft, ge(Be(s(k))), null, 16),
                      ]),
                    ], 4),
                  ]),
                  _: 3,
                }, 8, ['class', 'style']))
              : j('', !0),
          ]),
          _: 3,
        }, 8, ['no-fade']),
      ]),
      _: 3,
    }, 8, ['aria-busy']))
  },
}); const gr = 5; const Ya = 20; const Za = 0; const _e = 3; const pr = 'ellipsis-text'; const hr = 'first-text'; const yr = 'last-text'; const Br = 'next-text'; const $r = 'page'; const kr = 'prev-text'; const ea = e => Math.max(je(e) || Ya, 1); const ta = e => Math.max(je(e) || Za, 0); const Sr = (e, t) => {
  const a = je(e) || 1
  return a > t ? t : a < 1 ? 1 : a
}; const Cr = x({
  name: 'BPagination',
  props: {
    align: { type: String, default: 'start' },
    ariaControls: { type: String, required: !1 },
    ariaLabel: { type: String, default: 'Pagination' },
    disabled: { type: [Boolean, String], default: !1 },
    ellipsisClass: { type: [Array, String], default: () => [] },
    ellipsisText: { type: String, default: '\u2026' },
    firstClass: { type: [Array, String], default: () => [] },
    firstNumber: { type: [Boolean, String], default: !1 },
    firstText: { type: String, default: '\xAB' },
    hideEllipsis: { type: [Boolean, String], default: !1 },
    hideGotoEndButtons: { type: [Boolean, String], default: !1 },
    labelFirstPage: { type: String, default: 'Go to first page' },
    labelLastPage: { type: String, default: 'Go to last page' },
    labelNextPage: { type: String, default: 'Go to next page' },
    labelPage: { type: String, default: 'Go to page' },
    labelPrevPage: { type: String, default: 'Go to previous page' },
    lastClass: { type: [Array, String], default: () => [] },
    lastNumber: { type: [Boolean, String], default: !1 },
    lastText: { type: String, default: '\xBB' },
    limit: { type: Number, default: gr },
    modelValue: { type: Number, default: 1 },
    nextClass: { type: [Array, String], default: () => [] },
    nextText: { type: String, default: '\u203A' },
    pageClass: { type: [Array, String], default: () => [] },
    perPage: { type: Number, default: Ya },
    pills: { type: [Boolean, String], default: !1 },
    prevClass: { type: [Array, String], default: () => [] },
    prevText: { type: String, default: '\u2039' },
    size: { type: String, required: !1 },
    totalRows: { type: Number, default: Za },
  },
  emits: ['update:modelValue', 'page-click'],
  setup(e, { emit: t, slots: a }) {
    const l = r(o(e, 'disabled')); const n = r(o(e, 'firstNumber')); const i = r(o(e, 'hideEllipsis')); const f = r(o(e, 'hideGotoEndButtons')); const d = r(o(e, 'lastNumber')); const v = r(o(e, 'pills')); const b = Bl(o(e, 'align')); const g = u(
      () => Math.ceil(ta(e.totalRows) / ea(e.perPage)),
    ); const B = u(() => {
      let S
      return g.value - e.modelValue + 2 < e.limit && e.limit > _e ? S = g.value - k.value + 1 : S = e.modelValue - Math.floor(k.value / 2), S < 1 ? S = 1 : S > g.value - k.value && (S = g.value - k.value + 1), e.limit <= _e && d.value && g.value === S + k.value - 1 && (S = Math.max(S - 1, 1)), S
    }); const h = u(() => {
      const S = g.value - e.modelValue
      let A = !1
      return S + 2 < e.limit && e.limit > _e ? e.limit > _e && (A = !0) : e.limit > _e && (A = !!(!i.value || n.value)), B.value <= 1 && (A = !1), A && n.value && B.value < 4 && (A = !1), A
    }); const k = u(() => {
      let S = e.limit
      return g.value <= e.limit ? S = g.value : e.modelValue < e.limit - 1 && e.limit > _e ? ((!i.value || d.value) && (S = e.limit - (n.value ? 0 : 1)), S = Math.min(S, e.limit)) : g.value - e.modelValue + 2 < e.limit && e.limit > _e ? (!i.value || n.value) && (S = e.limit - (d.value ? 0 : 1)) : e.limit > _e && (S = e.limit - (i.value ? 0 : 2)), S
    }); const w = u(() => {
      const S = g.value - k.value
      let A = !1
      e.modelValue < e.limit - 1 && e.limit > _e ? (!i.value || d.value) && (A = !0) : e.limit > _e && (A = !!(!i.value || d.value)), B.value > S && (A = !1)
      const I = B.value + k.value - 1
      return A && d.value && I > g.value - 3 && (A = !1), A
    }); const y = Fe({
      pageSize: ea(e.perPage),
      totalRows: ta(e.totalRows),
      numberOfPages: g.value,
    }); const V = (S, A) => {
      if (A === e.modelValue)
        return
      const { target: I } = S; const M = new ut('page-click', {
        cancelable: !0,
        vueTarget: this,
        target: I,
      })
      t('page-click', M, A), !M.defaultPrevented && t('update:modelValue', A)
    }; const $ = u(() => e.size ? `pagination-${e.size}` : ''); const z = u(() => v.value ? 'b-pagination-pills' : '')
    re(
      () => e.modelValue,
      (S) => {
        const A = Sr(S, g.value)
        A !== e.modelValue && t('update:modelValue', A)
      },
    ), re(y, (S, A) => {
      S != null && (A.pageSize !== S.pageSize && A.totalRows === S.totalRows || A.numberOfPages !== S.numberOfPages && e.modelValue > A.numberOfPages) && t('update:modelValue', 1)
    })
    const L = u(() => {
      const S = []
      for (let A = 0; A < k.value; A++)
        S.push({ number: B.value + A, classes: null })
      return S
    })
    return () => {
      const S = []; const A = L.value.map(K => K.number); const I = K => K === e.modelValue; const M = e.modelValue < 1; const _ = e.align === 'fill'; const T = (K, de, se, Q, Z, Se) => {
        const ue = l.value || I(Se) || M || K < 1 || K > g.value; const $e = K < 1 ? 1 : K > g.value ? g.value : K; const Ae = { disabled: ue, page: $e, index: $e - 1 }; const U = Te(se, Ae, a) || Q || ''
        return oe(
          'li',
          {
            class: [
              'page-item',
              {
                'disabled': ue,
                'flex-fill': _,
                'd-flex': _ && !ue,
              },
              Z,
            ],
          },
          oe(
            ue ? 'span' : 'button',
            {
              'class': ['page-link', { 'flex-grow-1': !ue && _ }],
              'aria-label': de,
              'aria-controls': e.ariaControls || null,
              'aria-disabled': ue ? 'true' : null,
              'role': 'menuitem',
              'type': ue ? null : 'button',
              'tabindex': ue ? null : '-1',
              'onClick': (ee) => {
                ue || V(ee, $e)
              },
            },
            U,
          ),
        )
      }; const D = K => oe(
        'li',
        {
          class: [
            'page-item',
            'disabled',
            'bv-d-xs-down-none',
            _ ? 'flex-fill' : '',
            e.ellipsisClass,
          ],
          role: 'separator',
          key: `ellipsis-${K ? 'last' : 'first'}`,
        },
        [
          oe(
            'span',
            { class: ['page-link'] },
            Te(pr, {}, a) || e.ellipsisText || '...',
          ),
        ],
      ); const q = (K, de) => {
        const se = I(K.number) && !M; const Q = l.value ? null : se || M && de === 0 ? '0' : '-1'; const Z = {
          active: se,
          disabled: l.value,
          page: K.number,
          index: K.number - 1,
          content: K.number,
        }; const Se = Te($r, Z, a) || K.number; const ue = oe(
          l.value ? 'span' : 'button',
          {
            'class': ['page-link', { 'flex-grow-1': !l.value && _ }],
            'aria-controls': e.ariaControls || null,
            'aria-disabled': l.value ? 'true' : null,
            'aria-label': e.labelPage ? `${e.labelPage} ${K.number}` : null,
            'role': 'menuitemradio',
            'type': l.value ? null : 'button',
            'tabindex': Q,
            'onClick': ($e) => {
              l.value || V($e, K.number)
            },
          },
          Se,
        )
        return oe(
          'li',
          {
            class: [
              'page-item',
              {
                'disabled': l.value,
                'active': se,
                'flex-fill': _,
                'd-flex': _ && !l.value,
              },
              e.pageClass,
            ],
            role: 'presentation',
            key: `page-${K.number}`,
          },
          ue,
        )
      }
      if (!f.value && !n.value) {
        const K = T(
          1,
          e.labelFirstPage,
          hr,
          e.firstText,
          e.firstClass,
          1,
        )
        S.push(K)
      }
      const le = T(
        e.modelValue - 1,
        e.labelFirstPage,
        kr,
        e.prevText,
        e.prevClass,
        1,
      )
      S.push(le), n.value && A[0] !== 1 && S.push(q({ number: 1 }, 0)), h.value && S.push(D(!1)), L.value.forEach((K, de) => {
        const se = h.value && n.value && A[0] !== 1 ? 1 : 0
        S.push(q(K, de + se))
      }), w.value && S.push(D(!0)), d.value && A[A.length - 1] !== g.value && S.push(q({ number: g.value }, -1))
      const te = T(
        e.modelValue + 1,
        e.labelNextPage,
        Br,
        e.nextText,
        e.nextClass,
        g.value,
      )
      if (S.push(te), !d.value && !f.value) {
        const K = T(
          g.value,
          e.labelLastPage,
          yr,
          e.lastText,
          e.lastClass,
          g.value,
        )
        S.push(K)
      }
      return oe(
        'ul',
        {
          'class': ['pagination', $.value, b.value, z.value],
          'role': 'menubar',
          'aria-disabled': l.value,
          'aria-label': e.ariaLabel || null,
        },
        S,
      )
    }
  },
}); const we = /* @__PURE__ */ x({
  __name: 'BPlaceholder',
  props: {
    tag: { default: 'span' },
    width: null,
    cols: null,
    variant: null,
    size: null,
    animation: null,
  },
  setup(e) {
    const t = e; const a = u(
      () => t.width === void 0 ? void 0 : typeof t.width == 'number' ? t.width.toString() : t.width.includes('%') ? t.width.replaceAll('%', '') : t.width,
    ); const l = u(
      () => t.cols === void 0 ? void 0 : typeof t.cols == 'number' ? t.cols.toString() : t.cols,
    ); const n = u(() => ({
      [`col-${l.value}`]: l.value !== void 0 && a.value === void 0,
      [`bg-${t.variant}`]: t.variant !== void 0,
      [`placeholder-${t.size}`]: t.size !== void 0,
      [`placeholder-${t.animation}`]: t.animation !== void 0,
    })); const i = u(
      () => a.value === void 0 ? void 0 : `width: ${a.value}%;`,
    )
    return (f, d) => (c(), O(J(e.tag), {
      class: F(['placeholder', s(n)]),
      style: Ve(s(i)),
    }, null, 8, ['class', 'style']))
  },
}); const Qa = /* @__PURE__ */ x({
  __name: 'BPlaceholderButton',
  props: {
    tag: { default: 'div' },
    width: null,
    cols: null,
    variant: { default: 'primary' },
    animation: null,
  },
  setup(e) {
    const t = e; const a = u(() => ['btn', `btn-${t.variant}`, 'disabled']); const l = u(() => ({
      animation: t.animation,
      width: t.width,
      cols: t.cols,
      tag: t.tag,
    }))
    return (n, i) => (c(), O(we, W({ class: s(a) }, s(l)), null, 16, ['class']))
  },
}); const wr = /* @__PURE__ */ x({
  __name: 'BPlaceholderCard',
  props: {
    noHeader: { default: !1 },
    headerWidth: { default: 100 },
    headerVariant: null,
    headerAnimation: null,
    headerSize: null,
    noFooter: { default: !1 },
    footerWidth: { default: 100 },
    footerVariant: null,
    footerAnimation: null,
    footerSize: null,
    animation: null,
    size: null,
    variant: null,
    noButton: { default: !1 },
    imgBottom: { default: !1 },
    imgSrc: null,
    imgBlankColor: { default: '#868e96' },
    imgHeight: { default: 100 },
    noImg: { default: !1 },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'noButton')); const l = r(o(t, 'noHeader')); const n = r(o(t, 'noFooter')); const i = r(o(t, 'noImg')); const f = r(o(t, 'imgBottom')); const d = u(() => ({
      width: t.headerWidth,
      variant: t.headerVariant,
      animation: t.headerAnimation,
      size: t.headerSize,
    })); const v = u(() => ({
      width: t.footerWidth,
      animation: t.footerAnimation,
      size: a.value ? t.footerSize : void 0,
      variant: t.footerVariant,
    })); const b = u(() => ({
      blank: !t.imgSrc,
      blankColor: t.imgBlankColor,
      height: t.imgSrc ? void 0 : t.imgHeight,
      src: t.imgSrc,
      top: !f.value,
      bottom: f.value,
    }))
    return (g, B) => (c(), O(La, { 'img-bottom': s(f) }, oa({
      default: P(() => [
        p(g.$slots, 'default', {}, () => [
          ce(we, { cols: '7' }),
          ce(we, { cols: '4' }),
          ce(we, { cols: '4' }),
          ce(we, { cols: '6' }),
          ce(we, { cols: '8' }),
        ]),
      ]),
      _: 2,
    }, [
      s(i)
        ? void 0
        : {
            name: 'img',
            fn: P(() => [
              p(g.$slots, 'img', {}, () => [
                ce(ot, ge(Be(s(b))), null, 16),
              ]),
            ]),
            key: '0',
          },
      s(l)
        ? void 0
        : {
            name: 'header',
            fn: P(() => [
              p(g.$slots, 'header', {}, () => [
                ce(we, ge(Be(s(d))), null, 16),
              ]),
            ]),
            key: '1',
          },
      s(n)
        ? void 0
        : {
            name: 'footer',
            fn: P(() => [
              p(g.$slots, 'footer', {}, () => [
                s(a) ? (c(), O(we, ge(W({ key: 1 }, s(v))), null, 16)) : (c(), O(Qa, ge(W({ key: 0 }, s(v))), null, 16)),
              ]),
            ]),
            key: '2',
          },
    ]), 1032, ['img-bottom']))
  },
}); const vt = /* @__PURE__ */ x({
  __name: 'BTableSimple',
  props: {
    bordered: { default: !1 },
    borderless: { default: !1 },
    borderVariant: null,
    captionTop: { default: !1 },
    dark: { default: !1 },
    hover: { default: !1 },
    responsive: { type: [Boolean, String], default: !1 },
    stacked: { type: [Boolean, String], default: !1 },
    striped: { default: !1 },
    small: { default: !1 },
    tableClass: null,
    tableVariant: null,
    stickyHeader: { default: !1 },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'captionTop')); const l = r(o(t, 'borderless')); const n = r(o(t, 'bordered')); const i = r(o(t, 'dark')); const f = r(o(t, 'hover')); const d = r(o(t, 'small')); const v = r(o(t, 'striped')); const b = r(o(t, 'stickyHeader')); const g = u(() => [
      'table',
      'b-table',
      {
        'table-bordered': n.value,
        'table-borderless': l.value,
        [`border-${t.borderVariant}`]: t.borderVariant !== void 0,
        'caption-top': a.value,
        'table-dark': i.value,
        'table-hover': f.value,
        'b-table-stacked': typeof t.stacked == 'boolean' && t.stacked,
        [`b-table-stacked-${t.stacked}`]: typeof t.stacked == 'string',
        'table-striped': v.value,
        'table-sm': d.value,
        [`table-${t.tableVariant}`]: t.tableVariant !== void 0,
      },
      t.tableClass,
    ]); const B = u(() => [
      {
        'table-responsive': t.responsive === !0,
        [`table-responsive-${t.responsive}`]: typeof t.responsive == 'string',
        'b-table-sticky-header': b.value,
      },
    ])
    return (h, k) => e.responsive
      ? (c(), m('div', {
          key: 1,
          class: F(s(B)),
        }, [
          H('table', {
            role: 'table',
            class: F(s(g)),
          }, [
            p(h.$slots, 'default'),
          ], 2),
        ], 2))
      : (c(), m('table', {
          key: 0,
          role: 'table',
          class: F(s(g)),
        }, [
          p(h.$slots, 'default'),
        ], 2))
  },
}); const Tr = /* @__PURE__ */ x({
  __name: 'BPlaceholderTable',
  props: {
    rows: { default: 3 },
    columns: { default: 5 },
    cellWidth: { default: 100 },
    size: null,
    animation: null,
    variant: null,
    headerColumns: null,
    hideHeader: { default: !1 },
    headerCellWidth: { default: 100 },
    headerSize: null,
    headerAnimation: null,
    headerVariant: null,
    footerColumns: null,
    showFooter: { default: !1 },
    footerCellWidth: { default: 100 },
    footerSize: null,
    footerAnimation: null,
    footerVariant: null,
  },
  setup(e) {
    const t = e; const a = u(
      () => typeof t.columns == 'string' ? We(t.columns, 5) : t.columns,
    ); const l = u(
      () => typeof t.rows == 'string' ? We(t.rows, 3) : t.rows,
    ); const n = u(
      () => t.headerColumns === void 0 ? a.value : typeof t.headerColumns == 'string' ? We(t.headerColumns, a.value) : t.headerColumns,
    ); const i = u(
      () => t.footerColumns === void 0 ? a.value : typeof t.footerColumns == 'string' ? We(t.footerColumns, a.value) : t.footerColumns,
    ); const f = u(() => ({
      size: t.size,
      variant: t.variant,
      animation: t.animation,
      width: t.cellWidth,
    })); const d = u(() => ({
      size: t.headerSize,
      variant: t.headerVariant,
      animation: t.headerAnimation,
      width: t.headerCellWidth,
    })); const v = u(() => ({
      size: t.footerSize,
      variant: t.footerVariant,
      animation: t.footerAnimation,
      width: t.footerCellWidth,
    })); const b = r(o(t, 'hideHeader')); const g = r(o(t, 'showFooter'))
    return (B, h) => (c(), O(vt, null, {
      default: P(() => [
        s(b)
          ? j('', !0)
          : p(B.$slots, 'thead', { key: 0 }, () => [
            H('thead', null, [
              H('tr', null, [
                (c(!0), m(ae, null, ie(s(n), (k, w) => (c(), m('th', { key: w }, [
                  ce(we, ge(Be(s(d))), null, 16),
                ]))), 128)),
              ]),
            ]),
          ]),
        p(B.$slots, 'default', {}, () => [
          H('tbody', null, [
            (c(!0), m(ae, null, ie(s(l), (k, w) => (c(), m('tr', { key: w }, [
              (c(!0), m(ae, null, ie(s(a), (y, V) => (c(), m('td', { key: V }, [
                ce(we, ge(Be(s(f))), null, 16),
              ]))), 128)),
            ]))), 128)),
          ]),
        ]),
        s(g)
          ? p(B.$slots, 'tfoot', { key: 1 }, () => [
            H('tfoot', null, [
              H('tr', null, [
                (c(!0), m(ae, null, ie(s(i), (k, w) => (c(), m('th', { key: w }, [
                  ce(we, ge(Be(s(v))), null, 16),
                ]))), 128)),
              ]),
            ]),
          ])
          : j('', !0),
      ]),
      _: 3,
    }))
  },
}); const Vr = /* @__PURE__ */ x({
  __name: 'BPlaceholderWrapper',
  props: {
    loading: { default: !1 },
  },
  setup(e) {
    const a = r(o(e, 'loading'))
    return (l, n) => s(a) ? p(l.$slots, 'loading', { key: 0 }) : p(l.$slots, 'default', { key: 1 })
  },
}); const _r = x({
  props: {
    container: {
      type: [String, Object],
      default: 'body',
    },
    content: { type: String },
    id: { type: String },
    customClass: { type: String, default: '' },
    noninteractive: { type: [Boolean, String], default: !1 },
    placement: { type: String, default: 'right' },
    target: {
      type: [String, Object],
      default: void 0,
    },
    title: { type: String },
    delay: { type: [Number, Object], default: 0 },
    triggers: { type: String, default: 'click' },
    show: { type: [Boolean, String], default: !1 },
    variant: { type: String, default: void 0 },
    html: { type: [Boolean, String], default: !0 },
    sanitize: { type: [Boolean, String], default: !1 },
    offset: { type: String, default: '0' },
  },
  emits: ['show', 'shown', 'hide', 'hidden', 'inserted'],
  setup(e, { emit: t, slots: a }) {
    r(o(e, 'noninteractive'))
    const l = r(o(e, 'show')); const n = r(o(e, 'html')); const i = r(o(e, 'sanitize')); const f = X(); const d = X(); const v = X(); const b = X(); const g = X(); const B = u(() => ({
      [`b-popover-${e.variant}`]: e.variant !== void 0,
    })); const h = (y) => {
      if (typeof y == 'string')
        return y
      if (y instanceof HTMLElement)
        return y
      if (typeof y < 'u')
        return y.$el
    }; const k = (y) => {
      if (y) {
        if (typeof y == 'string') {
          const V = document.getElementById(y)
          return V || void 0
        }
        return y
      }
    }; const w = (y) => {
      d.value = k(h(y)), d.value && (v.value = new kt(d.value, {
        customClass: e.customClass,
        container: h(e.container),
        trigger: e.triggers,
        placement: e.placement,
        title: e.title || a.title ? b.value : '',
        content: g.value,
        html: n.value,
        delay: e.delay,
        sanitize: i.value,
        offset: e.offset,
      }))
    }
    return pe(() => {
      let y, V, $
      ze(() => {
        w(e.target)
      }), (V = (y = f.value) == null ? void 0 : y.parentNode) == null || V.removeChild(f.value), l.value && (($ = v.value) == null || $.show())
    }), st(() => {
      let y;
      (y = v.value) == null || y.dispose()
    }), re(
      () => e.target,
      (y) => {
        let V;
        (V = v.value) == null || V.dispose(), w(y)
      },
    ), re(
      () => l.value,
      (y, V) => {
        let $, z
        y !== V && (y ? ($ = v.value) == null || $.show() : (z = v.value) == null || z.hide())
      },
    ), me(d, 'show.bs.popover', () => t('show')), me(d, 'shown.bs.popover', () => t('shown')), me(d, 'hide.bs.popover', () => t('hide')), me(d, 'hidden.bs.popover', () => t('hidden')), me(d, 'inserted.bs.popover', () => t('inserted')), {
      element: f,
      titleRef: b,
      contentRef: g,
      classes: B,
    }
  },
}); const Ar = ['id']; const xr = { ref: 'titleRef' }; const Ir = { ref: 'contentRef' }
function Fr(e, t, a, l, n, i) {
  return c(), m('div', {
    id: e.id,
    ref: 'element',
    class: F(['popover b-popover', e.classes]),
    role: 'tooltip',
    tabindex: '-1',
  }, [
    H('div', xr, [
      p(e.$slots, 'title', {}, () => [
        Y(R(e.title), 1),
      ]),
    ], 512),
    H('div', Ir, [
      p(e.$slots, 'default', {}, () => [
        Y(R(e.content), 1),
      ]),
    ], 512),
  ], 10, Ar)
}
const Or = /* @__PURE__ */ ke(_r, [['render', Fr]]); const zr = ['aria-valuenow', 'aria-valuemax']; const el = /* @__PURE__ */ x({
  __name: 'BProgressBar',
  props: {
    animated: { default: !1 },
    label: null,
    labelHtml: null,
    max: null,
    precision: { default: 0 },
    showProgress: { default: !1 },
    showValue: { default: !1 },
    striped: { default: !1 },
    value: { default: 0 },
    variant: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'animated')); const l = r(o(t, 'showProgress')); const n = r(o(t, 'showValue')); const i = r(o(t, 'striped')); const f = Oe(tl); const d = u(() => ({
      'progress-bar-animated': a.value || (f == null ? void 0 : f.animated),
      'progress-bar-striped': i.value || (f == null ? void 0 : f.striped) || a.value || (f == null ? void 0 : f.animated),
      [`bg-${t.variant}`]: t.variant !== void 0,
    })); const v = u(
      () => typeof t.precision == 'number' ? t.precision : Number.parseFloat(t.precision),
    ); const b = u(
      () => typeof t.value == 'number' ? t.value : Number.parseFloat(t.value),
    ); const g = u(
      () => typeof t.max == 'number' ? t.max : t.max === void 0 ? void 0 : Number.parseFloat(t.max),
    ); const B = u(
      () => t.labelHtml !== void 0 ? t.labelHtml : n.value || (f == null ? void 0 : f.showValue) ? b.value.toFixed(v.value) : l.value || (f == null ? void 0 : f.showProgress) ? (b.value * 100 / (g.value || 100)).toFixed(v.value) : t.label !== void 0 ? t.label : '',
    ); const h = u(
      () => f != null && f.max ? `${b.value * 100 / (typeof f.max == 'number' ? f.max : Number.parseInt(f.max))}%` : t.max ? `${b.value * 100 / (typeof t.max == 'number' ? t.max : Number.parseInt(t.max))}%` : typeof t.value == 'string' ? t.value : `${t.value}%`,
    )
    return (k, w) => (c(), m('div', {
      'class': F(['progress-bar', s(d)]),
      'role': 'progressbar',
      'aria-valuenow': e.value,
      'aria-valuemin': '0',
      'aria-valuemax': e.max,
      'style': Ve({ width: s(h) }),
    }, [
      p(k.$slots, 'default', {}, () => [
        Y(R(s(B)), 1),
      ]),
    ], 14, zr))
  },
}); const tl = Symbol(); const Pr = /* @__PURE__ */ x({
  __name: 'BProgress',
  props: {
    variant: null,
    max: null,
    height: null,
    animated: { default: !1 },
    precision: { default: 0 },
    showProgress: { default: !1 },
    showValue: { default: !1 },
    striped: { default: !1 },
    value: { default: 0 },
  },
  setup(e) {
    const t = e; const a = r(o(t, 'animated')); const l = r(o(t, 'showProgress')); const n = r(o(t, 'showValue')); const i = r(o(t, 'striped')); const f = u(() => ({
      animated: a.value,
      max: t.max,
      precision: t.precision,
      showProgress: l.value,
      showValue: n.value,
      striped: i.value,
      value: t.value,
      variant: t.variant,
    }))
    return Me(tl, {
      animated: a.value,
      max: t.max,
      showProgress: l.value,
      showValue: n.value,
      striped: i.value,
    }), (d, v) => (c(), m('div', {
      class: 'progress',
      style: Ve({ height: e.height }),
    }, [
      p(d.$slots, 'default', {}, () => [
        ce(el, ge(Be(s(f))), null, 16),
      ]),
    ], 4))
  },
}); const aa = dt('cols', [''], { type: [String, Number], default: null }); const Nr = x({
  name: 'BRow',
  props: {
    tag: { type: String, default: 'div' },
    gutterX: { type: String, default: null },
    gutterY: { type: String, default: null },
    noGutters: { type: [Boolean, String], default: !1 },
    alignV: { type: String, default: null },
    alignH: { type: String, default: null },
    alignContent: { type: String, default: null },
    ...aa,
  },
  setup(e) {
    const t = r(o(e, 'noGutters')); const a = va(e, aa, 'cols', 'row-cols')
    return {
      classes: u(() => ({
        [`gx-${e.gutterX}`]: e.gutterX !== null,
        [`gy-${e.gutterY}`]: e.gutterY !== null,
        'g-0': t.value,
        [`align-items-${e.alignV}`]: e.alignV !== null,
        [`justify-content-${e.alignH}`]: e.alignH !== null,
        [`align-content-${e.alignContent}`]: e.alignContent !== null,
      })),
      rowColsClasses: a,
    }
  },
})
function Lr(e, t, a, l, n, i) {
  return c(), O(J(e.tag), {
    class: F(['row', [e.classes, e.rowColsClasses]]),
  }, {
    default: P(() => [
      p(e.$slots, 'default'),
    ]),
    _: 3,
  }, 8, ['class'])
}
const Hr = /* @__PURE__ */ ke(Nr, [['render', Lr]]); const at = /* @__PURE__ */ x({
  __name: 'BSkeleton',
  props: {
    height: null,
    width: null,
    size: null,
    animation: { default: 'wave' },
    type: { default: 'text' },
    variant: null,
  },
  setup(e) {
    const t = e; const a = u(() => [
      `b-skeleton-${t.type}`,
      {
        [`b-skeleton-animate-${t.animation}`]: typeof t.animation == 'boolean' ? !1 : t.animation,
        [`bg-${t.variant}`]: t.variant !== void 0,
      },
    ]); const l = u(() => ({
      width: t.size || t.width,
      height: t.size || t.height,
    }))
    return (n, i) => (c(), m('div', {
      class: F(['b-skeleton', s(a)]),
      style: Ve(s(l)),
    }, null, 6))
  },
}); const Er = /* @__PURE__ */ x({
  __name: 'BSkeletonIcon',
  props: {
    animation: { default: 'wave' },
  },
  setup(e) {
    const t = e; const a = u(() => [`b-skeleton-animate-${t.animation}`])
    return (l, n) => (c(), m('div', {
      class: F(['b-skeleton-icon-wrapper position-relative d-inline-block overflow-hidden', s(a)]),
    }, [
      p(l.$slots, 'default'),
    ], 2))
  },
}); const Dr = { key: 0 }; const Rr = { key: 1 }; const qr = /* @__PURE__ */ x({
  __name: 'BSkeletonTable',
  props: {
    animation: { default: 'wave' },
    columns: { default: 5 },
    hideHeader: { default: !1 },
    rows: { default: 3 },
    showFooter: { default: !1 },
    tableProps: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'hideHeader')); const l = r(o(t, 'showFooter'))
    return (n, i) => (c(), O(vt, ge(Be(e.tableProps)), {
      default: P(() => [
        s(a)
          ? j('', !0)
          : (c(), m('thead', Dr, [
              H('tr', null, [
                (c(!0), m(ae, null, ie(e.columns, (f, d) => (c(), m('th', { key: d }, [
                  ce(at),
                ]))), 128)),
              ]),
            ])),
        H('tbody', null, [
          (c(!0), m(ae, null, ie(e.rows, (f, d) => (c(), m('tr', { key: d }, [
            (c(!0), m(ae, null, ie(e.columns, (v, b) => (c(), m('td', { key: b }, [
              ce(at, { width: '75%' }),
            ]))), 128)),
          ]))), 128)),
        ]),
        s(l)
          ? (c(), m('tfoot', Rr, [
              H('tr', null, [
                (c(!0), m(ae, null, ie(e.columns, (f, d) => (c(), m('th', { key: d }, [
                  ce(at),
                ]))), 128)),
              ]),
            ]))
          : j('', !0),
      ]),
      _: 1,
    }, 16))
  },
}); const jr = /* @__PURE__ */ x({
  __name: 'BSkeletonWrapper',
  props: {
    loading: { default: !1 },
  },
  setup(e) {
    const a = r(o(e, 'loading'))
    return (l, n) => s(a) ? p(l.$slots, 'loading', { key: 0 }) : p(l.$slots, 'default', { key: 1 })
  },
}); const Mr = () => {
  const e = (d, v) => {
    const b = []
    return !(d != null && d.length) && (v == null ? void 0 : v.length)
      ? (Object.keys(v[0]).forEach(g => b.push({ key: g, label: Dt(g) })), b)
      : (Array.isArray(d) && d.forEach((g) => {
          typeof g == 'string' ? b.push({ key: g, label: Dt(g) }) : $l(g) && g.key && typeof g.key == 'string' && b.push({ ...g })
        }), b)
  }; const t = X([]); const a = (d, v, b, g) => {
    if (t.value = lt(v), 'isFilterableTable' in g && g.isFilterableTable.value === !0 && b.filter && (t.value = i(t.value, b.filter, b.filterable), l.value && l.value(t.value)), 'isSortable' in g && g.isSortable.value === !0 && (t.value = n(
      d,
      t.value,
      {
        key: b.sortBy,
        desc: g.sortDescBoolean.value,
      },
      b.sortCompare,
    )), b.perPage !== void 0) {
      const B = (b.currentPage - 1) * b.perPage
      t.value = t.value.splice(B, b.perPage)
    }
    return t.value
  }; const l = X(void 0); const n = (d, v, b, g) => {
    if (!b || !b.key)
      return v
    const B = b.key
    return v.sort((h, k) => {
      if (g !== void 0)
        return g(h, k, b.key, b.desc)
      const w = $ => typeof $ == 'object' ? JSON.stringify($) : $
      return w(h[B]) > w(k[B]) ? b.desc ? -1 : 1 : w(k[B]) > w(h[B]) ? b.desc ? 1 : -1 : 0
    })
  }; const i = (d, v, b) => d.filter(
    g => Object.entries(g).filter((B) => {
      const [h, k] = B
      return h[0] === '_' || b.length > 0 && !b.includes(h) ? !1 : (typeof k == 'object' ? JSON.stringify(Object.values(k)) : typeof k == 'string' ? k : k.toString()).toLowerCase().includes(v.toLowerCase())
    }).length > 0,
  )
  return {
    normaliseFields: e,
    mapItems: a,
    internalItems: t,
    updateInternalItems: async (d) => {
      try {
        return t.value = await St(d), t.value
      }
      catch {

      }
    },
    filterEvent: l,
  }
}; const Gr = ['title', 'abbr', 'onClick']; const Wr = { class: 'd-inline-flex flex-nowrap align-items-center gap-1' }; const Ur = {
  key: 0,
  class: 'b-table-sort-icon text-muted small',
}; const Xr = { key: 1 }; const Kr = ['onClick', 'onDblclick', 'onMouseenter', 'onMouseleave']; const Jr = ['colspan']; const Yr = ['colspan']; const Zr = { class: 'd-flex align-items-center justify-content-center gap-2' }; const Qr = /* @__PURE__ */ H('strong', null, 'Loading...', -1); const ei = {
  key: 1,
  class: 'b-table-empty-slot',
}; const ti = ['colspan']; const ai = { key: 0 }; const li = ['title', 'abbr', 'onClick']; const ni = { key: 1 }; const oi = { key: 2 }; const si = { key: 3 }; const ri = /* @__PURE__ */ x({
  __name: 'BTable',
  props: {
    align: null,
    caption: null,
    captionTop: { default: !1 },
    borderless: { default: !1 },
    bordered: { default: !1 },
    borderVariant: null,
    dark: { default: !1 },
    fields: { default: () => [] },
    footClone: { default: !1 },
    hover: { default: !1 },
    items: { default: () => [] },
    provider: null,
    sortCompare: null,
    noProvider: null,
    noProviderPaging: null,
    noProviderSorting: null,
    noProviderFiltering: null,
    responsive: { type: [Boolean, String], default: !1 },
    small: { default: !1 },
    striped: { default: !1 },
    variant: null,
    sortBy: null,
    sortDesc: { default: !1 },
    sortInternal: { default: !0 },
    selectable: { default: !1 },
    stickySelect: { default: !1 },
    selectHead: { type: [Boolean, String], default: !0 },
    selectMode: { default: 'single' },
    selectionVariant: { default: 'primary' },
    stickyHeader: { default: !1 },
    busy: { default: !1 },
    showEmpty: { default: !1 },
    perPage: null,
    currentPage: { default: 1 },
    filter: null,
    filterable: null,
    emptyText: { default: 'There are no records to show' },
    emptyFilteredText: { default: 'There are no records matching your request' },
  },
  emits: ['headClicked', 'rowClicked', 'rowDblClicked', 'rowHovered', 'rowUnhovered', 'rowSelected', 'rowUnselected', 'selection', 'update:busy', 'update:sortBy', 'update:sortDesc', 'sorted', 'filtered'],
  setup(e, { expose: t, emit: a }) {
    const l = e; const n = r(o(l, 'footClone')); const i = r(o(l, 'sortDesc')); const f = r(o(l, 'sortInternal')); const d = r(o(l, 'selectable')); const v = r(o(l, 'stickySelect')); const b = r(o(l, 'busy')); const g = r(o(l, 'showEmpty')); const B = r(o(l, 'showEmpty')); const h = r(o(l, 'showEmpty')); const k = r(o(l, 'showEmpty')); const w = X(b.value); const y = Ee(); const V = u(() => ({
      [`align-${l.align}`]: l.align !== void 0,
      'b-table-selectable': d.value,
      [`b-table-select-${l.selectMode}`]: d.value,
      'b-table-selecting user-select-none': d.value && Se.value,
      'b-table-busy': w.value,
      'b-table-sortable': se.value,
      'b-table-sort-desc': se.value && i.value === !0,
      'b-table-sort-asc': se.value && i.value === !1,
    })); const $ = u(() => ({
      bordered: l.bordered,
      borderless: l.borderless,
      borderVariant: l.borderVariant,
      captionTop: l.captionTop,
      dark: l.dark,
      hover: l.hover,
      responsive: l.responsive,
      striped: l.striped,
      small: l.small,
      tableClass: V.value,
      tableVariant: l.variant,
      stickyHeader: l.stickyHeader,
    })); const z = Mr()
    z.filterEvent.value = async (C) => {
      if (I.value) {
        await Ae()
        return
      }
      const E = await St(C)
      a('filtered', E)
    }
    const L = u(() => z.normaliseFields(l.fields, l.items)); const S = u(
      () => L.value.length + (d.value ? 1 : 0),
    ); const A = u(() => l.filter !== void 0 && l.filter !== ''); const I = u(() => l.provider !== void 0); const M = u(() => se.value && f.value === !0); const _ = u(() => I.value
      ? z.internalItems.value
      : M.value
        ? z.mapItems(l.fields, l.items, l, {
          isSortable: se,
          isFilterableTable: A,
          sortDescBoolean: i,
        })
        : l.items); const T = C => typeof C == 'string' ? Rt(C) : C.label !== void 0 ? C.label : typeof C.key == 'string' ? Rt(C.key) : C.key; const D = (C, E, ne = !1) => {
      const N = typeof C == 'string' ? C : C.key
      a('headClicked', N, C, E, ne), Q(C)
    }; const q = (C, E, ne) => {
      a('rowClicked', C, E, ne), $e(C, E, ne.shiftKey)
    }; const le = (C, E, ne) => a('rowDblClicked', C, E, ne); const te = (C, E, ne) => a('rowHovered', C, E, ne); const K = (C, E, ne) => a('rowUnhovered', C, E, ne); const de = u(
      () => d.value && (!!l.selectHead || y.selectHead !== void 0),
    ); const se = u(
      () => l.fields.filter(C => typeof C == 'string' ? !1 : C.sortable).length > 0,
    ); const Q = (C) => {
      if (!se.value)
        return
      const E = typeof C == 'string' ? C : C.key; const ne = typeof C == 'string' ? !1 : C.sortable
      if (se.value === !0 && ne === !0) {
        const N = !i.value
        E !== l.sortBy && a('update:sortBy', E), a('update:sortDesc', N), a('sorted', E, N)
      }
    }; const Z = X(/* @__PURE__ */ new Set([])); const Se = u(() => Z.value.size > 0); const ue = () => {
      !d.value || a('selection', Array.from(Z.value))
    }; const $e = (C, E, ne = !1) => {
      if (d.value) {
        if (Z.value.has(C))
          Z.value.delete(C), a('rowUnselected', C)
        else if (l.selectMode === 'single' && Z.value.size > 0 && (Z.value.forEach(N => a('rowUnselected', N)), Z.value.clear()), l.selectMode === 'range' && Z.value.size > 0 && ne) {
          const N = Array.from(Z.value).pop(); const he = _.value.findIndex(Le => Le === N); const fe = Math.min(he, E); const pt = Math.max(he, E)
          _.value.slice(fe, pt + 1).forEach((Le) => {
            Z.value.has(Le) || (Z.value.add(Le), a('rowSelected', Le))
          })
        }
        else
          Z.value.add(C), a('rowSelected', C)
        ue()
      }
    }; const Ae = async () => {
      if (!I.value || !l.provider || w.value)
        return
      w.value = !0
      const C = new Proxy(
        {
          currentPage: l.currentPage,
          filter: l.filter,
          sortBy: l.sortBy,
          sortDesc: l.sortDesc,
          perPage: l.perPage,
        },
        {
          get(ne, N) {
            return N in ne ? ne[N] : void 0
          },
          set() {
            return console.error('BTable provider context is a read-only object.'), !0
          },
        },
      ); const E = l.provider(C, z.updateInternalItems)
      if (E !== void 0) {
        if (E instanceof Promise)
          try {
            const ne = await E
            return Array.isArray(ne) ? await z.updateInternalItems(ne) : void 0
          }
          finally {
            w.value || (w.value = !1)
          }
        try {
          return await z.updateInternalItems(E)
        }
        finally {
          w.value && (w.value = !1)
        }
      }
    }; const U = (C) => {
      C._showDetails = !C._showDetails
    }; const ee = C => [
      C.class,
      C.thClass,
      C.variant ? `table-${C.variant}` : void 0,
      {
        'b-table-sortable-column': se.value && C.sortable,
        'b-table-sticky-column': C.stickyColumn,
      },
    ]; const G = (C, E) => [
      C.class,
      C.tdClass,
      C.variant ? `table-${C.variant}` : void 0,
      (E == null ? void 0 : E._cellVariants) && (E == null ? void 0 : E._cellVariants[C.key]) ? `table-${E == null ? void 0 : E._cellVariants[C.key]}` : void 0,
      {
        'b-table-sticky-column': C.stickyColumn,
      },
    ]; const be = C => [
      C._rowVariant ? `table-${C._rowVariant}` : null,
      C._rowVariant ? `table-${C._rowVariant}` : null,
      d.value && Z.value.has(C) ? `selected table-${l.selectionVariant}` : null,
    ]; const xe = () => {
      if (!d.value)
        return
      const C = Z.value.size > 0 ? Array.from(Z.value) : []
      Z.value = /* @__PURE__ */ new Set([..._.value]), Z.value.forEach((E) => {
        C.includes(E) || a('rowSelected', E)
      }), ue()
    }; const mt = () => {
      !d.value || (Z.value.forEach((C) => {
        a('rowUnselected', C)
      }), Z.value = /* @__PURE__ */ new Set([]), ue())
    }; const bt = (C) => {
      if (!d.value)
        return
      const E = _.value[C]
      !E || Z.value.has(E) || (Z.value.add(E), a('rowSelected', E), ue())
    }; const gt = (C) => {
      if (!d.value)
        return
      const E = _.value[C]
      !E || !Z.value.has(E) || (Z.value.delete(E), a('rowUnselected', E), ue())
    }
    pe(() => {
      I.value && Ae()
    }), re(
      () => l.filter,
      (C, E) => {
        C === E || I.value || C || St(l.items).then(ne => a('filtered', ne))
      },
    )
    const Ne = async (C, E, ne) => {
      if (E === ne)
        return
      const N = Le => l.noProvider && l.noProvider.includes(Le); const he = ['currentPage', 'perPage'].includes(C) && (N('paging') || B.value === !0); const fe = ['filter'].includes(C) && (N('filtering') || k.value === !0); const pt = ['sortBy', 'sortDesc'].includes(C) && (N('sorting') || h.value === !0)
      he || fe || pt || await Ae()
    }
    return re(
      () => w.value,
      () => w.value !== b.value && a('update:busy', w.value),
    ), re(
      () => b.value,
      () => w.value !== b.value && (w.value = b.value),
    ), re(
      () => l.filter,
      (C, E) => Ne('filter', C, E),
    ), re(
      () => l.currentPage,
      (C, E) => Ne('currentPage', C, E),
    ), re(
      () => l.perPage,
      (C, E) => Ne('perPage', C, E),
    ), re(
      () => l.sortBy,
      (C, E) => Ne('sortBy', C, E),
    ), re(
      () => l.sortDesc,
      (C, E) => Ne('sortDesc', C, E),
    ), t({
      selectAllRows: xe,
      clearSelected: mt,
      selectRow: bt,
      unselectRow: gt,
    }), (C, E) => (c(), O(vt, ge(Be(s($))), {
      default: P(() => {
        let ne
        return [
          H('thead', null, [
            C.$slots['thead-top'] ? p(C.$slots, 'thead-top', { key: 0 }) : j('', !0),
            H('tr', null, [
              s(de)
                ? (c(), m('th', {
                    key: 0,
                    class: F(['b-table-selection-column', {
                      'b-table-sticky-column': s(v),
                    }]),
                  }, [
                    p(C.$slots, 'select-head', {}, () => [
                      Y(R(typeof e.selectHead == 'boolean' ? 'Selected' : e.selectHead), 1),
                    ]),
                  ], 2))
                : j('', !0),
              (c(!0), m(ae, null, ie(s(L), N => (c(), m('th', W({
                key: N.key,
                scope: 'col',
                class: ee(N),
                title: N.headerTitle,
                abbr: N.headerAbbr,
                style: N.thStyle,
              }, N.thAttr, {
                onClick: he => D(N, he),
              }), [
                H('div', Wr, [
                  p(C.$slots, 'sort-icon', {
                    field: N,
                    sortBy: e.sortBy,
                    selected: N.key === e.sortBy,
                    isDesc: s(i),
                    direction: s(i) ? 'desc' : 'asc',
                  }, () => [
                    s(se) && N.sortable && N.key === e.sortBy ? (c(), m('span', Ur)) : j('', !0),
                  ]),
                  H('div', null, [
                    C.$slots[`head(${N.key})`] || C.$slots['head()']
                      ? p(C.$slots, C.$slots[`head(${N.key})`] ? `head(${N.key})` : 'head()', {
                        key: 0,
                        label: N.label,
                      })
                      : (c(), m(ae, { key: 1 }, [
                          Y(R(T(N)), 1),
                        ], 64)),
                  ]),
                ]),
              ], 16, Gr))), 128)),
            ]),
            C.$slots['thead-sub']
              ? (c(), m('tr', Xr, [
                  (c(!0), m(ae, null, ie(s(L), N => (c(), m('td', {
                    key: N.key,
                    scope: 'col',
                    class: F([N.class, N.thClass, N.variant ? `table-${N.variant}` : '']),
                  }, [
                    C.$slots['thead-sub']
                      ? p(C.$slots, 'thead-sub', W({
                        key: 0,
                        items: s(L),
                      }, N))
                      : (c(), m(ae, { key: 1 }, [
                          Y(R(N.label), 1),
                        ], 64)),
                  ], 2))), 128)),
                ]))
              : j('', !0),
          ]),
          H('tbody', null, [
            (c(!0), m(ae, null, ie(s(_), (N, he) => (c(), m(ae, { key: he }, [
              H('tr', {
                class: F(be(N)),
                onClick: fe => q(N, he, fe),
                onDblclick: fe => le(N, he, fe),
                onMouseenter: fe => te(N, he, fe),
                onMouseleave: fe => K(N, he, fe),
              }, [
                s(de)
                  ? (c(), m('td', {
                      key: 0,
                      class: F(['b-table-selection-column', {
                        'b-table-sticky-column': s(v),
                      }]),
                    }, [
                      p(C.$slots, 'select-cell', {}, () => [
                        H('span', {
                          class: F(Z.value.has(N) ? 'text-primary' : ''),
                        }, '\u{1F5F9}', 2),
                      ]),
                    ], 2))
                  : j('', !0),
                (c(!0), m(ae, null, ie(s(L), fe => (c(), m('td', W({
                  key: fe.key,
                }, fe.tdAttr, {
                  class: G(fe, N),
                }), [
                  C.$slots[`cell(${fe.key})`] || C.$slots['cell()']
                    ? p(C.$slots, C.$slots[`cell(${fe.key})`] ? `cell(${fe.key})` : 'cell()', {
                      key: 0,
                      value: N[fe.key],
                      index: he,
                      item: N,
                      field: fe,
                      items: e.items,
                      toggleDetails: () => U(N),
                      detailsShowing: N._showDetails,
                    })
                    : (c(), m(ae, { key: 1 }, [
                        Y(R(N[fe.key]), 1),
                      ], 64)),
                ], 16))), 128)),
              ], 42, Kr),
              N._showDetails === !0 && C.$slots['row-details']
                ? (c(), m('tr', {
                    key: 0,
                    class: F(be(N)),
                  }, [
                    H('td', { colspan: s(S) }, [
                      p(C.$slots, 'row-details', {
                        item: N,
                        toggleDetails: () => U(N),
                      }),
                    ], 8, Jr),
                  ], 2))
                : j('', !0),
            ], 64))), 128)),
            w.value
              ? (c(), m('tr', {
                  key: 0,
                  class: F(['b-table-busy-slot', { 'b-table-static-busy': s(_).length == 0 }]),
                }, [
                  H('td', { colspan: s(S) }, [
                    p(C.$slots, 'table-busy', {}, () => [
                      H('div', Zr, [
                        ce(ft, { class: 'align-middle' }),
                        Qr,
                      ]),
                    ]),
                  ], 8, Yr),
                ], 2))
              : j('', !0),
            s(g) && s(_).length === 0
              ? (c(), m('tr', ei, [
                  H('td', { colspan: s(S) }, [
                    p(C.$slots, 'empty', {
                      items: s(_),
                      filtered: s(A),
                    }, () => [
                      Y(R(s(A) ? e.emptyFilteredText : e.emptyText), 1),
                    ]),
                  ], 8, ti),
                ]))
              : j('', !0),
          ]),
          s(n)
            ? (c(), m('tfoot', ai, [
                H('tr', null, [
                  (c(!0), m(ae, null, ie(s(L), N => (c(), m('th', W({
                    key: N.key,
                  }, N.thAttr, {
                    scope: 'col',
                    class: [N.class, N.thClass, N.variant ? `table-${N.variant}` : ''],
                    title: N.headerTitle,
                    abbr: N.headerAbbr,
                    style: N.thStyle,
                    onClick: he => D(N, he, !0),
                  }), R(N.label), 17, li))), 128)),
                ]),
              ]))
            : C.$slots['custom-foot']
              ? (c(), m('tfoot', ni, [
                  p(C.$slots, 'custom-foot', {
                    fields: s(L),
                    items: e.items,
                    columns: (ne = s(L)) == null ? void 0 : ne.length,
                  }),
                ]))
              : j('', !0),
          C.$slots['table-caption']
            ? (c(), m('caption', oi, [
                p(C.$slots, 'table-caption'),
              ]))
            : e.caption ? (c(), m('caption', si, R(e.caption), 1)) : j('', !0),
        ]
      }),
      _: 3,
    }, 16))
  },
}); const ii = /* @__PURE__ */ x({
  __name: 'BTbody',
  props: {
    variant: null,
  },
  setup(e) {
    const t = e; const a = u(() => ({
      [`thead-${t.variant}`]: t.variant !== void 0,
    }))
    return (l, n) => (c(), m('tbody', {
      role: 'rowgroup',
      class: F(s(a)),
    }, [
      p(l.$slots, 'default'),
    ], 2))
  },
}); const ui = ['scope', 'colspan', 'rowspan', 'data-label']; const di = { key: 0 }; const ci = /* @__PURE__ */ x({
  __name: 'BTd',
  props: {
    colspan: null,
    rowspan: null,
    stackedHeading: null,
    stickyColumn: { default: !1 },
    variant: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'stickyColumn')); const l = u(() => ({
      [`table-${t.variant}`]: t.variant !== void 0,
      'b-table-sticky-column': a.value,
      'table-b-table-default': a.value && !t.variant,
    })); const n = u(() => t.colspan ? 'colspan' : t.rowspan ? 'rowspan' : 'col')
    return (i, f) => (c(), m('td', {
      'role': 'cell',
      'scope': s(n),
      'class': F(s(l)),
      'colspan': e.colspan,
      'rowspan': e.rowspan,
      'data-label': e.stackedHeading,
    }, [
      e.stackedHeading
        ? (c(), m('div', di, [
            p(i.$slots, 'default'),
          ]))
        : p(i.$slots, 'default', { key: 1 }),
    ], 10, ui))
  },
}); const fi = /* @__PURE__ */ x({
  __name: 'BTfoot',
  props: {
    variant: null,
  },
  setup(e) {
    const t = e; const a = u(() => ({
      [`table-${t.variant}`]: t.variant !== void 0,
    }))
    return (l, n) => (c(), m('tfoot', {
      role: 'rowgroup',
      class: F(s(a)),
    }, [
      p(l.$slots, 'default'),
    ], 2))
  },
}); const vi = ['scope', 'colspan', 'rowspan', 'data-label']; const mi = { key: 0 }; const bi = /* @__PURE__ */ x({
  __name: 'BTh',
  props: {
    colspan: null,
    rowspan: null,
    stackedHeading: null,
    stickyColumn: { default: !1 },
    variant: null,
  },
  setup(e) {
    const t = e; const a = r(o(t, 'stickyColumn')); const l = u(() => ({
      [`table-${t.variant}`]: t.variant !== void 0,
      'b-table-sticky-column': a.value,
      'table-b-table-default': a.value && t.variant === void 0,
    })); const n = u(() => t.colspan ? 'colspan' : t.rowspan ? 'rowspan' : 'col')
    return (i, f) => (c(), m('th', {
      'role': 'columnheader',
      'scope': s(n),
      'class': F(s(l)),
      'colspan': e.colspan,
      'rowspan': e.rowspan,
      'data-label': e.stackedHeading,
    }, [
      e.stackedHeading !== void 0
        ? (c(), m('div', mi, [
            p(i.$slots, 'default'),
          ]))
        : p(i.$slots, 'default', { key: 1 }),
    ], 10, vi))
  },
}); const gi = /* @__PURE__ */ x({
  __name: 'BThead',
  props: {
    variant: null,
  },
  setup(e) {
    const t = e; const a = u(() => ({
      [`table-${t.variant}`]: t.variant !== void 0,
    }))
    return (l, n) => (c(), m('thead', {
      role: 'rowgroup',
      class: F(s(a)),
    }, [
      p(l.$slots, 'default'),
    ], 2))
  },
}); const pi = /* @__PURE__ */ x({
  __name: 'BTr',
  props: {
    variant: null,
  },
  setup(e) {
    const t = e; const a = u(() => ({
      [`table-${t.variant}`]: t.variant !== void 0,
    }))
    return (l, n) => (c(), m('tr', {
      role: 'row',
      class: F(s(a)),
    }, [
      p(l.$slots, 'default'),
    ], 2))
  },
}); const hi = ['id', 'data-bs-target', 'aria-controls', 'aria-selected', 'onClick']; const al = Symbol(); const yi = /* @__PURE__ */ x({
  __name: 'BTabs',
  props: {
    activeNavItemClass: null,
    activeTabClass: null,
    align: null,
    card: { default: !1 },
    contentClass: null,
    end: { default: !1 },
    fill: { default: !1 },
    id: null,
    justified: { default: !1 },
    lazy: { default: !1 },
    navClass: null,
    navWrapperClass: null,
    noFade: { default: !1 },
    noNavStyle: { default: !1 },
    pills: { default: !1 },
    small: { default: !1 },
    tag: { default: 'div' },
    vertical: { default: !1 },
    modelValue: { default: -1 },
  },
  emits: ['update:modelValue', 'activate-tab', 'click'],
  setup(e, { emit: t }) {
    const a = e; const l = r(o(a, 'card')); const n = r(o(a, 'end')); const i = r(o(a, 'fill')); const f = r(o(a, 'justified')); const d = r(o(a, 'lazy')); const v = r(o(a, 'noFade')); const b = r(o(a, 'noNavStyle')); const g = r(o(a, 'pills')); const B = r(o(a, 'small')); const h = r(o(a, 'vertical')); const k = Ee(); const w = X(a.modelValue); const y = X(''); const V = u({
      get: () => w.value,
      set: (_) => {
        w.value = _, $.value.length > 0 && _ >= 0 && _ < $.value.length ? y.value = $.value[_].buttonId : y.value = '', t('update:modelValue', _)
      },
    }); const $ = u(() => {
      let _ = []
      return k.default && (_ = M(k).map((T, D) => {
        T.props || (T.props = {})
        const q = T.props['button-id'] || Ie('tab'); const le = T.props.id || Ie(); const te = V.value > -1 ? D === V.value : T.props.active === ''; const K = T.props['title-item-class']; const de = T.props['title-link-attributes']
        return {
          buttonId: q,
          contentId: le,
          active: te,
          disabled: T.props.disabled === '' || T.props.disabled === !0,
          navItemClasses: [
            {
              active: te,
              disabled: T.props.disabled === '' || T.props.disabled === !0,
            },
            te && a.activeNavItemClass ? a.activeNavItemClass : null,
            T.props['title-link-class'],
          ],
          tabClasses: [
            {
              fade: !v.value,
            },
            te && a.activeTabClass ? a.activeTabClass : null,
          ],
          target: `#${le}`,
          title: T.props.title,
          titleItemClass: K,
          titleLinkAttributes: de,
          onClick: T.props.onClick,
          tab: T,
        }
      })), _
    }); const z = u(() => !(($.value == null ? void 0 : $.value) && $.value.length > 0)); const L = u(() => ({
      'd-flex': h.value,
      'align-items-start': h.value,
    })); const S = u(() => ({
      'nav-pills': g.value,
      'flex-column me-3': h.value,
      [`justify-content-${a.align}`]: a.align !== void 0,
      'nav-fill': i.value,
      'card-header-tabs': l.value,
      'nav-justified': f.value,
      'nav-tabs': !b.value && !g.value,
      'small': B.value,
    })); const A = (_) => {
      let T = !1
      if (_ !== void 0 && _ > -1 && _ < $.value.length && !$.value[_].disabled && (V.value < 0 || $.value[_].buttonId !== y.value)) {
        const D = new ut('activate-tab', { cancelable: !0, vueTarget: this })
        t('activate-tab', _, V.value, D), D.defaultPrevented || (V.value = _, T = !0)
      }
      return !T && a.modelValue !== V.value && t('update:modelValue', V.value), T
    }; const I = (_, T) => {
      let D
      A(T), T >= 0 && !$.value[T].disabled && ((D = $.value[T]) == null ? void 0 : D.onClick) && typeof $.value[T].onClick == 'function' && $.value[T].onClick(_)
    }; const M = _ => !_ || !_.default
      ? []
      : _.default().reduce((T, D) => (typeof D.type == 'symbol' ? T = T.concat(D.children) : T.push(D), T), []).filter((T) => {
        let D
        return ((D = T.type) == null ? void 0 : D.__name) === 'BTab'
      })
    return A(w.value), re(
      () => a.modelValue,
      (_, T) => {
        if (_ === T)
          return
        if (_ = Math.max(_, -1), T = Math.max(T, -1), $.value.length <= 0) {
          V.value = -1
          return
        }
        const D = _ > T
        let q = _
        const le = $.value.length - 1
        for (; q >= 0 && q <= le && $.value[q].disabled;)
          q += D ? 1 : -1
        if (q < 0) {
          A(0)
          return
        }
        if (q >= $.value.length) {
          A($.value.length - 1)
          return
        }
        A(q)
      },
    ), re(
      () => $.value,
      () => {
        let _ = $.value.map(T => T.active && !T.disabled).lastIndexOf(!0)
        _ < 0 && (V.value >= $.value.length ? _ = $.value.map(T => !T.disabled).lastIndexOf(!0) : $.value[V.value] && !$.value[V.value].disabled && (_ = V.value)), _ < 0 && (_ = $.value.map(T => !T.disabled).indexOf(!0)), $.value.forEach((T, D) => T.active = D === _), A(_)
      },
    ), pe(() => {
      if (V.value < 0 && $.value.length > 0 && !$.value.some(_ => _.active)) {
        const _ = $.value.map(T => !T.disabled).indexOf(!0)
        A(_ >= 0 ? _ : -1)
      }
    }), Me(al, {
      lazy: d.value,
      card: l.value,
    }), (_, T) => (c(), O(J(e.tag), {
      id: e.id,
      class: F(['tabs', s(L)]),
    }, {
      default: P(() => [
        s(n)
          ? (c(), m('div', {
              key: 0,
              class: F(['tab-content', e.contentClass]),
            }, [
              (c(!0), m(ae, null, ie(s($), ({ tab: D, contentId: q, tabClasses: le, active: te }, K) => (c(), O(J(D), {
                id: q,
                key: K,
                class: F(le),
                active: te,
              }, null, 8, ['id', 'class', 'active']))), 128)),
              s(z)
                ? (c(), m('div', {
                    key: 'bv-empty-tab',
                    class: F(['tab-pane active', { 'card-body': s(l) }]),
                  }, [
                    p(_.$slots, 'empty'),
                  ], 2))
                : j('', !0),
            ], 2))
          : j('', !0),
        H('div', {
          class: F([e.navWrapperClass, { 'card-header': s(l), 'ms-auto': e.vertical && s(n) }]),
        }, [
          H('ul', {
            class: F(['nav', [s(S), e.navClass]]),
            role: 'tablist',
          }, [
            p(_.$slots, 'tabs-start'),
            (c(!0), m(ae, null, ie(s($), ({ tab: D, buttonId: q, contentId: le, navItemClasses: te, active: K, target: de }, se) => (c(), m('li', {
              key: se,
              class: F(['nav-item', D.props['title-item-class']]),
            }, [
              H('button', W({
                'id': q,
                'class': ['nav-link', te],
                'data-bs-toggle': 'tab',
                'data-bs-target': de,
                'role': 'tab',
                'aria-controls': le,
                'aria-selected': K,
              }, D.props['title-link-attributes'], {
                onClick: rt(Q => I(Q, se), ['stop', 'prevent']),
              }), [
                D.children && D.children.title
                  ? (c(), O(J(D.children.title), { key: 0 }))
                  : (c(), m(ae, { key: 1 }, [
                      Y(R(D.props.title), 1),
                    ], 64)),
              ], 16, hi),
            ], 2))), 128)),
            p(_.$slots, 'tabs-end'),
          ], 2),
        ], 2),
        s(n)
          ? j('', !0)
          : (c(), m('div', {
              key: 1,
              class: F(['tab-content', e.contentClass]),
            }, [
              (c(!0), m(ae, null, ie(s($), ({ tab: D, contentId: q, tabClasses: le, active: te }, K) => (c(), O(J(D), {
                id: q,
                key: K,
                class: F(le),
                active: te,
              }, null, 8, ['id', 'class', 'active']))), 128)),
              s(z)
                ? (c(), m('div', {
                    key: 'bv-empty-tab',
                    class: F(['tab-pane active', { 'card-body': s(l) }]),
                  }, [
                    p(_.$slots, 'empty'),
                  ], 2))
                : j('', !0),
            ], 2)),
      ]),
      _: 3,
    }, 8, ['id', 'class']))
  },
}); const Bi = /* @__PURE__ */ x({
  __name: 'BTab',
  props: {
    id: null,
    title: null,
    active: { default: !1 },
    buttonId: { default: void 0 },
    disabled: { default: !1 },
    lazy: { default: void 0 },
    lazyOnce: { default: void 0 },
    noBody: { type: [Boolean, String], default: !1 },
    tag: { default: 'div' },
    titleItemClass: { default: void 0 },
    titleLinkAttributes: { default: void 0 },
    titleLinkClass: { default: void 0 },
  },
  setup(e) {
    const t = e; const a = X(!1); const l = r(o(t, 'active')); const n = r(o(t, 'disabled')); const i = r(o(t, t.lazyOnce !== void 0 ? 'lazyOnce' : 'lazy')); const f = Oe(al, null); const d = u(() => (f == null ? void 0 : f.lazy) || i.value); const v = u(() => t.lazyOnce !== void 0); const b = u(() => l.value && !n.value); const g = u(() => {
      const h = d.value && v.value && a.value
      return b.value || !d.value || h
    }); const B = u(() => ({
      'active': l.value,
      'show': l.value,
      'card-body': (f == null ? void 0 : f.card) && t.noBody === !1,
    }))
    return re(
      () => g.value,
      (h) => {
        h && !a.value && (a.value = !0)
      },
    ), (h, k) => (c(), O(J(e.tag), {
      'id': e.id,
      'class': F(['tab-pane', s(B)]),
      'role': 'tabpanel',
      'aria-labelledby': 'profile-tab',
    }, {
      default: P(() => [
        s(g) ? p(h.$slots, 'default', { key: 0 }) : j('', !0),
      ]),
      _: 3,
    }, 8, ['id', 'class']))
  },
}); const $i = {
  BAccordion: Wl,
  BAccordionItem: dn,
  BNavText: tr,
  BAlert: fn,
  BAvatar: pn,
  BAvatarGroup: vn,
  BNavForm: Us,
  BBadge: kn,
  BBreadcrumb: Vn,
  BBreadcrumbItem: xa,
  BButton: Ke,
  BButtonGroup: In,
  BButtonToolbar: On,
  BCard: La,
  BCardBody: Pa,
  BCardFooter: Na,
  BCardGroup: Pn,
  BCardHeader: Fa,
  BCardImg: ot,
  BCardSubtitle: za,
  BCardText: Nn,
  BCardTitle: Oa,
  BCarousel: Un,
  BCarouselSlide: Qn,
  BCloseButton: Ge,
  BCol: Ue,
  BCollapse: Va,
  BContainer: ro,
  BDropdown: qa,
  BDropdownDivider: fo,
  BDropdownForm: po,
  BDropdownGroup: $o,
  BDropdownHeader: wo,
  BDropdownItem: Vo,
  BDropdownItemButton: Ao,
  BDropdownText: Fo,
  BForm: ja,
  BFormCheckbox: Ma,
  BFormCheckboxGroup: jo,
  BFormFloatingLabel: No,
  BFormGroup: Yo,
  BFormInput: ts,
  BFormInvalidFeedback: At,
  BFormRadio: Wa,
  BFormRadioGroup: rs,
  BFormRow: tt,
  BFormSelect: cs,
  BFormSelectOption: Ht,
  BFormSelectOptionGroup: Ua,
  BFormText: xt,
  BFormTextarea: xs,
  BFormTag: Xa,
  BFormTags: Ts,
  BFormValidFeedback: It,
  BImg: Nt,
  BInputGroup: Ls,
  BInputGroupAddon: Et,
  BInputGroupAppend: Hs,
  BInputGroupPrepend: Es,
  BInputGroupText: Ka,
  BLink: Ce,
  BListGroup: Ds,
  BListGroupItem: Rs,
  BModal: Gs,
  BNav: Ws,
  BNavbar: ar,
  BNavbarBrand: or,
  BNavbarNav: sr,
  BNavbarToggle: ir,
  BNavItem: Ys,
  BNavItemDropdown: Qs,
  BOffcanvas: mr,
  BOverlay: br,
  BPagination: Cr,
  BPlaceholder: we,
  BPlaceholderButton: Qa,
  BPlaceholderCard: wr,
  BPlaceholderTable: Tr,
  BPlaceholderWrapper: Vr,
  BPopover: Or,
  BProgress: Pr,
  BProgressBar: el,
  BRow: Hr,
  BSkeleton: at,
  BSkeletonIcon: Er,
  BSkeletonTable: qr,
  BSkeletonWrapper: jr,
  BSpinner: ft,
  BTab: Bi,
  BTable: ri,
  BTableSimple: vt,
  BTbody: ii,
  BTd: ci,
  BTfoot: fi,
  BTh: bi,
  BThead: gi,
  BTr: pi,
  BToast: Ea,
  BToaster: Vt,
  BToastContainer: Vt,
  BTabs: yi,
  BTransition: Lt,
  BToastPlugin: so,
}
const Ti = {
  install(e, t = {}) {
    Object.entries($i).forEach(([a, l]) => {
      e.component(a, l)
    }), Object.entries(nn).forEach(([a, l]) => {
      e.directive(a, l)
    }), jl(e)
  },
}
export {
  Wl as BAccordion,
  dn as BAccordionItem,
  fn as BAlert,
  pn as BAvatar,
  vn as BAvatarGroup,
  kn as BBadge,
  Vn as BBreadcrumb,
  xa as BBreadcrumbItem,
  Ke as BButton,
  In as BButtonGroup,
  On as BButtonToolbar,
  La as BCard,
  Pa as BCardBody,
  Na as BCardFooter,
  Pn as BCardGroup,
  Fa as BCardHeader,
  ot as BCardImg,
  za as BCardSubtitle,
  Nn as BCardText,
  Oa as BCardTitle,
  Un as BCarousel,
  Qn as BCarouselSlide,
  Ge as BCloseButton,
  Ue as BCol,
  Va as BCollapse,
  ro as BContainer,
  qa as BDropdown,
  fo as BDropdownDivider,
  po as BDropdownForm,
  $o as BDropdownGroup,
  wo as BDropdownHeader,
  Vo as BDropdownItem,
  Ao as BDropdownItemButton,
  Fo as BDropdownText,
  ja as BForm,
  Ma as BFormCheckbox,
  jo as BFormCheckboxGroup,
  No as BFormFloatingLabel,
  Yo as BFormGroup,
  ts as BFormInput,
  At as BFormInvalidFeedback,
  Wa as BFormRadio,
  rs as BFormRadioGroup,
  tt as BFormRow,
  cs as BFormSelect,
  Ht as BFormSelectOption,
  Ua as BFormSelectOptionGroup,
  Xa as BFormTag,
  Ts as BFormTags,
  xt as BFormText,
  xs as BFormTextarea,
  It as BFormValidFeedback,
  Nt as BImg,
  Ls as BInputGroup,
  Et as BInputGroupAddon,
  Hs as BInputGroupAppend,
  Es as BInputGroupPrepend,
  Ka as BInputGroupText,
  Ce as BLink,
  Ds as BListGroup,
  Rs as BListGroupItem,
  Gs as BModal,
  Ws as BNav,
  Us as BNavForm,
  Ys as BNavItem,
  Qs as BNavItemDropdown,
  tr as BNavText,
  ar as BNavbar,
  or as BNavbarBrand,
  sr as BNavbarNav,
  ir as BNavbarToggle,
  mr as BOffcanvas,
  br as BOverlay,
  Cr as BPagination,
  we as BPlaceholder,
  Qa as BPlaceholderButton,
  wr as BPlaceholderCard,
  Tr as BPlaceholderTable,
  Vr as BPlaceholderWrapper,
  Or as BPopover,
  Pr as BProgress,
  el as BProgressBar,
  Hr as BRow,
  at as BSkeleton,
  Er as BSkeletonIcon,
  qr as BSkeletonTable,
  jr as BSkeletonWrapper,
  ft as BSpinner,
  Bi as BTab,
  ri as BTable,
  vt as BTableSimple,
  yi as BTabs,
  ii as BTbody,
  ci as BTd,
  fi as BTfoot,
  bi as BTh,
  gi as BThead,
  Ea as BToast,
  Vt as BToastContainer,
  so as BToastPlugin,
  Vt as BToaster,
  pi as BTr,
  Lt as BTransition,
  Ti as BootstrapVue3,
  ut as BvEvent,
  Ti as default,
  Ml as useBreadcrumb,
  Ra as useToast,
  Xl as vBPopover,
  Pt as vBToggle,
  en as vBTooltip,
  tn as vBVisible,
}
