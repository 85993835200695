import api from '@/plugins/api'

export const useAuthStore = defineStore('auth', () => {
  const session = ref(null)

  const check = computed(() => session.value !== null)

  function setSession({ data }: any) {
    session.value = data
  }

  async function getUser() {
    return api
      .get('user', { withCredentials: true })
      .then(({ data }) => {
        setSession({ data })
        return data
      })
      .catch((err) => {
        let host = document.domain

        if (document.location.port)
          host += ':48001'
        window.location.href = `${document.location.protocol}//${host}/api/auth/login`
        return err.response.data
      })
  }

  return {
    check,
    session,
    setSession,
    getUser,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
