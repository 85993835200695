import { v4 } from 'uuid'
import { socket } from '@/socket'
import { ObjectUtil } from '@/utils/object'
import { parseMXErrors, parseTXTErrors } from '@/socket/errors'

export const useSocketStore = defineStore('socket', () => {
  const connected = ref(false)
  const dnsStore = useDnsStore()
  const logStore = useLogStore()

  const isConnected = computed(() => connected.value)

  watch(
    () => isConnected.value,
    (_count, _prevCount) => {
      // console.log(count, prevCount)
    },
  )

  const listen = (onFunctionName?: string, callback?: Function) => {
    if (onFunctionName && callback && !socket?.hasListeners(onFunctionName)) {
      socket.on(onFunctionName, (data: any) => {
        callback(data)
      })
    }
  }

  function init() {
    listen('connect', connect)
    listen('disconnect', disconnect)

    listen('DOMAIN_RESULT', SOCKET_DOMAIN_RESULT)
    listen('PING_RESULT', SOCKET_PING_RESULT)
    listen('REVERSE_RESULT', SOCKET_REVERSE_RESULT)
    listen('WHOIS_RESULT', SOCKET_WHOIS_RESULT)
    listen('SSL_CHECK_RESULT', SOCKET_SSL_CHECK_RESULT)
  }

  const emit = (eventName: string, data: any) => {
    socket.emit(eventName, data)
  }

  const reconnect = () => {
    socket.disconnect()
    socket.connect()
  }

  function connect() {
    connected.value = true
  }

  function disconnect() {
    connected.value = false
  }

  function pingEvent({ recordType, host, index, uuid = v4() }: IPingPayload) {
    if (recordType === 'MXRecords') {
      host = host.split(' ')[1]
      if (host === null)
        return
    }

    logStore.addLog(`Pinging ${host}`, uuid)

    return emit('ping_event', { host, uuid, index, recordType })
  }

  function reverseDomainEvent({ ip, recordType, index, resolver, uuid = v4() }: any) {
    logStore.addLog(`Reverse lookup for ${ip}`, uuid)

    return emit('reverse_domain_event', { ip, uuid, index, resolver, recordType })
  }

  function whoisEvent({ index = 0, uuid = v4(), initial = true, recordType = 'initialWhois', host = localStorage.getItem('url') }) {
    logStore.addLog(`Getting whois for ${host}`, uuid)

    return emit('whois_event', { host, uuid, index, initial, recordType })
  }

  function SOCKET_DOMAIN_RESULT({ data, single, uuid, recordTypes }: IDomainResponse) {
    const records = dnsStore.records

    ObjectUtil.getObjectKeys<IDNSRecords>(data).forEach(key => records[key] = data[key])

    logStore.editLog('Finished', uuid)

    if (recordTypes.includes('NS'))
      records.NSRecords.forEach((o: INSRecord, index) => pingEvent({ host: o.host, recordType: o.recordType, index }))

    if (recordTypes.includes('A'))
      records.ARecords.forEach((o: IARecord, index) => pingEvent({ host: o.host, recordType: o.recordType, index }))

    if (recordTypes.includes('MX'))
      records.MXRecords.forEach((o: IMXRecord, index) => pingEvent({ host: o.host, recordType: o.recordType, index }))

    records.TXTRecords.map((o: ITXTRecord) => {
      o.working = false
      o.error = null
      return o
    })

    parseTXTErrors(recordTypes.includes('TXT'))

    if (single === false) {
      dnsStore.whoisRunning = true
      whoisEvent({ initial: true, host: dnsStore.query })
    }
  }

  function SOCKET_WHOIS_RESULT({ data, uuid, host, index, initial, recordType }: IWhoisResponse) {
    dnsStore.whoisRunning = false

    dnsStore.initialWhois = { data, uuid, host, index, initial, recordType }

    if (data.error)
      dnsStore.addProblem('Whois lookup failed', 'whois')

    if (data.registrar === null) {
      logStore.editLog(data.error, uuid)
      return
    }

    if (data.status) {
      if (data.status.includes('clientHold'))
        dnsStore.addProblem('Domain is on hold', 'domain')

      if (data.status.includes('pendingTransfer'))
        dnsStore.addProblem('Domain is pending transfer', 'domain')
    }

    logStore.editLog(data.registrar, uuid)
  }

  function SOCKET_PING_RESULT({ ip, data, host, uuid, recordType, index, asn_country, asn_name }: IPingResponse) {
    const records = dnsStore.records

    logStore.editLog(`${data} ms`, uuid)

    if (ip !== null)
      reverseDomainEvent({ ip, index, recordType, resolver: dnsStore.dnsResolver })

    parseMXErrors(recordType === 'MXRecords', host, ip, index)

    Object.assign(records[recordType][index], { ping: data, ip, asn_name, asn_country })
  }

  function SOCKET_REVERSE_RESULT({ host, index, recordType, uuid }: IReverseResponse) {
    const records = dnsStore.records

    logStore.editLog(`${host}`, uuid)

    Object.assign(records[recordType][index], { reverse: host, working: false })
  }

  function SOCKET_SSL_CHECK_RESULT({ host, result, tag }: ISSLHosts) {
    dnsStore.sslHosts
      .filter(o => o.host === host)
      .forEach((o) => {
        o.error = result
        o.tag = tag
      })
  }

  return {
    isConnected,
    init,
    connect,
    disconnect,
    reconnect,
    emit,
    SOCKET_DOMAIN_RESULT,
    SOCKET_PING_RESULT,
    SOCKET_REVERSE_RESULT,
    SOCKET_SSL_CHECK_RESULT,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSocketStore, import.meta.hot))

