/* eslint-disable @typescript-eslint/no-unused-vars */
export function useModals() {
  function showWhoIsModal(host: string) {
    host = host.substring(host.length - 1) === '.' ? host.slice(0, -1) : host
    useDnsStore().addProblem('skrt', 'ARecords')
  }

  function showPortScan(host: string) {
    host = host.substring(host.length - 1) === '.' ? host.slice(0, -1) : host
  }

  function showHttpHeaders(host: string) {
    host = host.substring(host.length - 1) === '.' ? host.slice(0, -1) : host
  }

  return { showHttpHeaders, showWhoIsModal, showPortScan }
}
