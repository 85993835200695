<template>
  <div
    class="dbox data-box"
    :class="{ 'pb-0': records.ARecords.length }"
  >
    <b-row>
      <b-col cols="6">
        <div class="dtitle">
          A
        </div>
      </b-col>
      <b-col
        cols="6"
        class="text-end text-success fs-5"
      >
        <BButton
          v-b-tooltip.hover.left
          class="empty-btn text-success"
          @click="performLookup"
        >
          <i-bi-play v-show="!ARecordsBusy" />
          <i-bi-arrow-clockwise
            v-show="ARecordsBusy"
            small
            animation="spin"
            variant="secondary"
          />
        </BButton>
      </b-col>
    </b-row>

    <table
      v-show="records.ARecords.length"
      class="table no-padding"
    >
      <thead>
        <tr>
          <th>Host</th>
          <th>Ping</th>
          <th>ASN</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(ARecord, index) in records.ARecords"
          :key="index"
        >
          <td>
            <div>
              {{ ARecord.host }}
              <span>
                <i-bi-clipboard />
                <span
                  class="iconify"
                  data-icon="bi:clipboard"
                  data-inline="false"
                />
              </span>
            </div>
            <div>
              <span class="grey">
                {{ ARecord.reverse }}
              </span>
            </div>
            <RecordIcons
              hide-port-scan
              @whois="showWhoIsModal(ARecord.host)"
              @http-headers="showHttpHeaders(ARecord.host)"
            />
          </td>
          <td>
            <span v-if="~~ARecord.ping < 2000 && ARecord.ping >= 500">
              🟨
            </span>
            <span v-else-if="~~ARecord.ping >= 500">
              🟥
            </span>
            <span v-else>
              🟩
            </span>

            <span> {{ ARecord.ping }} ms</span>
          </td>
          <td>
            {{ ARecord.asn_name }},a
            {{ ARecord.asn_country }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="!records.ARecords.length"
      class="no-result"
    >
      No Results - {{ query }}
    </div>
  </div>
</template>

<script setup lang="ts">
const dns = useDnsStore()

const { records, query, ARecordsBusy, dnsResolver } = dns

const { showHttpHeaders, showWhoIsModal } = useModals()

function performLookup() {
  if (ARecordsBusy)
    return

  dns.performSingleLookup({
    host: query,
    resolver: dnsResolver,
    record: 'A',
  })
}
</script>
