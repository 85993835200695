import { resolvers } from '@/common'

export const setLayout = () => [
  { component: 'WhoisBox', x: 0, y: 0, w: 4, h: 3, i: 0 },
  { component: 'ProblemsBox', x: 4, y: 0, w: 4, h: 3, i: 1 },
  { component: 'SSLConnectionsBox', x: 8, y: 7, w: 4, h: 3, i: 2 },
  { component: 'NSRecords', x: 0, y: 3, w: 8, h: 3, i: 3 },
  { component: 'ARecords', x: 8, y: 10, w: 4, h: 3, i: 4 },
  { component: 'MXRecords', x: 0, y: 6, w: 5, h: 3, i: 5 },
  { component: 'TXTRecords', x: 5, y: 6, w: 3, h: 3, i: 6 },
  { component: 'SOARecords', x: 8, y: 13, w: 4, h: 3, i: 7 },
  { component: 'DebugBox', x: 8, y: 0, w: 4, h: 7, i: 8 },
]

export const setResolvers = () => resolvers
