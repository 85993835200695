<template>
  <b-container
    fluid
    class="p-0"
  >
    <b-row
      v-show="!socketStore.isConnected"
      class="mb-2"
    >
      <b-col>
        <div class="dbox error-box text-center">
          Connecting to websocket
        </div>
      </b-col>
    </b-row>

    <CxHeader @dig="getDomain()" />

    <!-- DNS ERROR -->
    <b-row
      v-if="dnsError !== null && !propChecker"
      class="mb-2 p-0 m-0"
    >
      <b-col
        :cols="12"
        class="p-0"
      >
        <div class="box error-box text-danger">
          {{ dnsError }}
        </div>
      </b-col>
    </b-row>
    <GridLayout
      ref="gridLayoutRef"
      v-model:layout="layout"
      :col-num="12"
      :auto-size="false"
      :row-height="30"
      :is-draggable="!NSRecordsBusy && customize"
      :is-resizable="!NSRecordsBusy && customize"
    >
      <template #default="{ gridItemProps }">
        <GridItem
          v-for="item in layout"
          :key="item.i"
          v-bind="gridItemProps"
          ref="gridItemRef"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :static="item.component === 'DebugBox'"
        >
          <div class="item">
            <ResizerItem :component="item.component" />
          </div>
        </GridItem>
      </template>
    </GridLayout>

    <PortScanModal />
  </b-container>
</template>

<script setup lang="ts">
import GridItem from '@/packages/grid-layout/components/GridItem.vue'
import GridLayout from '@/packages/grid-layout/components/GridLayout.vue'
import ResizerItem from '@/packages/grid-layout/components/ResizerItem.vue'

const gridLayoutRef = ref(null)
const canceled = ref(false)

const dnsStore = useDnsStore()
const logStore = useLogStore()
const initStore = useInitStore()
const layoutStore = useLayoutStore()
const socketStore = useSocketStore()

const { layout, customize } = storeToRefs(layoutStore)
const { pendingCount } = storeToRefs(logStore)
const { dnsError, query, dnsResolver, NSRecordsBusy } = storeToRefs(dnsStore)
const { clearOnLoad, propChecker, autoRunSSLCheck } = storeToRefs(initStore)

const { resetResolverState } = usePropCheckStore()

onMounted(() => {
  resetResolverState(true)
  if (pendingCount.value > 0)
    logStore.logs = []

  if (!clearOnLoad)
    logStore.clearLogs()
})

const getDomain = () => {
  if (query.value === '')
    return

  if (pendingCount.value > 0)
    return canceled.value = true

  dnsStore.performDNSLookup({
    host: query.value,
    resolver: dnsResolver.value,
    sslCheck: autoRunSSLCheck.value,
    records: ['A', 'NS', 'MX', 'SOA', 'TXT'],
  })

  logStore.setStartTime()
}
</script>
