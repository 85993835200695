import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'
import type { App, ComponentPublicInstance } from 'vue'
import type { Router } from 'vue-router'
import { toRaw } from 'vue'

const initSentry = (app: App, router: Router) => {
  if (import.meta.env.MODE !== 'development') {
    Sentry.init({
      app,
      dsn: 'https://65c4dfcbe5bd4b69a0eb7188d9baacec@o1397721.ingest.sentry.io/6723321',
      tunnel: '/bugs',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['*'],
        }),
        new CaptureConsole({ levels: ['error'] }),
      ],
      tracesSampleRate: 1.0,
    })
  }
}

export const initHMR = () => {
  if (import.meta.env.MODE === 'development') {
    console.dump = function (message?: any) {
      console.info(JSON.parse(JSON.stringify(message)))
    }
    if (!console.oldLog) {
      console.oldLog = console.read
      console.read = (...args) => {
        console.log(...args.map(arg => toRaw(arg)))
      }
    }

    if (import.meta.hot) {
      import.meta.hot.on('vite:beforeUpdate', () => console.clear())
      import.meta.hot.on('vite:error', ({ err }) => {
        console.log(err)
      })
    }
  }
}

const errHandler = (err: unknown, instance: ComponentPublicInstance | null, info: string) => {
  console.group(info)
  console.log(instance?.$.type.__name)
  console.error(err)
  console.groupEnd()
}

export default {
  errHandler,
  initSentry,
  initHMR,
}
