import { defineStore } from 'pinia'
import collect from 'collect.js'
import { setLayout } from '@/common/defaultStates'

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    layout: useLocalStorage('layout', setLayout()),
    customize: false,
    showDebugBox: useLocalStorage('showDebugBox', false),
  }),

  getters: {
    getLayout(state) {
      return state.layout
    },
  },

  actions: {
    resetLayout() {
      this.layout = setLayout()
    },

    updatePartial(component: any, x: number, y: number, w: number, h: number, i: number) {
      const index = this.layout.findIndex(o => o.component === component)
      Object.assign(this.layout[index], {
        component, x, y, w, h, i,
      })
    },
    toggleDebugBox() {
      if (this.showDebugBox)
        this.removeDebugBox()
      else
        this.addDebugBox()

      this.showDebugBox = !this.showDebugBox
    },
    updateLayout(layout: any) {
      this.layout = layout
    },
    addDebugBox() {
      this.layout.push({ component: 'DebugBox', x: 8, y: 9, w: 4, h: 7, i: collect(this.layout).max('i') + 1 })
    },
    removeDebugBox() {
      const i: any = this.layout.find(o => o.component === 'DebugBox')
      const index: number = this.layout.map(o => o.i).indexOf(i)

      this.layout.splice(index, 1)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useLayoutStore, import.meta.hot))

/*
var layout = setLayout();
var customize = $ref(false);
var showDebugBox = $ref(false);

function updateLayout(layout: any) {
    layout = layout;
}

function resetLayout() {
    layout = setLayout();
}
function toggleCustomize() {
    customize = !customize;
}
function toggleDebugBox() {
    showDebugBox = !showDebugBox;
}
function setDebugBox(value: boolean) {
    showDebugBox = value;
}

function removeDebugBox() {
    const i:any = layout.find((o) => o.component === 'DebugBox');
    const index: number = layout.map((o) => o.i).indexOf(i);

    layout.splice(index, 1);
}

function addDebugBox() {
    layout.push({ component: 'DebugBox', x: 8, y: 9, w: 4, h: 7, i: collect(layout).max('i') + 1 });
}

return {
    layout,
    customize,
    showDebugBox,
    updateLayout,
    resetLayout,
    toggleCustomize,
    toggleDebugBox,
    setDebugBox,
    removeDebugBox,
    addDebugBox,
};

*/
