import axios from 'axios'

let host = document.domain

if (document.location.port)
  host += `:${document.location.port}`

const api = axios.create({
  baseURL: `${document.location.protocol}//${host}/api/auth`,
})

export default api
