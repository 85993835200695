<script setup>
const dns = useDnsStore()

const { query, MXRecordsBusy, dnsResolver, records, TXTRecordsBusy, whoisRunning } = dns

function performLookup() {
  if (TXTRecordsBusy)
    return

  dns.performSingleLookup({
    host: query,
    resolver: dnsResolver,
    record: 'TXT',
  })
}
</script>

<template>
  <div class="dbox data-box">
    <b-row>
      <b-col cols="6">
        <div class="dtitle">
          TXT
        </div>
      </b-col>
      <b-col cols="6" class="text-end text-success fs-5">
        <b-button
          v-b-tooltip.left
          class="empty-btn text-success"
          data-bs-toggle="hi"
          :title="`Get TXT Records for ${query}`"
          @click="performLookup"
        >
          <i-bi-play v-show="!whoisRunning" />
          <i-bi-arrow-clockwise v-show="whoisRunning" small animation="spin" variant="secondary" />
        </b-button>
      </b-col>
    </b-row>
    <table v-show="records.TXTRecords.length" class="table no-padding">
      <thead>
        <tr>
          <th />
        </tr>
      </thead>
      <tbody>
        <TXTRecord v-for="(record, index) in records.TXTRecords" :key="index" :data="record" :index="index" />
      </tbody>
    </table>

    <div v-if="!records.TXTRecords.length" class="no-result">
      No Results
    </div>
  </div>
</template>
