<template>
  <RouterLink
    v-for="route in $router.options.routes"
    :key="route.path"
    :to="route.path"
  >
    <span v-if="route.name !== 'propcheck'">
      {{ route.name }} |
    </span>
  </RouterLink>

  <RouterView />
  <HelpModal />
  <SettingsModal />
  <DebugLogsModal />
  <!--
  <WhoIsModal />
  <HTTPHeadersModal />

-->
</template>

<script setup lang="ts">
const dns = useDnsStore()
const init = useInitStore()
const socketStore = useSocketStore()

socketStore.init()

const { fontFamily, getFontSize } = storeToRefs(init)

const { Escape } = useMagicKeys()

watch(Escape, (v) => {
  if (v)
    dns.clearRecords()
})
</script>

<style>
* {
  font-size: v-bind(getFontSize);
  font-family: v-bind(fontFamily), sans-serif;
}
</style>
