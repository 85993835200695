<template>
  <div
    class="dbox data-box"
    :class="{ 'pb-0': records.SOARecords.length }"
  >
    <div class="record-type">
      <div class="dtitle">
        SOA
      </div>
    </div>
    <table
      v-if="records.SOARecords.length"
      class="table no-padding"
    >
      <tbody>
        <tr
          v-for="(SOARecord, index) in records.SOARecords"
          :key="index"
          class="record"
        >
          <td>
            <table class="soa-table w-100">
              <tbody>
                <tr>
                  <td class="left bold">
                    Primary nameserver:&nbsp;
                  </td>
                  <td class="left grey">
                    {{ SOARecordValue[0] }}
                  </td>
                </tr>
                <tr>
                  <td class="left bold">
                    Hostmaster E-mail:&nbsp;
                  </td>
                  <td class="left grey">
                    {{ SOARecordValue[1] }}
                  </td>
                </tr>
                <tr>
                  <td class="left bold">
                    Serial number: &nbsp;
                  </td>
                  <td class="left grey lowercase">
                    {{ SOARecordValue[2] }}
                  </td>
                </tr>
                <tr>
                  <td class="left bold">
                    Refresh: &nbsp;
                  </td>
                  <td class="left grey lowercase">
                    {{ SOARecordValue[3] }}
                  </td>
                </tr>
                <tr>
                  <td class="left bold">
                    Retry: &nbsp;
                  </td>
                  <td class="left grey lowercase">
                    {{ SOARecordValue[4] }}
                  </td>
                </tr>
                <tr>
                  <td class="left bold">
                    Expire: &nbsp;
                  </td>
                  <td class="left grey lowercase">
                    {{ SOARecordValue[5] }}
                  </td>
                </tr>
                <tr>
                  <td class="left bold">
                    Minimum TTL: &nbsp;
                  </td>
                  <td class="left grey lowercase">
                    {{ SOARecordValue[6] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="!records.SOARecords.length"
      class="no-result"
    >
      No Results
    </div>
  </div>
</template>

<script setup>
const dns = useDnsStore()

const { records } = storeToRefs(dns)

const SOARecordValue = computed(() => {
  if (!records.value.SOARecords.length)
    return []
  return records.value.SOARecords[0].host.split(' ')
})
</script>
