<template>
  <b-modal id="debug-log-modal" title="Debug Logs" hide-footer>
    <DebugBox />
  </b-modal>
</template>

<script>
import DebugBox from '@/components/DebugBox.vue'

export default {
  name: 'DebugLogsModal',

  components: { DebugBox },
}
</script>
