<script setup>
const dns = useDnsStore()

const { query, MXRecordsBusy, dnsResolver, records } = storeToRefs(dns)

function performLookup() {
  if (MXRecordsBusy.value)
    return

  dns.performSingleLookup({
    host: query.value,
    resolver: dnsResolver.value,
    record: 'MX',
  })
}

function pingText(ping) {
  if (!ping)
    return 'N/A'

  if (~~ping < 2000 && ping >= 500)
    return `🟨 ${ping} ms`
  if (~~ping >= 500)
    return `🟥 ${ping} ms`

  return `🟩 ${ping} ms`
}
</script>

<template>
  <div class="dbox data-box" :class="{ 'pb-0': records.MXRecords.length }">
    <b-row>
      <b-col cols="6">
        <div class="dtitle">
          MX
        </div>
      </b-col>
      <b-col cols="6" class="text-end text-success fs-5" style="z-index: 3">
        <b-button
          v-b-tooltip.bottom
          class="empty-btn text-success"
          :title="`Get MX Records for ${query}`"
          @click="performLookup"
        >
          <i-bi-play v-show="!MXRecordsBusy" />
          <i-bi-arrow-clockwise v-show="MXRecordsBusy" small animation="spin" variant="secondary" />
        </b-button>
      </b-col>
    </b-row>
    <table v-show="records.MXRecords.length" class="table no-padding">
      <thead>
        <tr class="record">
          <th>Host</th>
          <th>Ping</th>
          <th>IP</th>
          <th>ASN</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(MXRecord, index) in records.MXRecords" :key="index" class="record">
          <td>
            <span :class="{ 'text-danger': MXRecord.ip === null }">
              <b-button
                v-if="MXRecord.ip === null"
                v-b-tooltip.hover.right
                :title="`A Record not found for ${MXRecord.host}`"
                class="empty-btn me-1 text-sm text-danger"
              >
                <i-bi-diamond-fill />
              </b-button>

              {{ MXRecord.host }}
            </span>
          </td>

          <td>
            <span :class="{ 'text-danger': MXRecord.ip === null }">
              <span v-if="MXRecord.ip === null"> 🟥 0 ms </span>
              <span v-else>
                {{ pingText(MXRecord.ping) }}
              </span>
            </span>
          </td>
          <td>
            <span v-if="MXRecord.ip === null" class="text-danger"> N/A </span>
            <span v-else>
              {{ MXRecord.ip }}
            </span>
            <div v-if="MXRecord.ip !== null" class="grey">
              {{ MXRecord.reverse }}
            </div>
          </td>
          <td>
            <span v-if="MXRecord.ip === null" class="text-danger"> N/A </span>
            <span v-else> {{ MXRecord.asn_name }}, {{ MXRecord.asn_country }} </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!records.MXRecords.length" class="no-result">
      No Results
    </div>
  </div>
</template>
