import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './stores'
import init from './plugins/sentry'
import { BootstrapVue3 } from './plugins/bootstrap-vue'

import '@/assets'

const app = createApp(App)

init.initSentry(app, router)

app.use(pinia)
app.use(router)
app.use(BootstrapVue3)

init.initHMR()

app.mount('#app')

window.app = app

// app.config.errorHandler = init.errHandler
