import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import cloneDeep from 'lodash.clonedeep'
import PiniaLogger from 'pinia-logger'

const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)
pinia.use(PiniaLogger({ logErrors: true, expanded: true, disabled: import.meta.env.MODE === 'production' || true }))

pinia.use(({ store }) => {
  const initialState = cloneDeep(store.$state)
  store.$reset = () => store.$patch(cloneDeep(initialState))

  store.$onAction((_action) => {

  })
})

window.pinia = pinia

export default pinia
