<template>
  <div class="dbox data-box" :class="{ 'pb-0': logs.length }">
    <div class="dtitle p-0 mb-1">
      Logs - {{ query }}
    </div>

    <div name="main" class="debug-box">
      <div class="mt-2">
        <LogEntry v-for="(log, index) in logs" :key="index" :log="log" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const logStore = useLogStore()
const dns = useDnsStore()

const { logs } = storeToRefs(logStore)
const query = $ref(dns.query)
</script>
