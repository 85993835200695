<template>
  <div
    ref="el"
    style="position: relative"
  >
    <ARecords v-if="component === 'ARecords'" />
    <NSRecords v-if="component === 'NSRecords'" />
    <MXRecords v-if="component === 'MXRecords'" />
    <TXTRecords v-if="component === 'TXTRecords'" />
    <SOARecords v-if="component === 'SOARecords'" />
    <ProblemsBox v-if="component === 'ProblemsBox'" />
    <SSLConnectionsBox v-if="component === 'SSLConnectionsBox'" />
    <WhoisBox v-if="component === 'WhoisBox'" />
    <DebugBox v-if="component === 'DebugBox'" />
  </div>
</template>

<script>
import { ResizeSensor } from 'css-element-queries'
import { templateRef, useResizeObserver } from '@vueuse/core'
import { emitterKey } from '../types/symbols'

export default {
  name: 'ResizeBox',

  inject: {
    eventBus: 'eventBus',
  },

  props: {
    component: String,
  },

  data() {
    return {
      resizeSensor: null,
      clientHeight: null,
    }
  },

  async mounted() {
    const store = useLayoutStore()

    const { layout } = storeToRefs(store)

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    this.gridItem = this.$parent

    this.resizeSensor = new ResizeSensor(this.$el, () => {
      const widgetHeight = this.$el.clientHeight
      if (widgetHeight !== vm.widgetHeight) {
        const h = Math.ceil(
          (widgetHeight + vm.gridItem.margin[1]) / (vm.gridItem.rowHeight + vm.gridItem.margin[1]),
        )
        const newH = h

        vm.widgetHeight = widgetHeight

        vm.gridItem.eventBus.emit(
          'resizeEvent', {
            eventType: 'resizeend',
            i: vm.gridItem.i,
            x: vm.gridItem.x,
            y: vm.gridItem.y,
            h: newH,
            w: vm.gridItem.w,
          },

        )
      }
    })

    this.widgetHeight = this.$el.clientHeight
  },

}
</script>
