<template>
  <b-modal
    id="port-scan-modal"
    title="Port Scan"
    hide-footer
  >
    <b-form-group
      label="Host"
      class="mb-2"
      label-for="host"
    >
      <input
        id="host"
        v-model="host"
        type="text"
        class="inputbox mt-1 pl-0"
      >
    </b-form-group>
    <b-form-group
      v-slot="{ ariaDescribedby }"
      label="Mode"
      class="mb-2"
      label-for="host"
    >
      <b-form-radio
        v-model.number="mode"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        value="1"
      >
        1 - Ports 1-1024
      </b-form-radio>
      <b-form-radio
        v-model.number="mode"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        value="2"
      >
        2 - Primary ports
        <span style="font-size: 10.4px">
          [21, 22, 23, 25, 53, 80, 110, 143, 443, 587, 993, 995, 2083, 2096, 3306]
        </span>
      </b-form-radio>
      <b-form-radio
        v-model.number="mode"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        value="3"
      >
        3 - Custom port list (seperated by comma)
      </b-form-radio>
      <input
        v-if="mode === 3"
        id="ports"
        v-model="customPorts"
        type="text"
        class="mt-2 inputbox"
      >
    </b-form-group>

    <div v-if="portScanData">
      Open Ports: {{ portScanData.ports }}
    </div>

    <b-button
      v-show="isBusy"
      variant="danger"
      class="mt-3 me-3"
      block
      @click="cancelScan"
    >
      Cancel Scan
    </b-button>

    <b-button
      v-show="!isBusy"
      variant="primary"
      class="mt-3 me-3"
      block
      @click="portScan"
    >
      Begin Scan
    </b-button>
    <b-button
      class="mt-3"
      block
      @click="$bvModal.hide('port-scan-modal')"
    >
      Close
    </b-button>
  </b-modal>
</template>

<script setup>
const host = ref(null)
const mode = ref(3)
const isBusy = ref(false)
const portScanData = ref(null)
const defaultPorts = ref('20,21,22,23,25,53,80,110,143,443,587,993,995,2083,2096,3306')
const customPorts = ref('80,443,837')
const portUrl = ref(null)
const controller = $ref(new AbortController())

async function cancelScan() {
  controller.abort()
}

const obj = {
  host: host.value,
  mode: mode.value,
  ports: mode.value === 3 ? customPorts.value : null,
}

const portScan = async () => {
  isBusy.value = true
  portScanData.value = null

  const params = new URLSearchParams(obj).toString()

  await axios
    .get(`https://${document.domain}/api/portscan?${params}`, {
      signal: this.controller.signal,
    })
    .then(({ data }) => ((portScanData.value = data) && (isBusy.value = false)))
    .catch(() => {
      this.isBusy = false
      this.controller = new AbortController()
    })
}
</script>
