<template>
  <div class="dbox data-box">
    <div class="record-type">
      <div class="dtitle mb-2">
        Potential Problems
      </div>
    </div>
    <div
      v-for="problem in problems"
      :key="problem.uuid"
      class="text-danger pb-1"
    >
      <i-bi-diamond-fill />
      <span> {{ problem.message }} </span>
    </div>
    <div
      v-if="!problems.length"
      class="no-result"
    >
      No problems found
    </div>
  </div>
</template>

<script setup>
const dns = useDnsStore()

const { problems } = storeToRefs(dns)
</script>
