<template>
  <div class="dbox data-box">
    <b-row>
      <b-col cols="6">
        <div class="dtitle p-0 mb-1">
          Whois
        </div>
      </b-col>
      <b-col
        cols="6"
        class="text-end text-success fs-5"
        style="z-index: 1"
      >
        <b-button
          v-b-tooltip.hover.bottom
          class="empty-btn text-success"
          :title="`Run Whois on ${query}`"
          @click="pendingCount === 0 && dns.performInitialWhoisLookup"
        >
          <i-bi-play v-show="!whoisRunning" />
          <i-bi-arrow-clockwise
            v-show="whoisRunning"
            small
            animation="spin"
            variant="secondary"
          />
        </b-button>
      </b-col>
    </b-row>
    <WhoIsResult :initial="true" />
  </div>
</template>

<script setup>
import WhoIsResult from '@/components/WhoIsResult.vue'

const dns = useDnsStore()
const logs = useLogStore()
const { query, whoisRunning } = dns
const { pendingCount } = storeToRefs(logs)
</script>
