function sslURL(host: string) {
  return `https://${host}`
}

export const setSSLHosts = (url: string): ISSLHosts[] => {
  const cPanelHost = `cpanel.${url.split('.').slice(-2).join('.')}`
  const mailHost = `mail.${url.split('.').slice(-2).join('.')}`
  const wwwHost = `www.${url.split('.').slice(-2).join('.')}`

  return [
    { host: sslURL(url), error: 'PENDING', tag: [], result: '' },
    { host: sslURL(wwwHost), error: 'PENDING', tag: [], result: '' },
    { host: sslURL(mailHost), error: 'PENDING', tag: [], result: '' },
    { host: sslURL(cPanelHost), error: 'PENDING', tag: [], result: '' },
  ]
}
