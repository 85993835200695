<template>
  <div>
    <span :class="logClass">
      [{{ log.time }}]
      {{ log.message }}
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
  log: {
    type: Object,
    default: () => ({
      status: '',
    }),
  },
})

const logClass = computed(() => {
  if (props.log.status === 'error')
    return 'text-danger'
  if (props.log.status === 'success')
    return 'mint-green'
  if (props.log.status === 'pending')
    return 'text-warning'
})
</script>
