<template>
  <cxHeader />

  <div class="rowspan">
    <div
      v-for="resolver in getResolvers"
      :key="resolver.id"
    >
      <BPopover
        :id="resolver.id"
        :target="resolver.id"
        triggers="hover"
      >
        <template #title>
          <small
            class="text-sm"
            :class="`text-${resolver.status === 'error' ? 'danger' : 'success'}`"
          >
            {{ resolver.name }}
          </small>
        </template>
        <div class="text-md">
          <i
            class="fflag ff-sm"
            :class="`fflag-${resolver.country_code}`"
          />
          <small> {{ resolver.country }}</small>
          <br>
          <div v-if="resolver.status === 'error'">
            <small>{{ resolver.errorMsg }}</small>
          </div>
          <div v-else-if="resolver.value.length === 0">
            -
          </div>

          <div v-else>
            <div
              v-for="(r, index) in resolver.value"
              :key="index"
            >
              {{ r }}
            </div>
          </div>
        </div>
      </BPopover>
    </div>

    <b-row align-h="end">
      <b-col
        :sm="12"
        :md="6"
      >
        <div class="item prop-results mb-2">
          <div class="dbox data-box record-scroll">
            <table class="table no-padding">
              <tbody>
                <PropResult
                  v-for="(resolver, index) in getResolvers"
                  :id="resolver.id"
                  :key="index"
                  :resolver="resolver"
                />
              </tbody>
            </table>
          </div>
        </div>
      </b-col>

      <b-col
        sm="12"
        md="6"
      >
        <div class="item h-auto mb-2">
          <div class="dbox data-box">
            <div class="world-map">
              <WorldMap height="400">
                <MapMarker
                  v-for="(resolver, index) in getResolvers"
                  :id="resolver.id"
                  :key="resolver.name"
                  :index="index"
                  :resolver="resolver"
                  trigger="hover"
                  placement="top"
                  variant="success"
                />
              </WorldMap>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script setup>
import MapMarker from '@/components/PropCheck/MapMarker.vue'
const propStore = usePropCheckStore()
const { resolvers, getResolvers, getFirstResolver } = storeToRefs(propStore)

function propCheckResult({ data }) {
  const { ip, status, value, errorMsg } = data

  const index = resolvers.findIndex(o => o.ip === ip)

  propStore.updateResolver({
    index,
    value,
    status,
    errorMsg,
  })
}
</script>

<style scoped>
.table {
  margin: 0;
}
</style>
