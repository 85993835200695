import { createRouter, createWebHistory } from 'vue-router'
import DigGrid from '../views/DigGrid.vue'
import PropCheckVue from '@/views/PropCheck.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main',
      component: DigGrid,
    },
    {
      path: '/propcheck',
      name: 'propcheck',
      component: PropCheckVue,
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('../views/Unauthorized.vue'),
    },
    {
      path: '/debug',
      name: 'debug',
      component: import('../views/Debug/Debug1.vue'),
    },
    {
      path: '/coords',
      name: 'coords',
      component: () => import('../views/PropCoords.vue'),
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()

  const auth = storeToRefs(authStore)

  if (!auth.check.value) {
    const data = await authStore.getUser().catch(e => console.error(e))

    if (data.error)
      next({ name: 'unauthorized' })
    else
      next({ name: 'main' })
  } else {
    next()
  }
})
export default router
