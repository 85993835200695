import { REGEX_CONSTANT } from './spfRegex'

export const parseTXTErrors = (parse: boolean) => {
  if (!parse)
    return

  const dns = useDnsStore()

  dns.records.TXTRecords.map((o: ITXTRecord) => {
    let value = o.host
    if (!value.includes('v=spf'))
      return o
    value = value.slice(1, -1)
    if (!REGEX_CONSTANT.SPF_REGEX.test(value)) {
      // o.host = value.replace(/\s\s+/g, "<span class='bg-danger'>&nbsp;&nbsp;</span>");
      o.error = true
      dns.addProblem('One or more SPF records have an invalid syntax', 'TXTRecords')
    }
    return { o }
  })

  if (dns.records.TXTRecords.map((o: ITXTRecord) => o.host).includes('dkim-error'))
    dns.addProblem(`DKIM Not Found - tried default._domainkey.${dns.query}`, 'TXTRecords')
}

export const parseMXErrors = (parse: boolean, host: string, ip: string | undefined, index: number) => {
  if (!parse)
    return
  if (ip !== null)
    return
  const dnsStore = useDnsStore()
  const error = `MX record for ${host} does not have an A record`
  dnsStore.records.MXRecords[index].working = false
  if (!dnsStore.problems.map(o => o.message).includes(error))
    dnsStore.addProblem(error, 'MXRecords')
}
