import { defineStore } from 'pinia'
import { resolvers as resolverList } from '@/common'

interface iUpdatePosition { index: number; x: number; y: number }
interface iUpdateResolver { index: number; value?: any[]; status: number; errorMsg: string }

export const usePropCheckStore = defineStore('propcheck', () => {
  const resolvers = ref(<IResolver[]>resolverList)

  const getResolvers = computed(() => resolvers.value.filter(o => o.id !== 'marker-0'))

  const getFirstResolver = computed(() => resolvers.value[0])
  const getSecondResolver = computed(() => resolvers.value[1])

  function updateResolver(index: number, { ...payload }: { payload: iUpdateResolver }) {
    Object.assign(resolvers.value[index], { ...payload })
  }

  function updatePosition({ index, x, y }: iUpdatePosition) {
    Object.assign(resolvers.value[index], { x, y })
  }

  function resetResolverState(pending: boolean) {
    resolvers.value.map((o: IResolver) => {
      o.value = []
      o.status = pending ? 'pending' : null
      o.errorMsg = null
      return { ...o }
    })
  }

  return {
    resolvers,
    getResolvers,
    getFirstResolver,
    getSecondResolver,
    updateResolver,
    updatePosition,
    resetResolverState,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePropCheckStore, import.meta.hot))
