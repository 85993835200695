<template>
  <div>
    <div v-if="whoisData">
      <b-row>
        <b-col :cols="6">
          <table class="whois-table">
            <tbody>
              <tr>
                <td class="left bold">
                  Registrar:&nbsp;
                </td>
                <td class="left grey">
                  {{ whoisData.registrar ? whoisData.registrar : 'N/A' }}
                </td>
              </tr>
              <tr v-if="whoisData.org !== null">
                <td class="left bold">
                  Orginzation:&nbsp;
                </td>
                <td class="left grey">
                  {{ whoisData.org }}
                </td>
              </tr>
              <tr>
                <td class="left bold">
                  Created: &nbsp;
                </td>
                <td class="left grey lowercase">
                  {{ whoisData.creation_date }}
                </td>
              </tr>
              <tr>
                <td class="left bold">
                  Updated: &nbsp;
                </td>
                <td class="left grey lowercase">
                  {{ whoisData.updated_date }}
                </td>
              </tr>
              <tr>
                <td class="left bold">
                  Expires: &nbsp;
                </td>
                <td class="left grey lowercase">
                  {{ whoisData.expiration_date }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col :cols="6">
          <div
            class="left bold"
            :class="!noMargin ? 'top-20' : ''"
          >
            Status:&nbsp;
          </div>
          <div
            v-for="(s, index) in whoisData.status"
            :key="index"
            class="sm grey"
          >
            <span :style="{ color: statusColor(s) }">
              {{ s }}
            </span>
          </div>

          <div
            class="left bold"
            :class="!noMargin ? 'mt-1' : ''"
          >
            Nameservers&nbsp;
          </div>
          <div
            v-for="ns in whoisData.nameservers"
            :key="ns"
            class="sm grey"
          >
            <span>
              {{ ns }}
            </span>
          </div>
        </b-col>
      </b-row>
    </div>

    <div v-else-if="initialWhois && pendingCount > 0">
      <div
        class="no-result"
        style="color: yellow"
      >
        Pending...
      </div>
    </div>
    <div
      v-else
      class="no-result"
    >
      No Results
    </div>
  </div>
</template>

<script setup>
defineProps({
  initial: { type: Boolean, default: false },
  noMargin: { type: Boolean, default: false },
})

const logs = useLogStore()

const dns = useDnsStore()

const { pendingCount } = logs

const { whoisError, whoisData, initialWhois } = storeToRefs(dns)

function statusColor(status) {
  if (!status)
    return 'N/A'
  if (
    ![
      'N/A',
      'ok',
      'clientTransferProhibited',
      'serverTransferProhibited',
      'serverDeleteProhibited',
      'clientDeleteProhibited',
      'clientUpdateProhibited',
      'serverUpdateProhibited',
      'clientRenewProhibited',
    ].includes(status)
  )
    return 'red'

  return null
}
</script>

<script>
/*
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'WhoIsResult',

        components: {
            BRow,
            BCol,
        },

        props: {
            initial: {
                type: Boolean,
                default: false,
            },
            noMargin: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState({
                initialWhois: (state) => state.initialWhois,
                whoisData: (state) => state.initialWhois.data,
            }),
            ...mapGetters({
                whoisData: 'initialWhoisData',
                whoisError: 'whoisError',
                logsLength: 'logLength',
            }),
        },

        methods: {
            isEmpty() {
                return Object.keys(this.whoisData).length === 0;
            },

            statusColor(status) {
                if (!status) return 'N/A';
                if (
                    ![
                        'N/A',
                        'ok',
                        'clientTransferProhibited',
                        'serverTransferProhibited',
                        'serverDeleteProhibited',
                        'clientDeleteProhibited',
                        'clientUpdateProhibited',
                        'serverUpdateProhibited',
                        'clientRenewProhibited',
                    ].includes(status)
                ) {
                    return 'red';
                }
                return null;
            },
        },
    };
    */
</script>
