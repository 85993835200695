export const useLogStore = defineStore('logs', {
  state: () => ({
    logs: [] as ILogs[],
    pendingLogs: [],
    startTime: new Date().getTime(),
    endTime: new Date().getTime(),
    executionTime: 0,
  }),
  getters: {
    getLogs: state => state.logs.sort((a, b) => (a.startTime < b.startTime ? -1 : 1)),
    getLogLength: state => state.logs.length - 1,
    getStartTime: state => state.startTime,
    getExecutionTime: state => state.endTime - state.startTime,
    pendingCount: state => state.logs.filter(o => o.pending === true).length,
  },
  actions: {
    addLog(message: string, uuid = 'getDomain') {
      this.logs.push({
        message,
        uuid,
        pending: true,
        status: 'pending',
        time: new Date().toLocaleTimeString([], {
          hour12: false,
          second: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }),
        startTime: new Date().getTime(),
      })
    },
    editLog(value: string, uuid = 'getDomain', status = 'success') {
      const logIndex = this.logs.findIndex(o => o.uuid === uuid)

      if (this.logs[logIndex] === undefined)
        return

      if (value === null)
        value = 'Unkown Registrar'

      const executionTime = new Date().getTime() - this.logs[logIndex].startTime + 0

      if (value.includes('error'))
        status = 'error'
      if (value.includes('error'))
        value = 'Error'
      if (value.includes('Reverse failed'))
        status = 'error'
      if (value.includes('No whois server'))
        status = 'error'

      Object.assign(this.logs[logIndex], {
        message: `${this.logs[logIndex].message} ... ${value}`,
        status,
        executionTime,
        pending: false,
      })
    },
    clearLogs() {
      this.logs = []
      this.executionTime = 0
      this.startTime = new Date().getTime()
      this.endTime = this.startTime
    },
    setStartTime() {
      this.startTime = new Date().getTime()
    },
    SET_END_TIME(time: number) {
      this.endTime = time
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useLogStore, import.meta.hot))

