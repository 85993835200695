<template>
  <b-modal
    id="settings-modal"
    title="Settings"
    size="md"
    hide-footer
  >

    <b-row class="mb-3 p-0">
      <b-col cols="6">
        <label
          for="themeSelector"
          class="form-label"
        >Theme</label>
        <select
          id="themeSelector"
          v-model="theme"
          class="selectbox d-block"
        >
          <option disabled>
            Choose theme
          </option>
          <option value="dark">
            Dark (default)
          </option>
          <option value="light">
            Light
          </option>
          <option value="eclipse">
            Eclipse
          </option>
        </select>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <label
          for="themeSelector"
          class="form-label"
        >Font Size</label>
        <input
          ref="inputbox"
          v-model="fontSize"
          type="number"
          class="inputbox"
        >
      </b-col>
      <b-col cols="6">
        <label
          for="themeSelector"
          class="form-label"
        >Font Family</label>
        <select
          id="themeSelector"
          v-model="fontFamily"
          class="selectbox d-block"
        >
          <option disabled>
            Choose font
          </option>
          <option value="Montserrat">
            Montserrat (default)
          </option>
          <option value="Arial">
            Arial
          </option>
          <option value="monospace">
            Monospace
          </option>
        </select>
      </b-col>
    </b-row>
    <b-row
      class="mt-4"
      align-h="center"
    >
      <b-col
        cols="6"
        align-self="center"
      >
        <b-form-checkbox
          id="auto-run-ssl-check"
          v-model="autoRunSSLCheck"
          name="auto-run-ssl-check"
        >
          Auto-run SSL Check on Lookup
        </b-form-checkbox>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox
          id="clear-on-load"
          v-model="clearOnLoad"
          name="clear-on-load"
        >
          Clear results on load
        </b-form-checkbox>
      </b-col>
    </b-row>

    <hr>

    <b-row class="mt-4">
      <b-col cols="12">
        <label class="form-label pe-2">Whitelist Email: </label>
        <input
          v-model="email"
          type="text"
          class="inputbox"
        >
        <b-button
          variant="outline-success"
          @click="whitelistEmail"
        >
          Submit
        </b-button>
        <br>
        <span class="text-center">
          {{ addEmailResponse }}
        </span>
      </b-col>
    </b-row>

    <hr>

    <b-row
      class="mt-4"
      align-h="center"
    >
      <b-col
        :cols="3"
        align-self="center"
        class="text-center"
      >
        <b-button
          variant="outline-secondary"
          @click="layout.toggleDebugBox"
        >
          {{ showDebugBox ? 'Hide' : 'Show' }} Logs
        </b-button>
      </b-col>
      <b-col
        :cols="4"
        align-self="center"
        class="text-center"
      >
        <b-button
          variant="outline-secondary"
          @click="resetLayout"
        >
          Reset Layout
        </b-button>
      </b-col>
      <b-col
        :cols="5"
        align-self="center"
        class="text-center"
      >
        <b-button
          variant="outline-secondary"
          @click="init.urlHistory = []"
        >
          Clear Query History
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script setup lang="ts">
import api from '@/plugins/api/index.js'

const email = $ref('')
const addEmailResponse = ref<string | null>(null)

const dns = useDnsStore()
const init = useInitStore()
const layout = useLayoutStore()

const { showDebugBox } = storeToRefs(layout)
const { autoRunSSLCheck, theme, fontSize, fontFamily, clearOnLoad } = storeToRefs(init)


function resetLayout() {
  dns.$reset()
  layout.resetLayout()
  layout.showDebugBox = false
}

function whitelistEmail() {
  api.get('whitelist', { params: { email }, baseURL: 'api' })
    .then(() => (addEmailResponse.value = `${email} added to whitelist`))
    .catch(() => (addEmailResponse.value = 'Unauthorized.'))
}
</script>
