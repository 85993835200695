<template>
  <b-modal id="help-modal" title="Help" hide-footer>
    <button type="button" aria-label="Close" class="close">
      ×
    </button>
    <strong> Welcome to Wingman </strong>
    <br>
    <br>
    <p>Dig domains. Get results.</p>

    <p>Press <strong> ESC </strong> to clear results</p>
    <p>Press the play icon to run specific query using host from the input box.</p>
  </b-modal>
</template>

<script setup>
</script>
