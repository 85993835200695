export const stripUrl = (url: string) => {
  if (url === undefined)
    return

  return url
    .replace(/^https?:\/\//, '')
    .replace('/', '')
    .trim()
}

export const recordTypes = ['A', 'AAAA', 'NS', 'CNAME', 'MX', 'TXT', 'PTR', 'SRV', 'SOA', 'DNSKEY']

export const resolvers: IResolver[] = [
  {
    name: 'InMotion Hosting',
    ip: '74.124.210.242',
    city: 'Santa Monica',
    state: 'California',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 125,
    y: 345,
    id: 'marker-0',
  },
  {
    name: 'IMH Reseller DNS Servers',
    ip: '216.194.168.112',
    city: 'El Segundo',
    state: 'California',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 120,
    y: 335,
    id: 'marker-1',
  },
  {
    name: 'Web Hosting Hub DNS',
    ip: '173.205.127.4',
    city: 'Virginia Beach',
    state: 'Virginia',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 245,
    y: 340,
    id: 'marker-2',
  },
  {
    name: 'NextDNS 1',
    ip: '45.90.28.206',
    city: 'Washington',
    state: 'District of Columbia',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 240,
    y: 330,
    id: 'marker-3',
  },
  {
    name: 'NextDNS 2',
    ip: '45.90.30.206',
    city: 'Washington',
    state: 'District of Columbia',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 240,
    y: 330,
    id: 'marker-4',
  },
  {
    name: 'Google Public DNS',
    ip: '8.8.8.8',
    city: 'Mountain View',
    state: 'California',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 115,
    y: 325,
    id: 'marker-5',
  },
  {
    name: 'Cloudflare',
    ip: '1.1.1.1',
    city: 'Los Angeles',
    state: 'California',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 136,
    y: 353,
    id: 'marker-6',
  },
  {
    name: 'Level3',
    ip: '209.244.0.3',
    city: 'Monroe',
    state: 'Louisiana',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 205,
    y: 350,
    id: 'marker-7',
  },
  {
    name: 'OpenDNS',
    ip: '208.67.222.222',
    city: 'San Francisco',
    state: 'California',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 220,
    y: 140,
    id: 'marker-8',
  },
  {
    name: 'Socket ISC',
    ip: '216.106.88.3',
    city: 'Columbia',
    state: 'Missouri',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 200,
    y: 330,
    id: 'marker-9',
  },
  {
    name: 'Limestone Networks',
    ip: '69.162.67.202',
    city: 'Dallas',
    state: 'Texas',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 185,
    y: 345,
    id: 'marker-10',
  },
  {
    name: 'AT&T Services',
    ip: '12.121.117.201',
    city: 'Miami',
    state: 'Miama',
    country: 'United States',
    country_code: 'US',
    value: [],
    status: null,
    errorMsg: null,
    x: 235,
    y: 380,
    id: 'marker-11',
  },

  {
    name: 'Quad9',
    ip: '9.9.9.9',
    city: 'Zurich',
    state: 'Zurich',
    country: 'Switzerland',
    country_code: 'CH',
    value: [],
    status: null,
    errorMsg: null,
    x: 484,
    y: 300,
    id: 'marker-12',
  },
  {
    name: 'Quad9 No Blocks',
    ip: '9.9.9.10',
    city: 'Zurich',
    state: 'Zurich',
    country: 'Switzerland',
    country_code: 'CH',
    value: [],
    status: null,
    errorMsg: null,
    x: 484,
    y: 300,
    id: 'marker-13',
  },

  {
    name: 'PhillipCapital (India)',
    ip: '202.134.52.105',
    city: 'Mumbai',
    state: 'Maharashtra',
    country: 'India',
    country_code: 'IN',
    value: [],
    status: null,
    errorMsg: null,
    x: 665,
    y: 395,
    id: 'marker-14',
  },
  {
    name: 'Zarnet (Zimbabwe)',
    ip: '41.57.125.214',
    city: 'Quatre Bornes',
    state: 'Plaines Wilhems District',
    country: 'Mauritius',
    country_code: 'MU',
    value: [],
    status: null,
    errorMsg: null,
    x: 620,
    y: 505,
    id: 'marker-15',
  },
  {
    name: 'CPS (Argentina)',
    ip: '190.111.246.169',
    city: 'Buenos Aires City',
    state: 'Buenos Aires Autonomous City',
    country: 'Argentina',
    country_code: 'AR',
    value: [],
    status: null,
    errorMsg: null,
    x: 297,
    y: 550,
    id: 'marker-16',
  },
  {
    name: 'DTPNET NAP (Indonesia)',
    ip: '202.43.162.242',
    city: 'Jakarta',
    state: 'Jakarta Raya',
    country: 'Indonesia',
    country_code: 'ID',
    value: [],
    status: null,
    errorMsg: null,
    x: 755,
    y: 465,
    id: 'marker-17',
  },
  {
    name: 'mtnonline.com (Nigeria)',
    ip: '83.143.8.249',
    city: 'Lagos',
    state: 'Lagos',
    country: 'Nigeria',
    country_code: 'NG',
    value: [],
    status: null,
    errorMsg: null,
    x: 480,
    y: 435,
    id: 'marker-18',
  },
  {
    name: 'NewMedia (Singapore)',
    ip: '103.53.197.218',
    city: 'Singapore',
    state: 'Singapore',
    country: 'Singapore',
    country_code: 'SG',
    value: [],
    status: null,
    errorMsg: null,
    x: 750,
    y: 440,

    id: 'marker-19',
  },
  {
    name: 'UUNET (Australia)',
    ip: '203.2.193.67',
    city: 'St Leonards',
    state: 'New South Wales',
    country: 'Australia',
    country_code: 'AU',
    value: [],
    status: null,
    errorMsg: null,
    x: 888,
    y: 544,
    id: 'marker-20',
  },
  {
    name: 'ANEXIA (Austria)',
    ip: '83.137.41.9',
    city: 'Ampass',
    state: 'Tirol',
    country: 'Austria',
    country_code: 'AT',
    value: [],
    status: null,
    errorMsg: null,
    x: 500,
    y: 294,
    id: 'marker-21',
  },
  {
    name: 'LVLT-3549 (Ecuador)',
    ip: '190.216.111.247',
    city: 'Guayaquil',
    state: 'Guayas',
    country: 'Ecuador',
    country_code: 'EC',
    value: [],
    status: null,
    errorMsg: null,
    x: 233,
    y: 455,
    id: 'marker-22',
  },
  {
    name: 'OpenNIC (Czech Republic)',
    ip: '185.43.135.1',
    city: 'Prague',
    state: 'Prague',
    country: 'Czech Republic',
    country_code: 'CZ',
    value: [],
    status: null,
    errorMsg: null,
    x: 506,
    y: 286,
    id: 'marker-23',
  },
  {
    name: 'R-Kom (Germany)',
    ip: '81.27.162.100',
    city: 'Regensburg',
    state: 'Bayern',
    country: 'Germany',
    country_code: 'DE',
    value: [],
    status: null,
    errorMsg: null,
    x: 490,
    y: 285,
    id: 'marker-24',
  },
  {
    name: 'OpenNIC (France)',
    ip: '83.145.86.7',
    city: 'Lille',
    state: 'Hauts-de-France',
    country: 'France',
    country_code: 'FR',
    value: [],
    status: null,
    errorMsg: null,
    x: 467,
    y: 295,
    id: 'marker-25',
  },
  {
    name: 'Fujitsu Limited (Japan)',
    ip: '202.248.37.74',
    city: 'Tokyo',
    state: 'Tokyo',
    country: 'Japan',
    country_code: 'JP',
    value: [],
    status: null,
    errorMsg: null,
    x: 843,
    y: 347,
    id: 'marker-26',
  },
  {
    name: 'LG Dacom (South Korea)',
    ip: '164.124.101.2',
    city: 'Seoul',
    state: 'Seoul',
    country: 'South Korea',
    country_code: 'KR',
    value: [],
    status: null,
    errorMsg: null,
    x: 823,
    y: 341,
    id: 'marker-27',
  },
  {
    name: 'Multinet Pakistan (Pakistan)',
    ip: '125.209.116.22',
    city: 'Karachi',
    state: 'Sindh',
    country: 'Pakistan',
    country_code: 'PK',
    value: [],
    status: null,
    errorMsg: null,
    x: 647,
    y: 380,
    id: 'marker-28',
  },
  {
    name: 'OpenNIC (Russia)',
    ip: '91.217.137.37',
    city: 'Moscow',
    state: 'Moscow',
    country: 'Russia',
    country_code: 'RU',
    value: [],
    status: null,
    errorMsg: null,
    x: 579,
    y: 258,
    id: 'marker-29',
  },
]
