<template>
  <div class="dbox data-box">
    <b-row>
      <b-col cols="6">
        <div class="dtitle p-0 mb-1">
          SSL Connections
        </div>
      </b-col>
      <b-col
        cols="6"
        class="text-end text-success fs-5"
      >
        <b-button
          v-b-tooltip.hover.left
          class="empty-btn text-success"
          :title="`Run SSL Check on ${query}`"
          @click="performSSLCheck()"
        >
          <i-bi-play v-show="pendingSSLHosts === 0" />
          <i-bi-arrow-clockwise
            v-show="pendingSSLHosts === 0"
            small
            animation="spin"
            class="text-secondary spin"
          />
        </b-button>
      </b-col>
    </b-row>
    <div v-if="sslHosts.length">
      <div v-if="cmsCheck">
        Site is running:
        <span v-if="cmsCheck.length === 1"> {{ cmsCheck[0] }}</span>
        <span
          v-else
          v-html="`<br/>${cmsCheck.length ? cmsCheck.join('<br/>') : 'Not found'}`"
        />
      </div>
      <div
        v-for="ssl in sslHosts"
        :key="ssl.host"
      >
        <div>
          <div v-if="ssl.error !== 'success' && ssl.error !== 'PENDING'">
            <span style="color: red">
              <span v-if="ssl.error.includes('Hostname mismatch, certificate is not valid')">
                {{ ssl.host }} - Hostname mismatch, certificate is not valid
              </span>
              <span v-else-if="ssl.error.includes('self-signed certificate')">
                {{ ssl.host }} - Self-signed cert
              </span>
              <span v-else-if="ssl.error.includes('No address found')">
                {{ ssl.host }} - Host not found (NXDOMAIN)
              </span>

              <span v-else> {{ ssl.host }} - {{ ssl.error }} </span>
            </span>
          </div>
          <div v-else-if="ssl.error === 'PENDING'">
            <span style="color: yellow">{{ ssl.host }} - Pending...</span>
          </div>
          <div v-else>
            <span class="mint-green">{{ ssl.host }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!sslHosts.length"
      class="no-result"
    >
      No results
    </div>
  </div>
</template>

<script setup>
const dns = useDnsStore()

const { sslHosts, pendingSSLHosts, cmsCheck, query } = storeToRefs(dns)

function performSSLCheck() {
  dns.performSSLCheck({ host: query.value })
}
</script>
