<template>
  <div class="dbox data-box">
    <b-row>
      <b-col cols="6">
        <div class="dtitle">
          Nameservers
        </div>
      </b-col>
      <b-col cols="6" class="text-end text-success fs-5" style="z-index: 3">
        <b-button
          v-b-tooltip.hover.bottom
          class="empty-btn text-success"
          :title="`Get NS Records for ${dns.query}`"
          @click="performLookup"
        >
          <i-bi-play v-show="!working" />
          <i-bi-arrow-clockwise v-show="working" small animation="spin" variant="secondary" />
        </b-button>
      </b-col>
    </b-row>
    <table v-show="records.NSRecords.length" class="table mb-0">
      <thead>
        <tr class="row">
          <th class="d-inline-block col-3">
            Host
          </th>
          <th class="d-inline-block col-3">
            IP
          </th>
          <th class="d-inline-block col-2">
            Ping
          </th>
          <th class="d-inline-block col-2">
            Registrar
          </th>
          <th class="d-inline-block col-2">
            ASN
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(NSRecord, index) in records.NSRecords" :key="index" class="record no-border row">
          <td class="d-inline-block col-3">
            {{ NSRecord.host }} <br>
            <RecordIcons
              hide-headers
              hide-port-scan
              @whois="showWhoIsModal(NSRecord.host)"
              @port-scan="showPortScan(NSRecord.host)"
              @http-headers="showHttpHeaders(NSRecord.host)"
            />
          </td>
          <td class="d-inline-block col-3">
            {{ NSRecord.ip }}<br>
            <div v-if="NSRecord.reverse" class="grey">
              {{ NSRecord.reverse }}
            </div>
          </td>
          <td class="d-inline-block col-2">
            {{ pingText(NSRecord.ping) }}
          </td>

          <td class="d-inline-block col-2">
            {{ NSRecord?.whois.registrar ? NSRecord.whois.registrar : 'Pending...' }}
          </td>

          <td class="d-inline-block col-2">
            {{ NSRecord.asn_name }}, {{ NSRecord.asn_country }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!records.NSRecords.length" class="no-result">
      No Results
    </div>
  </div>
</template>

<script setup>
const dns = useDnsStore()

const { records, query, NSRecords } = storeToRefs(dns)

const { showHttpHeaders, showPortScan, showWhoIsModal } = useModals()

const { getNSRecordsPending: working, dnsResolver } = dns

function performLookup() {
  if (working) 
    return

  dns.performSingleLookup({
    host: dns.query,
    resolver: dnsResolver,
    record: 'NS',
  })
}

function pingText(ping) {
  if (!ping) 
    return 'N/A'

  if (~~ping < 2000 && ping >= 500) {
    return `🟨 ${ping} ms`
  }
  else if (~~ping >= 500) {
    return `🟥 ${ping} ms`
  }
  else {
    return `🟩 ${ping} ms`
  }
}
</script>
