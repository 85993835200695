<template>
  <tr v-if="data.host === 'dkim-error'">
    <td style="word-break: break-all; font-size: 11px" class="text-danger">
      <i-bi-diamond-fill />

      <span> DKIM Not Found </span>
    </td>
  </tr>
  <tr v-else class="record" :class="{ 'text-danger': data.error }">
    <td v-if="validRecords">
      {{ value }}
    </td>
  </tr>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({ host: null }),
  },
})

const value = computed(() => {
  let value = props.data.host
  if (!value.includes('v=spf'))
    return value

  value = value.replace(/\s\s+/g, '<span class=\'bg-danger\'>&nbsp;&nbsp;</span>')
  value = value.replace('  ', '<span class=\'bg-danger\'>&nbsp;&nbsp;</span>')

  return value
})

const validRecords = computed(() => props.data.host !== 'dkim-error')
</script>
