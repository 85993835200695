import { v4 } from 'uuid'
import { setSSLHosts } from '@/utils'

export const useDnsStore = defineStore('dns', () => {
  const logStore = useLogStore()
  const socket = useSocketStore()

  const query = useLocalStorage('url', 'cxdig.net')

  const dnsResolver = useLocalStorage('dnsResolver', 'router')
  const dnsError = ref(null)
  const cmsCheck = ref(null)
  const singleLookup = ref<string | boolean>(false)
  const whoisRunning = ref(false)
  const initialWhois = ref<IWhoisResponse>()
  const problems = ref<IProblem[]>([])
  const sslHosts = ref<ISSLHosts[]>([])
  const whoisModalData = $ref({})

  const whoisData = computed(() => initialWhois.value?.data)

  const records: IDNSRecords = reactive({
    NSRecords: [] as INSRecord[],
    ARecords: [] as IARecord[],
    MXRecords: [] as IMXRecord[],
    TXTRecords: [] as ITXTRecord[],
    SOARecords: [] as any,
  })

  const getARecords = computed((): IARecord[] => records.ARecords)
  const getNSRecords = computed((): INSRecord[] => records.NSRecords)
  const ARecordsBusy = computed(() => (!!records.ARecords.filter(o => o.working === true).length))
  const NSRecordsBusy = computed(() => (!!records.NSRecords.filter(o => o.working === true).length))
  const MXRecordsBusy = computed(() => (!!records.MXRecords.filter(o => o.working === true).length))
  const TXTRecordsBusy = computed(() => (!!records.TXTRecords.filter(o => o.working === true).length))
  const pendingSSLHosts = computed(() => sslHosts.value.filter(o => o.error === 'PENDING').length)

  const getTotalTasks = computed(() => {
    let count = 0
    if (singleLookup.value === false) {
      count += records.ARecords.length * 3
      count += records.NSRecords.length * 3
      count += records.MXRecords.length * 2
    }

    if (singleLookup.value === 'ARecords')
      count += records.ARecords.length * 3
    if (singleLookup.value === 'NSRecords')
      count += records.NSRecords.length * 3
    if (singleLookup.value === 'MXRecords')
      count += records.MXRecords.length * 2

    return count
  })

  function clearRecords() {
    Object.keys(records).forEach(o => (records[o as IRecordType] = []))
    problems.value = []
    logStore.clearLogs()
  }

  function addProblem(message: string, recordType: IProblemType) {
    problems.value.push({ message, recordType, uuid: v4() })
  }

  function removeProblem(recordType: IRecordType) {
    problems.value = problems.value.filter(o => o.recordType !== recordType)
  }

  function clearState() {
    problems.value = []

    sslHosts.value = []

    Object.keys(records).forEach(o => (records[o as IRecordType] = []))

    logStore.clearLogs()

    singleLookup.value = false

    initialWhois.value = undefined
  }

  function clearSSLHosts() {
    sslHosts.value = []
  }

  function performSSLCheck({ host }: ISSLPayload): ISSLHost[] {
    sslHosts.value = setSSLHosts(host)

    cmsCheck.value = null

    sslHosts.value.forEach(
      (o: ISSLHost) => socket?.emit('ssl_host_event', { host: o.host }),
    )

    return sslHosts.value
  }

  function performSingleLookup({ resolver, record, host }: ISingleDNSPayload) {
    const uuid = v4()

    const recordName: IRecordType = `${record}Records`

    records[recordName] = []

    logStore.setStartTime()

    singleLookup.value = recordName

    removeProblem(recordName)

    logStore.addLog(`DIG ${record} records ${query.value} using ${resolver}`, uuid)

    socket?.emit('domain_event', {
      host,
      resolver,
      records: [record],
      single: true,
      uuid,
    })
  }

  function performDNSLookup({ host, resolver, sslCheck, records }: IFullDNSPayload) {
    clearState()

    const uuid = v4()

    logStore.addLog(`DIG ${host} using ${resolver}`, uuid)

    socket.emit('domain_event', { host, resolver, records, single: false, uuid })

    if (sslCheck) {
      clearSSLHosts()
      performSSLCheck({ host })
    }
  }

  return {
    pendingSSLHosts,
    query,
    singleLookup,
    getTotalTasks,
    sslHosts,
    NSRecordsBusy,
    ARecordsBusy,
    MXRecordsBusy,
    TXTRecordsBusy,
    problems,
    dnsError,
    dnsResolver,
    records,
    getARecords,
    getNSRecords,
    whoisModalData,
    cmsCheck,
    whoisRunning,
    initialWhois,
    whoisData,
    addProblem,
    removeProblem,
    clearState,
    clearRecords,
    clearSSLHosts,
    performDNSLookup,
    performSingleLookup,
    performSSLCheck,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDnsStore, import.meta.hot))

