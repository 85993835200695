<template>
  <b-row class="mb-2 position-relative">
    <b-col
      md="12"
      lg="7"
      class="order-last order-lg-first"
    >
      <div class="inputboxlabel">
        {{ propChecker ? 'PROP' : 'DIG' }}
      </div>

      <input
        ref="inputbox"
        v-model="dns.query"
        type="text"
        class="inputbox inputbox-with-label"
        list="query_history"
        @keypress.space.prevent
        @keydown.enter="$emit('dig')"
      >

      <select
        v-if="propChecker"
        v-model="recordPropCheck"
        class="selectbox"
      >
        <option
          v-for="recordType in recordTypes"
          :key="recordType"
          :value="recordType"
          v-html="recordType"
        />
      </select>

      <select
        v-if="!propChecker && dnsResolvers.length"
        v-model="dnsResolver"
        class="selectbox"
      >
        <option
          value="router"
          selected="false"
        >
          ISP
        </option>
        <option
          v-for="resolver in dnsResolvers"
          :key="resolver.ip"
          :value="resolver.ip"
        >
          {{ resolver.name }}
        </option>
      </select>

      <b-button
        v-show="pendingCount === 0 || getTotalTasks === 0"
        variant="outline-success"
        class="btn-dig"
        :disabled="isDisabled"
        @click="$emit('dig')"
      >
        <span> {{ submitButtonText }} </span>
      </b-button>
      <b-button
        v-show="pendingCount > 0 && getTotalTasks > 0"
        variant="outline-danger"
        class="btn-dig me-1"
        :disabled="canceled"
        @click="cancelSocket()"
      >
        Cancel ({{ pendingCount }} / {{ getTotalTasks }})
      </b-button>
    </b-col>

    <b-col
      md="12"
      lg="5"
      class="mb-2 mb-lg-0 d-flex justify-content-center justify-content-lg-end"
    >
      <span
        class="iconify"
        data-icon="carbon:dns-services"
        data-inline="false"
        style="color: #888888"
      />

      <span
        class="iconify"
        data-icon="bi:2-square"
        data-inline="false"
        style="color: #888888"
      />

      <b-button
        class="me-1"
        variant="outline-info"
        @click="$router.push({ name: propChecker ? 'main' : 'propcheck' })"
      >
        <i-eos-icons-dns />

        <span class="ms-1 d-none d-sm-inline-block">
          {{ propChecker ? 'Dig' : 'Prop Check' }}
        </span>
      </b-button>

      <b-button
        v-b-tooltip.hover.bottom
        v-b-modal.debug-log-modal
        title="Show Logs Modal"
        variant="outline-danger"
        class="me-1"
      >
        <i-bi-bug />
        <span class="d-none d-sm-inline-block"> Logs </span>
      </b-button>

      <b-button
        v-b-tooltip.hover.bottom
        title="Customize layout"
        variant="outline-primary"
        :disabled="dns.records.NSRecords.length > 0"
        class="me-1"
        @click="customize = !customize"
      >
        <i-bi-layers />
        <span class="d-none d-sm-inline-block"> Customize </span>
      </b-button>

      <b-button
        v-b-modal.settings-modal
        variant="outline-secondary"
        class="me-1"
      >
        <i-eos-icons-dns />
        <span class="d-none d-sm-inline-block"> Settings </span>
      </b-button>

      <b-button
        v-b-modal.help-modal
        variant="outline-success"
        class="me-1"
      >
        <i-eos-icons-dns />
      </b-button>
    </b-col>
  </b-row>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { recordTypes, resolvers } from '@/common'

defineEmits(['dig'])

const router = useRouter()
const canceled = $ref(false)
const recordPropCheck = ref('A')
const inputbox = ref<HTMLFormElement | null>(null)
const dnsResolvers = ref(resolvers)

const dns = useDnsStore()
const logs = useLogStore()
const layout = useLayoutStore()

const { customize } = storeToRefs(layout)
const { getTotalTasks, query, dnsResolver } = storeToRefs(dns)
const { pendingCount, getExecutionTime } = storeToRefs(logs)

const propChecker = computed(() => router?.currentRoute?.value?.name === 'propcheck')

onMounted(() => inputbox.value?.focus())

function cancelSocket() {
  logs.$reset()
  dns.$reset()
}

const isDisabled = computed(() => {
  if (query.value === null || query.value === '')
    return true

  return logs.pendingCount > 0 || layout.customize === true
})

const submitButtonText = computed((): string => {
  if (propChecker.value)
    return 'PROPAGATION CHECK'

  if (getTotalTasks.value === 0 || canceled || getExecutionTime.value === 0)
    return 'DIG'
  if (pendingCount.value > 0)
    return `DIG (${getExecutionTime.value}ms)`
  return 'DIG'
})
</script>
