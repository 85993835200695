import { defineStore } from 'pinia'

export const useInitStore = defineStore('init', () => {
  const theme = useLocalStorage('theme', 'dark')
  const propChecker = useLocalStorage('propChecker', false)
  const fontSize = useLocalStorage('fontSize', 12)
  const fontFamily = useLocalStorage('fontFamily', 'Montserrat')
  const urlHistory = useLocalStorage('urlHistory', [])
  const clearOnLoad = useLocalStorage('clearOnLoad', true)
  const autoRunSSLCheck = useLocalStorage('autoRunSSLCheck', false)

  function setFontSize(value: number) {
    fontSize.value = value
  }

  function togglePropChecker() {
    return (propChecker.value = !propChecker.value)
  }

  const getFontSize = computed(() => `${fontSize.value}px`)
  const getPropChecker = computed(() => propChecker.value)

  return {
    theme,
    propChecker,
    fontSize,
    fontFamily,
    clearOnLoad,
    urlHistory,
    getFontSize,
    getPropChecker,
    autoRunSSLCheck,
    setFontSize,
    togglePropChecker,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useInitStore, import.meta.hot))

